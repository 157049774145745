const sessionStatusOptions = [
  {value: null, label: 'All'},
  {value: 'canceled', label: 'Canceled'},
  {value: 'occurred', label: 'Occurred'},
  {value: 'occurred_successful', label: 'Occurred Successful'},
  {value: 'occurred_no_show', label: 'Occurred No Show'},
  {value: 'scheduled', label: 'Scheduled'},
  {value: 'rescheduling_request', label: 'Rescheduling Requested'},
  {value: 'no_show_coach_confirmed', label: 'No Show Coach Confirmed'},
  {value: 'no_show_user_confirmed', label: 'No Show User Confirmed'},
];

export default sessionStatusOptions;
