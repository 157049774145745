import React from 'react';
import { NavLink } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

const columns = [
    // created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    filterable: false,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      )
    },
  },

  // name //
  {
    Header: 'Job Post',
    accessor: 'job',
    style: { fontWeight: 'bold' },
    filterable: false,
    Cell: ({ original }) => {
      const { job, vacancy_id } = original;

      return (
        <div className="table-column-name ellipsis-text" title={`${job}`}>
          <NavLink target='_blank' to={`/jobs/${vacancy_id}`}>{`${job}`}</NavLink>
        </div>
      );
    },
  },
  // plan //
  {
    Header: 'Plan',
    accessor: 'plan',
    width: 120,
    filterable: false,
    Cell: ({ original }) => {
      const { plan } = original;

      return (
        <div className="ellipsis-text">
            {plan}
        </div>
      );
    },
  },
  // amount //
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 120,
    style: { textAlign: 'right' },
    filterable: false,
    Cell: ({ original }) => {
      const { amount  } = original;

      return (
        <div className="ellipsis-text">
          ${amount}
        </div>
      );
    },
  },
  //status//
  {
    Header: 'Status',
    accessor: 'status',
    width: 170,
    filterable: false,
    Cell: ({ original }) => {
      const { status } = original;
      return (
        <div className="ellipsis-text">
          {status ? status : 'null'}
        </div>
      );
    },
  },
  // amount //
  {
    Header: 'Promo Code Applied',
    accessor: 'promoCode',
    width: 170,
    filterable: false,
    Cell: ({ original }) => {
      const { code, promoCodeId } = original;
      return (
        <div className="ellipsis-text">
          <NavLink target='_blank' to={`/jobs-promo-codes/${promoCodeId}`}>{code ? code : ''}</NavLink>
        </div>
      );
    },
  },
];

export default columns;