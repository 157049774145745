import axios from 'axios';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getPayments = id => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.get(
    `${API_URL}/${subUrl}/payments`,
    {
      headers: { Authorization: token },
      params: {
        filter: {
          where: {promoCodeId: id, status: 'succeeded'},
          include: [
            'vacancy'
          ]
        }
      }
    }
  )
};

export default getPayments;
