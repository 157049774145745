import React from 'react';
import { NavLink } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

const columns = [

  // created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    filterable: false,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      )
    },
  },

  // name //
  {
    Header: 'Job Name',
    accessor: 'name',
    style: { fontWeight: 'bold' },
    filterable: false,
    Cell: ({ original }) => {
      const { vacancy } = original;

      return (
        <div className="table-column-name ellipsis-text" title={vacancy.title}>
          <NavLink to={`/jobs/${vacancy.id}`}>
            {vacancy.name}
          </NavLink>
        </div>
      );
    },
  },

  // amount //
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 100,
    style: { textAlign: 'right' },
    filterable: false,
    Cell: ({ original }) => {
      const { amount  } = original;

      return (
        <div className="ellipsis-text">
          ${amount}
        </div>
      );
    },
  },


  // status //
  {
    Header: 'Plan',
    accessor: 'plan',
    width: 50,
    style: { textAlign: 'right' },
    filterable: false,
    Cell: ({ original }) => {
      const { planName } = original;

      return (
        <div className="ellipsis-text">
            {planName}
        </div>
      );
    },
  },
];

export default columns;
