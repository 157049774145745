import axios from 'axios';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getPayouts = ({type = 'next', coachId, date} = {}) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  // filter-template
  const params = {
    filter: {},
  };
  if (coachId) {
    params.filter.coaches = [coachId];
  }
  if (date) {
    params.filter.date = date;
  }

  const cancelTokenSource = axios.CancelToken.source();

  // get-request for data
  return {
    request: axios.get(`${API_URL}/${subUrl}/payouts/${type}`, {
      params,
      headers: { Authorization: token }, // backend doesn't check it
      cancelToken: cancelTokenSource.token,
    }),
    cancel: cancelTokenSource.cancel
  }
};

export default getPayouts;
