import React from 'react';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { Input } from 'debounce-input-decorator';

import 'react-datepicker/dist/react-datepicker.css';

const columns = [

  // created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },

  // name //
  {
    Header: 'Name',
    accessor: 'name',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { id, name } = original;

      return (
        <div className="table-column-name ellipsis-text" title={name}>
          <NavLink to={`/promo-codes/${id}`}>
            {name}
          </NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // discount //
  {
    Header: 'Discount',
    accessor: 'value',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { value = {} } = original;

      return (
        <div className="ellipsis-text">
          {value}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // user //
  {
    Header: 'Coach/Sitewide',
    accessor: 'user',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { user: {coach_id, name = '', surname = ''} = {} } = original;

      if (coach_id) {
        return (
          <div className="table-column-name  ellipsis-text" title={`${name} ${surname}`}>
            <NavLink to={`/coaches/${coach_id}`}>
              {`${name} ${surname}`}
            </NavLink>
          </div>
        );
      } else {
        return (
          <div className="ellipsis-text sitewide">
            Sitewide
          </div>
        );
      }
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // limit //
  {
    Header: 'Limit',
    accessor: 'codeLimit',
    width: 50,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { codeLimit } = original;

      return (
        <div className="ellipsis-text">
          {codeLimit}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // used //
  {
    Header: 'Used',
    accessor: 'used',
    width: 50,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { used } = original;

      return (
        <div className="ellipsis-text">
          {used}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // status //
  {
    Header: 'Status',
    accessor: 'status',
    width: 110,
    Cell: ({ original }) => {
      const { status } = original;
      return (
        <div
          className="text-capitalize"
          title={status}
        >
          {status}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <select
          onChange={
            event => {
              if (event.target.value === '') {
                return onChange(null);
              }

              return onChange(event.target.value);
            }
          }
          style={{ width: "100%", height: '38px' }}
          value={filter ? filter.value : ''}
        >
          <option value=''>All</option>
          <option value='live'>Live</option>
          <option value='paused'>Paused</option>
          <option value='deleted'>Deleted</option>
        </select>
      )
    }
  },
];

export default columns;
