import React from 'react';
import { Button } from 'reactstrap';
import ConfirmModal from '../../components/Modals/Confirm/ConfirmModal';

const ConfirmPayModal = ({ isOpen, closeModal, confirmSubmit, children }) => (
  <ConfirmModal isOpen={isOpen} closeModal={closeModal}>
    <section className="section-container confirm-container">
      <span className="ion-close-round ReactModal__confirm__close" onClick={closeModal} />

      <div className='ReactModal__confirm__title'>
        {children}
      </div>

      <footer className="ReactModal__confirm__buttons">
        <Button outline color="secondary" onClick={closeModal}>Cancel</Button>
        <Button outline color="primary" onClick={confirmSubmit}>Confirm</Button>
      </footer>
    </section>
  </ConfirmModal>
);


export default ConfirmPayModal;
