import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';

// state - own react-table state
// data-path - url-parh for request
const getPromoCodesCount = state => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const { filtered } = state;

  // where template for request
  const where = {};

  // when we are typing something inside filter-fields state of react-table is changing
  // we take filter inputs values and inject to request params
  filtered.forEach(i => {
    // id //
    if (i.id === 'id') {
      where[i.id] = i.value;

    // created //
    } else if (i.id === 'created') {
      const createdDate = i.value && format(i.value, 'yyyy-MM-dd');

      if (createdDate) {
        where.created = { 'gt': createdDate }
      }
    // name //
    } else if (i.id === 'name') {
      where[i.id] = { 'like': '%' + i.value + '%' }

    // discount //
    } else if (i.id === 'discount') {
      where.value = parseInt(i.value);

    // limit //
    } else if (i.id === 'limit') {
      where.codeLimit = parseInt(i.value);

    // used //
    } else if (i.id === 'used') {
      where[i.id] = parseInt(i.value);

    // user //
    } else if (i.id === 'user') {
      where[i.id] = { 'like': '%' + i.value + '%' };

    // status //
    } else if (i.id === 'status') {
      where.status = i.value;

    } else {
      where[i.id] = {'like': '%' + i.value + '%'}
    }
  });

  // get-request for count
  return axios.get(`${API_URL}/${subUrl}/promo_codes/searchExtra`, {
    // inject where to params
    params: {
      filter: { where },
      count: true
    },
    headers: { Authorization: token } // backend doesn't check it
  })
};

export default getPromoCodesCount;
