import axios from 'axios';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getSession = id => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.get(
    `${API_URL}/${subUrl}/coach_sessions/${id}`,
    {
      headers: { Authorization: token },
      params: {
        filter: {
          include: [
            'user',
            'coach',
            'payout',
            {
              promoCode: [
                'user'
              ]
            },
            {
              logs: [
                'user',
                'coach',
                'admin'
              ]
            },
            'videos',
            'reviews'
          ]
        }
      }
    }
  )
};

export default getSession;
