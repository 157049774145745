import React, { Component } from 'react';

import isEmpty from 'lodash/isEmpty';

import Table from '../../components/Table';
import columns from './columns';

import Alerts from '../../components/Alerts';
import AddButton from '../../components/AddButton';

import DeleteCoach from './delete';

import formatNumber from './../../utils/formatNumber';

import { withHeaderTitle } from '../../../components/Header/HeaderTitle';

import getCoaches      from './api/getCoaches';
import getCoachesCount from './api/getCoachesCount';
import deleteCoach    from './api/deleteCoach';
import getExportCoachesUrl from "./api/getExportCoachesUrl";

class Coaches extends Component {
  UNSAFE_componentWillMount() { this.props.setHeaderTitle('Coaches') }

  state = {
    // table
    tableState: { filtered: [], sorted: [] },
    coaches: [], coachesCount: null, tableLoading: false, count: null,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', errorAlertIsOpen: false,

    // fields
    id: null, name: '', surname: '',

    // delete
    deleteModalLoading: false, deleteModalIsOpen: false,
  }

  deleteClick = original => () => {
    const { id, general: { name, surname } = {} } = original;
    this.setState({ id, name, surname, deleteModalIsOpen: true, alertIsOpen: false });
  }

  deleteSubmit = () => {
    const dataWithoutDeleted = [];
    const { coaches, id } = this.state;

    this.setState({ deleteModalLoading: true, errorAlertIsOpen: false });

    deleteCoach(id)
      // if delete ok
      .then(() => {
        for (let i = 0; i < coaches.length; i++) {
          // skiping deleted item and forming new array without it
          if (coaches[i].id !== id) {
            // push all data without deleted item to new array
            dataWithoutDeleted.push(coaches[i]);
          }
        }
        this.setState({
          // set new data w\o deleted item
          coaches: dataWithoutDeleted,
          deleteModalIsOpen: false,
          deleteModalLoading: false,

          // show alert
          alertType: 'delete', alertIsOpen: true
        })

        // close alert after 2 sec
        setTimeout(() => {
          this.setState({ alertIsOpen: false });
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  resetFields = () => {
    this.setState({
      name: '',
      surname: '',
    });
  }

  addClick = () => {
    // reset fields
    this.resetFields();

    // open page
    this.props.history.push('/coaches/new');
  }

  exportToCsv = (e) => {
    e.preventDefault();

    window.open(getExportCoachesUrl(this.state.tableState), '_blank');
  };

  closeDeleteModal = () => {
    const { deleteModalLoading } = this.state
    !deleteModalLoading && this.setState({ deleteModalIsOpen: false });
  }

  closeErrorAlert  = () => this.setState({ errorAlertIsOpen: false });

  catchErrors = error => {
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        deleteModalLoading: false,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  componentWillReceiveProps() {
    // AFTER EDIT //
    const { coaches } = this.state;
    const { afterAddData, afterEditData } = this.props.history.location.state || {};

    if (!isEmpty(afterAddData)) {
      this.setState({
        coaches: [afterAddData].concat(coaches),
      });
    }

    if(!isEmpty(afterEditData)) {
      // find editing data in all data by id
      for (let i = 0; i < coaches.length; i++) {
        if (coaches[i].id === afterEditData.id) {
          // inject editing data to table state
          coaches[i] = {
            id: afterEditData.id,
            status: afterEditData.status,
            created: afterEditData.created,
            lastLogin: afterEditData.lastLogin,
            trusted: afterEditData.trusted,
            general: afterEditData.general ? {
              name: afterEditData.general.name,
              surname: afterEditData.general.surname,
              email: afterEditData.general.email,
              company: afterEditData.general.company,
              company_id: afterEditData.general.company_id,
            } : {},
          };
        }
      }

      // inject new array with edited data to table
      this.setState({ coaches });
    }

    // AFTER DELETE //
    const { deletedId } = this.props.history.location.state || {};

    if(deletedId) {
      // get current table-data from the state w\o editing change (when render only)
      const { coaches, coachesCount, count } = this.state;
      const dataWitoutDeleted = [];

      for (let i = 0; i < coaches.length; i++) {
        // skiping deleted item and forming new array without it
        if (coaches[i].id !== deletedId) {
          // push all data without deleted item to new array
          dataWitoutDeleted.push(coaches[i]);
        }
      }
      this.setState({
        coaches: dataWitoutDeleted,
        coachesCount: coachesCount - 1,
        count: count - 1,
      });
    }
  }

  render() {
    const {
      // table
      coaches, coachesCount, count, tableLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText,

      // delete
      deleteModalLoading, deleteModalIsOpen,

      // fields
      id, name, surname,
    } = this.state;

    const controlsColumn = [
      {
        Header: '',
        width: 30,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <div className="rt-custom__controls">
            <i className="ion-android-delete" onClick={this.deleteClick(original)} />
          </div>
        )
      }
    ];

    return (
      <div className="coaches-page">
        <div className="table-top">
          <p className="md-lg">
            Total records:&nbsp;<b>{count && formatNumber(this.state.count)}</b>
          </p>

          <a
            href='#'
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.exportToCsv}
          >
            Export as CSV
          </a>

          <AddButton
            text="coach"
            loading={deleteModalLoading}
            addClick={this.addClick}
          />
        </div>

        { alertIsOpen && <Alerts type={alertType} id={id} name={name} surname={surname} errorText={alertErrorText} /> }

        <DeleteCoach
          id={id}
          name={name}
          surname={surname}
          isOpen={deleteModalIsOpen}
          deleteSubmit={this.deleteSubmit}
          modalLoading={deleteModalLoading}
          closeModal={this.closeDeleteModal}
        />

        <Table
          data={coaches}
          manual={true}
          pages={coachesCount}
          loading={tableLoading}
          columns={[...columns, ...controlsColumn]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
          onFetchData={state => {
            this.setState({ tableState: state, tableLoading: true });

            // count request
            getCoachesCount(state)
              .then(res => {
                this.setState({
                  count: res.data.count,
                  coachesCount: Math.ceil(res.data.count / state.pageSize)
                })

                // data request
                getCoaches(state)
                  .then(res => this.setState({ coaches: res.data, tableLoading: false }))
                  .catch(error => this.catchErrors(error));
              }).catch(error => this.catchErrors(error));
          }}
        />
      </div>
    )
  }
}

export default withHeaderTitle(Coaches);
