import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const rescheduleRequest = (id, reason) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.post(
    `${API_URL}/${subUrl}/coach_sessions/${id}/reschedulingRequest`,

    {
      reason,
    },

    {
      headers: { Authorization: token }
    }
  );
}

export default rescheduleRequest;
