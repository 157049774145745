import React, { Component } from 'react';

import isEmpty from 'lodash/isEmpty';

import Table from '../../components/Table';
import columns from './columns';

import Alerts from '../../components/Alerts';

import formatNumber from './../../utils/formatNumber';

import { withHeaderTitle } from '../../../components/Header/HeaderTitle';

import getSessions      from './api/getSessions';
import getSessionsCount from './api/getSessionsCount';
import getExportCoachSessionsUrl from "./api/getExportCoachSessionsUrl";

class CoachSessions extends Component {
  UNSAFE_componentWillMount() { this.props.setHeaderTitle('Coaching Sessions') }

  state = {
    // table
    tableState: { filtered: [], sorted: [] },
    sessions: [], sessionsCount: null, tableLoading: false, count: null,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', errorAlertIsOpen: false,
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  exportToCsv = (e) => {
    e.preventDefault();

    window.open(getExportCoachSessionsUrl(this.state.tableState), '_blank');
  };

  closeErrorAlert  = () => this.setState({ errorAlertIsOpen: false });

  catchErrors = error => {
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  componentWillReceiveProps() {
    // AFTER EDIT //
    const { afterEditData } = this.props.history.location.state || {};

    if(!isEmpty(afterEditData)) {
      // get current table-data from the state w\o editing change (when render only)
      const { sessions } = this.state;

      // find editing data in all data by id
      for (let i = 0; i < sessions.length; i++) {
        if (sessions[i].id === afterEditData.id) {
          // inject editing data to table state
          sessions[i].sessionStatus = afterEditData.sessionStatus;
        }
      }

      // inject new array with edited data to table
      this.setState({ sessions });
    }

    // AFTER REFUND //
    const { afterRefundData } = this.props.history.location.state || {};

    if(!isEmpty(afterRefundData)) {
      // get current table-data from the state w\o editing change (when render only)
      const { sessions } = this.state;

      // find editing data in all data by id
      for (let i = 0; i < sessions.length; i++) {
        if (sessions[i].id === afterRefundData.id) {
          // inject editing data to table state
          sessions[i].status = 'refunded';
        }
      }

      // inject new array with edited data to table
      this.setState({ sessions });
    }
  }

  render() {
    const {
      // table
      sessions, sessionsCount, count, tableLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText,
    } = this.state;

    return (
      <div className="coaching-sessions-page">
        <div className="table-top">
          <p className="md-lg">
            Total records:&nbsp;<b>{count && formatNumber(this.state.count)}</b>
          </p>

          <a
            href='#'
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.exportToCsv}
          >
            Export as CSV
          </a>
        </div>

        { alertIsOpen && <Alerts type={alertType} errorText={alertErrorText} /> }

        <Table
          data={sessions}
          manual={true}
          pages={sessionsCount}
          loading={tableLoading}
          columns={[...columns]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
          onFetchData={state => {
            this.setState({ tableState: state, tableLoading: true });

            // count request
            getSessionsCount(state)
              .then(res => {
                this.setState({
                  count: res.data.count,
                  sessionsCount: Math.ceil(res.data.count / state.pageSize)
                })

                // data request
                getSessions(state)
                  .then(res => this.setState({ sessions: res.data, tableLoading: false }))
                  .catch(error => this.catchErrors(error));
              }).catch(error => this.catchErrors(error));
          }}
        />
      </div>
    )
  }
}

export default withHeaderTitle(CoachSessions);
