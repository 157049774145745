import React from 'react';
import { NavLink } from 'react-router-dom';

import { Input } from 'debounce-input-decorator';
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Select from "react-select";

import getCoachTypes from "./api/getCoachTypes";
import sessionStatusOptions from "./api/sessionStatusOptions";

import 'react-datepicker/dist/react-datepicker.css';
import './table.scss';

const customStyles = {
  // dropdown menu
  menu: (provided) => {
    return { ...provided, textAlign: 'left', width: 320 }
  },
  // control
  control: (provided) => {
    return { ...provided, padding: 0, border: '1px solid rgba(0,0,0,0.1)' }
  },
  // single options
  option: (provided, state) => ({ ...provided, borderBottom: '1px solid rgba(0,0,0,0.1)', minHeight: '22px' }),
};

const sessionsColumns = [

  // id //
  {
    Header: 'Id',
    accessor: 'id',
    width: 60,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { id } = original;
      return (
        <div className="ellipsis-text" title={id || ''}>{id || ''}</div>
      );
    },
    filterMethod: ({value}, { id }) => {
      return !value || !value.length || id.toString().includes(value);
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // created //
  {
    Header: 'Date & Time',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      );
    },
    filterMethod: ({value}, {created}) => {
      if (!value) {
        return true;
      }

      return window.moment(value).isSame(created, 'day');
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },

  // type //
  {
    Header: 'Session Type',
    accessor: 'service.type',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { id, service } = original;
      return (
        <div className="table-column-name ellipsis-text" title={service.type.name}>
          <NavLink to={`/coaching-sessions/${id}`}>
            {service.type.name}
          </NavLink>
        </div>
      );
    },
    filterMethod: ({value}, data) => {
      if (!value) {
        return true;
      }

      if (!data['service.type']) {
        return false;
      }

      return value.slug === data['service.type'].slug;
    },
    Filter: ({ filter, onChange }) => {
      return (
        <AsyncSelect
          className="session-type-select"
          placeholder="Select type..."
          isClearable={true}
          styles={customStyles}
          menuPlacement="auto"
          cacheOptions={true}
          defaultOptions={true}
          loadOptions={inputValue => getCoachTypes(inputValue).then(res => res.data)}
          getOptionValue={o => o.id}
          getOptionLabel={o => o.name}
          onChange={onChange}
          value={filter ? filter.value : ''}
        />
      )
    }
  },

  // talent //
  {
    Header: 'Talent',
    accessor: 'user',
    width: 180,
    Cell: ({ original }) => {
      const { user: {name = '', surname = ''} = {} } = original;
      return (
        <div
          className="text-capitalize"
          title={`${name} ${surname}`}
        >
          {`${name} ${surname}`}
        </div>
      );
    },
    filterMethod: ({value}, { user: {name, surname} = {} }) => {
      return (
        name.toLowerCase().includes(value.toLowerCase()) ||
        surname.toLowerCase().includes(value.toLowerCase())
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // sessionStatus //
  {
    Header: 'Session Status',
    accessor: 'sessionStatus',
    width: 160,
    Cell: ({ original }) => {
      const { sessionStatus } = original;
      const sessionStatusString = sessionStatus ? sessionStatus.replace(/_/g, ' ') : '';
      return (
        <div
          className="text-capitalize"
          title={sessionStatusString}
        >
          {sessionStatusString}
        </div>
      );
    },
    filterMethod: ({value}, { sessionStatus }) => {
      if (!value) {
        return true;
      }

      return value === sessionStatus;
    },
    Filter: ({ filter, onChange }) => {
      return (
        <Select
          onChange={
            option => {
              if (!option) {
                return onChange(null);
              }

              return onChange(option.value);
            }
          }
          options={sessionStatusOptions}
          className='sessions-select'
          menuPosition='fixed'
          value={filter ? sessionStatusOptions.find(option => option.value === filter.value) : sessionStatusOptions[0]}
        />
      )
    }
  },

  // // total //
  // {
  //   Header: 'Amount',
  //   accessor: 'total',
  //   width: 100,
  //   style: { textAlign: 'right' },
  //   Cell: ({ original }) => {
  //     const { total } = original;
  //     return (
  //       <div
  //         className="ellipsis-text"
  //         title={Math.round(total * 100) / 100 ? `$${Math.round(total * 100) / 100}` : 'Free'}
  //       >
  //         {Math.round(total * 100) / 100 ? `$${Math.round(total * 100) / 100}` : 'Free'}
  //       </div>
  //     );
  //   },
  //   filterMethod: ({value}, { total }) => {
  //     return !value || !value.length || (Math.round(total * 100) / 100).toString().includes(value);
  //   },
  //   Filter: ({ filter, onChange }) => (
  //     <Input
  //       value={filter ? filter.value : ''}
  //       onChange={event => onChange(event.target.value)}
  //       style={{ width: '100%', height: '38px' }}
  //       debounceTimeout={800}
  //     />
  //   ),
  // },
  //
  // // amount //
  // {
  //   Header: 'Less Fees',
  //   accessor: 'amount',
  //   width: 100,
  //   style: { textAlign: 'right' },
  //   Cell: ({ original }) => {
  //     const { amount } = original;
  //     return (
  //       <div
  //         className="ellipsis-text"
  //         title={Math.round(amount * 100) / 100 ? `$${Math.round(amount * 100) / 100}` : 'Free'}
  //       >
  //         {Math.round(amount * 100) / 100 ? `$${Math.round(amount * 100) / 100}` : 'Free'}
  //       </div>
  //     );
  //   },
  //   filterMethod: ({value}, { amount }) => {
  //     return !value || !value.length || (Math.round(amount * 100) / 100).toString().includes(value);
  //   },
  //   Filter: ({ filter, onChange }) => (
  //     <Input
  //       value={filter ? filter.value : ''}
  //       onChange={event => onChange(event.target.value)}
  //       style={{ width: '100%', height: '38px' }}
  //       debounceTimeout={800}
  //     />
  //   ),
  // },
];

export default sessionsColumns;
