import React, { Component } from 'react';

import isEmpty from 'lodash/isEmpty';

import Table from '../../components/Table';
import columns from './columns';

import Alerts from '../../components/Alerts';
import AddButton from '../../components/AddButton';

import formatNumber from './../../utils/formatNumber';

import { withHeaderTitle } from '../../../components/Header/HeaderTitle';

import getPromoCodes      from './api/getPromoCodes';
import getPromoCodesCount from './api/getPromoCodesCount';

import './table.scss';

class PromoCodes extends Component {
  UNSAFE_componentWillMount() { this.props.setHeaderTitle('Jobs Promo Codes') }

  state = {
    // table
    tableState: { filtered: [], sorted: [] },
    promoCodes: [], promoCodesCount: null, tableLoading: false, count: null,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', errorAlertIsOpen: false,

    // fields
    id: null, name: '', surname: '',
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  resetFields = () => {
    this.setState({
      name: '',
      surname: '',
    });
  }

  addClick = () => {
    // reset fields
    this.resetFields();

    // open page
    this.props.history.push('/jobs-promo-codes/new');
  }

  exportToCsv = (e) => {
    e.preventDefault();

  };

  closeErrorAlert  = () => this.setState({ errorAlertIsOpen: false });

  catchErrors = error => {
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  componentWillReceiveProps() {
    // AFTER EDIT //
    const { promoCodes } = this.state;
    const { afterAddData, afterEditData } = this.props.history.location.state || {};

    if (!isEmpty(afterAddData)) {
      this.setState({
        promoCodes: [afterAddData].concat(promoCodes),
      });
    }

    if(!isEmpty(afterEditData)) {
      // find editing data in all data by id
      for (let i = 0; i < promoCodes.length; i++) {
        if (promoCodes[i].id === afterEditData.id) {
          // inject editing data to table state
          promoCodes[i] = {
            id: afterEditData.id,
            created: afterEditData.created,
            name: afterEditData.name,
            value: afterEditData.value,
            codeLimit: afterEditData.codeLimit,
            limitPerUser: afterEditData.limitPerUser,
            used: afterEditData.used,
          };
        }
      }

      // inject new array with edited data to table
      this.setState({ promoCodes });
    }

    // AFTER DELETE //
    const { deletedId } = this.props.history.location.state || {};

    if(deletedId) {
      // get current table-data from the state w\o editing change (when render only)
      const { promoCodes, promoCodesCount, count } = this.state;
      const dataWithoutDeleted = [];

      for (let i = 0; i < promoCodes.length; i++) {
        // skiping deleted item and forming new array without it
        if (promoCodes[i].id !== deletedId) {
          // push all data without deleted item to new array
          dataWithoutDeleted.push(promoCodes[i]);
        }
      }
      this.setState({
        promoCodes: dataWithoutDeleted,
        promoCodesCount: promoCodesCount - 1,
        count: count - 1,
      });
    }
  }

  render() {
    const {
      // table
      promoCodes, promoCodesCount, count, tableLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText,

      // fields
      id, name, surname,
    } = this.state;

    const controlsColumn = [
      {
        Header: '',
        width: 30,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <div className="rt-custom__controls">
              
          </div>
        )
      }
    ];

    return (
      <div className="promo-codes-page">
        <div className="table-top">
          <p className="md-lg">
            Total records:&nbsp;<b>{count && formatNumber(this.state.count)}</b>
          </p>

          <AddButton
            text="promo code"
            addClick={this.addClick}
          />
        </div>

        { alertIsOpen && <Alerts type={alertType} id={id} name={name} surname={surname} errorText={alertErrorText} /> }

        <Table
          data={promoCodes}
          manual={true}
          pages={promoCodesCount}
          loading={tableLoading}
          columns={[...columns, ...controlsColumn]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
          onFetchData={state => {
            this.setState({ tableState: state, tableLoading: true });

            // count request
            getPromoCodesCount(state)
              .then(res => {
                this.setState({
                  count: res.data.count,
                  promoCodesCount: Math.ceil(res.data.count / state.pageSize)
                })

                // data request
                getPromoCodes(state)
                  .then(res => this.setState({ promoCodes: res.data, tableLoading: false }))
                  .catch(error => this.catchErrors(error));
              }).catch(error => this.catchErrors(error));
          }}
        />
      </div>
    )
  }
}

export default withHeaderTitle(PromoCodes);
