import React from 'react';
import { Button } from 'reactstrap';
import ConfirmModal from '../../components/Modals/Confirm/ConfirmModal';

import Spinner from '../../../components/Spinner';

const ConfirmPayModal = ({ isOpen, modalLoading, closeModal, confirmSubmit, children }) => (
  <ConfirmModal isOpen={isOpen} modalLoading={modalLoading} closeModal={closeModal}>
    <section className="section-container confirm-container">
      <span className="ion-close-round ReactModal__confirm__close" onClick={closeModal} />

      <div className='ReactModal__confirm__title'>
        {children}
      </div>

      {
        modalLoading ? (
          <div className="ReactModal__confirm__is-loading">
            <Spinner />
          </div>
        ) : (
          <footer className="ReactModal__confirm__buttons">
            <Button outline color="secondary" onClick={closeModal}>Cancel</Button>
            <Button outline color="primary" onClick={confirmSubmit}>Confirm</Button>
          </footer>
        )
      }
    </section>
  </ConfirmModal>
);


export default ConfirmPayModal;
