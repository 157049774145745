import React from 'react';

import {Link} from "react-router-dom";
import isEmpty from 'lodash/isEmpty';

import Table from '../../components/Table';
import columns from './columns';

import Alerts from '../../components/Alerts';
import AddButton from '../../components/AddButton';

import DeleteCampaign from './delete';

import formatNumber from './../../utils/formatNumber';

import { withHeaderTitle } from '../../../components/Header/HeaderTitle';

import deleteCampaign    from './api/deleteCampaign';
import getCampaigns      from './api/getCampaigns';
import getCampaignsCount from './api/getCampaignsCount';


class Campaigns extends React.Component {
  UNSAFE_componentWillMount() { this.props.setHeaderTitle('Campaigns') }


  state = {
    // table
    campaigns: [], campaignsCount: null, tableLoading: false, count: null,

    // alert
    alertIsOpen: false, alertType: '', alertErrorText: '',

    // delete
    deleteModalIsOpen: false, deleteModalLoading: false,

    // fields
    id: null,
    title: '',
    url: '',
    owner: '',
    weight: 0,
    status: 'live',
    locations: [],
    skills: [],
    roles: [],
    seniorities: [],
    companies: [],
    eventTypes: [],
    pages: []
  }

  // onChanges
  // onChangeCompany = company => this.setState({ companies, company_id: company.id });
  onChange          = e         => this.setState({ [e.target.name]: e.target.value });
  onChangeCompanies = companies => this.setState({ companies });
  onChangeUser      = user      => {
    this.setState({ user, employer_id: user.id });
  }

  resetFields = () => {
    this.setState({
      title: '',
      url: '',
      owner: '',
      weight: '',
      status: 'live',
      locations: [],
      skills: [],
      roles: [],
      seniorities: [],
      companies: [],
      eventTypes: [],
      pages: [],
    });
  }

  addClick = () => {
    // reset fields
    this.resetFields();

    // open page
    this.props.history.push('/campaigns/new');
  }

  deleteClick = original => () => {
    const { id, title } = original;
    this.setState({ id, title, deleteModalIsOpen: true, alertIsOpen: false });
  }

  deleteSubmit = () => {
    const dataWitoutDeleted = [];
    const { id, campaigns } = this.state;

    this.setState({ deleteModalLoading: true, errorAlertIsOpen: false });

    deleteCampaign(id)
      // if delete ok
      .then(() => {
        for (let i = 0; i < campaigns.length; i++) {
          // skiping deleted item and forming new array without it
          if (campaigns[i].id !== id) {
            // push all data without deleted item to new array
            dataWitoutDeleted.push(campaigns[i]);
          }
        }
        this.setState({
          // set new data w\o deleted item
          campaigns: dataWitoutDeleted,
          deleteModalIsOpen: false,
          deleteModalLoading: false,

          // show alert
          alertType: 'delete', alertIsOpen: true
        })

        // close alert after 2 sec
        setTimeout(() => {
          this.setState({ alertIsOpen: false });
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }

  closeDeleteModal = () => {
    const { deleteModalLoading } = this.state
    !deleteModalLoading && this.setState({ deleteModalIsOpen: false });
  }

  closeErrorAlert  = () => this.setState({ errorAlertIsOpen: false });

  catchErrors = error => {
    console.log(error);
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        deleteModalLoading: false,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  componentWillReceiveProps() {
    // new data after edit plan
    const { campaigns } = this.state;
    const { afterAddData, afterEditData } = this.props.history.location.state || {};
    const { detailState } = this.props.history.location.state || {};

    if (!isEmpty(afterAddData && detailState)) {
      this.setState({
        campaigns: [afterAddData].concat(campaigns),
      });
    }

    if(!isEmpty(afterEditData && detailState)) {
      // find editing data in all data by id
      for (let i = 0; i < campaigns.length; i++) {
        if (campaigns[i].id === afterEditData.id) {
          const {
            id,
            title,
            url,
            owner,
            weight,
            status,
            locations,
            skills,
            roles,
            seniorities,
            companies,
            eventTypes,
            pages,
            impressions,
          } = afterEditData;

          // inject editing data to table state
          campaigns[i] = {
            id,
            title,
            url,
            owner,
            weight,
            status,
            locations,
            skills,
            roles,
            seniorities,
            companies,
            eventTypes,
            pages,
            impressions,
          };
        }
      }

      // inject new array with edited data to table
      this.setState({ campaigns });
    }

    // AFTER DELETE //
    const { deletedId } = this.props.history.location.state || {};

    if (deletedId) {
      // get current table-data from the state w\o editing change (when render only)
      const { campaigns, campaignsCount, count } = this.state;
      const dataWithoutDeleted = [];

      for (let i = 0; i < campaigns.length; i++) {
        // skiping deleted item and forming new array without it
        if (campaigns[i].id !== deletedId) {
          // push all data without deleted item to new array
          dataWithoutDeleted.push(campaigns[i]);
        }
      }
      this.setState({
        campaigns: dataWithoutDeleted,
        campaignsCount: campaignsCount - 1,
        count: count - 1,
      });
    }
  }

  render() {
    const {
      // table
      campaigns, campaignsCount, count, tableLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText,

      // delete
      deleteModalLoading, deleteModalIsOpen,

      // fields
      id,
      title,
    } = this.state;

    const controlsColumn = [
      {
        Header: '',
        width: 30,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <div className="rt-custom__controls">
            <Link
              to={`/campaigns/new?copyId=${original.id}`}
            >
              <i className="ion-ios-copy" />
            </Link>
          </div>
        )
      },
      {
        Header: '',
        width: 30,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <div className="rt-custom__controls">
            <i className="ion-android-delete" onClick={this.deleteClick(original)} />
          </div>
        )
      }
    ];

    return (
      <div className="campaigns-page">
        <div className="table-top">
          <p className="md-lg">
            Total records:&nbsp;<b>{count && formatNumber(count)}</b>
          </p>

          <AddButton
            text="campaign"
            loading={deleteModalLoading}
            addClick={this.addClick}
          />
        </div>

        { alertIsOpen && <Alerts type={alertType} id={id} name={title} errorText={alertErrorText} /> }

        <DeleteCampaign
          id={id} title={title}
          isOpen={deleteModalIsOpen}
          deleteSubmit={this.deleteSubmit}
          modalLoading={deleteModalLoading}
          closeModal={this.closeDeleteModal}
        />

        <Table
          data={campaigns}
          manual={true}
          pages={campaignsCount}
          loading={tableLoading}
          columns={[...columns, ...controlsColumn]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
          onFetchData={state => {
            this.setState({ tableLoading: true });

            // count request
            getCampaignsCount(state)
              .then(res => {
                this.setState({
                  count: res.data.count,
                  campaignsCount: Math.ceil(res.data.count / state.pageSize)
                })

                // data request
                getCampaigns(state)
                  // .then(res => this.setState({ campaigns: res.data, tableLoading: false }))
                  .then(res => {
                    this.setState({ campaigns: res.data, tableLoading: false});
                  })
                  .catch(error => this.catchErrors(error));
              }).catch(error => this.catchErrors(error));
          }}
        />
      </div>
    )
  }
}

export default withHeaderTitle(Campaigns);
