import React, { Component } from 'react';

import Table from "../../components/Table";
import Select from "react-select";
import {Input} from "debounce-input-decorator";

import durationOptions from "./api/durationOptions";

const PH_PERCENT = 30; // percents

class Offerings extends Component {
  onChange = (typeId, roleId, value) => {
    const { services } = this.props;

    if (!value) {
      if (!services[typeId]) {
        return;
      }

      delete services[typeId][roleId];

      if (!Object.keys(services[typeId]).length) {
        delete services[typeId];
      }
    } else {
      if (!services[typeId]) {
        services[typeId] = {};
      }

      services[typeId][roleId] = value;
    }

    this.props.onChange(services);
  };

  onSwitch = (typeId, roleId, checked) => {
    if (checked) {
      this.onChange(typeId, roleId, {
        price: 0,
        duration: 60,
      });
    } else {
      this.onChange(typeId, roleId, null);
    }
  };

  getData = () => {
    const { types, roles, services } = this.props;

    return roles.map(role => ([
      role.name,
      ...types.map(type => ({
        role,
        type,
        value: services[type.id] && typeof services[type.id][role.id] !== 'undefined'
          ? services[type.id][role.id]
          : null
      }))
    ]));
  };

  getColumns = () => {
    const { types } = this.props;

    return [
      {
        Header: '',
        accessor: 'head',
        style: { textAlign: 'right' },
        width: 200,
        Cell: ({ original }) => {
          return (
            <div className="ellipsis-text" title={original[0] || ''}>{original[0] || ''}</div>
          );
        },
      },
      ...types.map((type, index) => ({
        Header: type.name,
        accessor: type.slug,
        Cell: ({ original }) => {
          const data = original[index + 1];

          return (
            <div>
              <div
                className="custom-checkbox"
                style={{float: 'left'}}
              >
                <label className="switch switch-warn switch-primary">
                  <input
                    name={type.slug}
                    type="checkbox"
                    checked={!!data.value}
                    onChange={event => this.onSwitch(data.type.id, data.role.id, event.target.checked)}
                  />
                  <span/>
                </label>
              </div>

              <Select
                value={data.value ? durationOptions.find(option => option.value === data.value.duration) : undefined}
                onChange={value => this.onChange(data.type.id, data.role.id, {...data.value, duration: value.value})}
                options={durationOptions}
                isSearchable={false}
                isDisabled={!data.value}
                placeholder='Duration'
                className='offerings-select'
                menuPosition='fixed'
              />

              <Input
                value={data.value ? data.value.price : ''}
                placeholder='Fee'
                onChange={event => this.onChange(data.type.id, data.role.id, {...data.value, price: event.target.value || 0})}
                style={{ width: '50px', height: '38px', marginRight: '5px' }}
                debounceTimeout={800}
                disabled={!data.value}
              />

              {
                data.value ?
                  <span>
                    {data.value.price ? `$${Math.round(data.value.price * (1 + (PH_PERCENT / 100)))}` : <span style={{color: '#4fc0b5'}}>Free</span>}
                  </span>
                  : null
              }
            </div>
          );
        }
      }))
    ];
  };

  render() {
    return (
      <div className="coaches-offerings-page">
        <Table
          filterable={false}
          sortable={false}
          showPagination={false}
          minRows={0}
          data={this.getData()}
          columns={this.getColumns()}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
        />
      </div>
    )
  }
}

export default Offerings;
