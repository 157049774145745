import axios from 'axios';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getPromoCode = id => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.get(
    `${API_URL}/${subUrl}/promo_codes/${id}`,
    {
      headers: { Authorization: token },
      params: {
        filter: {
          include: [
            'user',
            {
              sessions: [
                'user'
              ]
            },
          ]
        }
      }
    }
  )
};

export default getPromoCode;
