import React, {Component} from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import DeleteCoach from './delete';
import Sessions from './sessions';

import {Link} from "react-router-dom";
import {Button} from 'reactstrap';
import Alerts from '../../components/Alerts';
import Spinner from '../../../components/Spinner';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {API_URL} from '../../api/apiUrl';

import Offerings from "./offerings";
import Availability from "./availability";

import getCoach from './api/getCoach';
import addCoach from "./api/addCoach";
import editCoach from './api/editCoach';
import deleteCoach from './api/deleteCoach';
import getCompany from './api/getCompany';
import getCompanies from './api/getCompanies';
import getUsers from './api/getUsers';
import getSessionsCounter from "./api/getSessionsCounter";
import getCoachTypes from "./api/getCoachTypes";
import getCoachRoles from "./api/getCoachRoles";
import getCoachCalendars from './api/getCoachCalendars';
import checkCoachUrl from "./api/checkCoachUrl";
import statusOptions from './api/statusOptions';
// import formatNumber from "../../utils/formatNumber";

import './edit.scss';

class CoachDetail extends Component {
  state = {
    lifetimePayouts: 0,
    totalSessions: 0,
    canceledSessions: 0,
    rescheduledSessions: 0,
    noShowsSessions: 0,

    // fields
    id: null,
    name: '',
    surname: '',
    oldName: '',
    user: null,
    paypal: '',
    company: {id: null, name: ''}, company_id: null,
    previous_companies: [],
    url: '',
    defaultUrl: '',
    url_linkedin: '',
    url_dribbble: '',
    url_behance: '',
    url_github: '',
    url_website: '',
    headline: '',
    status: 'visible',
    trusted: false,
    // banned: false,
    calendars: [],
    details: '',

    services: {},
    availability: {},
    availabilitySettings: {
      available: [],
      buffer: 0,
      max_pd: 0,
      max_pw: 0,
      lead_time: 0,
      days_out: 0,
      timezone: null,
    },

    statusObj: statusOptions.find(status => status.value === 'live') || null,

    created: '', lastLogin: '',

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '',

    // api
    loading: false,
    checkingUrl: false,
    existsUrl: false,

    // delete
    deleteModalIsOpen: false, deleteModalLoading: false,

    coachTypes: [],
    coachRoles: [],

    // applied
    tabIndex: 0,
  }

  // change fields
  onChange = e => {
    if (e.target.type === 'checkbox') {
      this.setState({[e.target.name]: e.target.checked})
    } else {
      this.setState({[e.target.name]: e.target.value})
    }
  }

  onChangeUser = user => this.setState({ user, company: user.company, company_id: user.company_id });
  onChangeUrl = e => {
    const url = e.target.value;

    this.setState({
      url
    });

    if (!url || !url.length) {
      return;
    }

    if (url === this.state.defaultUrl) {
      this.setState({
        checkingUrl: false,
        existsUrl: false,
      });

      return;
    }

    this.setState({
      checkingUrl: true,
      existsUrl: false,
    });

    checkCoachUrl(url)
      .then(existsUrl => {
        this.setState({
          checkingUrl: false,
          existsUrl,
        });
      })
      .catch(() => {
        this.setState({
          checkingUrl: false,
          existsUrl: true,
        });
      });
  }
  onChangeCompany = company => this.setState({company, company_id: company.id});
  onChangePastCompanies = previous_companies => this.setState({previous_companies});
  onChangeCalendars = calendars => this.setState({calendars});
  onChangeStatus = statusObj => this.setState({statusObj, status: statusObj.value});
  onChangeDetails = details => this.setState({details});

  // close page and go back to table
  closeDetail = () => this.props.history.push('/coaches');

  closeDeleteModal = () => {
    const {deleteModalLoading} = this.state
    !deleteModalLoading && this.setState({deleteModalIsOpen: false});
  }

  closeErrorAlert = () => this.setState({errorAlertIsOpen: false});

  // add request
  addSubmit = e => {
    e.preventDefault();

    this.setState({loading: true, errorAlertIsOpen: false});

    const {state} = this;

    addCoach(state)
      .then(res => {
        // open alert
        this.setState({alertIsOpen: true, alertType: 'add'});

        // close alert after 2 sec and redirect to table
        setTimeout(() => {
          this.setState({loading: false, alertIsOpen: false});

          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/coaches',
            state: {
              afterAddData: res.data
            }
          })
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  };

  // edit request
  editSubmit = e => {
    e.preventDefault();
    this.setState({loading: true, errorAlertIsOpen: false});

    const {state} = this;

    editCoach(state)
      .then((res) => {
        // open alert
        this.setState({alertIsOpen: true, alertType: 'edit'});

        // close alert after 2 sec and redirect to table
        setTimeout(() => {
          this.setState({loading: false, alertIsOpen: false});

          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/coaches',
            state: {
              afterEditData: res.data
            }
          })
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }

  deleteClick = () => {
    this.setState({deleteModalIsOpen: true, alertIsOpen: false});
  }

  deleteSubmit = () => {
    const {id} = this.state;

    this.setState({
      loading: true,
      deleteModalIsOpen: false, // it may be lower in logic (now it is not needs if false)
      deleteModalLoading: true,
      errorAlertIsOpen: false
    });

    deleteCoach(id)
      .then(() => {
        this.setState({
          deleteModalIsOpen: false,
          deleteModalLoading: false,
          alertType: 'delete', alertIsOpen: true
        });

        setTimeout(() => {
          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/coaches',
            state: {deletedId: id}
          });

          this.setState({alertIsOpen: false});
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }

  catchErrors = error => {
    const {name, statusCode, message} = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');

    } else {
      this.setState({
        errorAlertIsOpen: true,
        loading: false,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  generateServiceObject = (services) => {
    const resultServices = {};

    services.forEach(service => {
      if (!resultServices[service.type_id]) {
        resultServices[service.type_id] = {};
      }

      resultServices[service.type_id][service.role_id] = {
        ...service,
        price: service.price / 100,
      };
    });

    return resultServices;
  };

  generateAvailabilityObject = (availability) => {
    const resultAvailability = {};

    availability.forEach(day => {
      if (!resultAvailability[day.id]) {
        resultAvailability[day.id] = [];
      }

      resultAvailability[day.id].push({
        start: day.start,
        end: day.end,
      });
    });

    return resultAvailability;
  };

  componentDidMount() {
    this.setState({loading: true});

    // get request
    this.getCoach()
      .then((coach) => {
        const promises = [];

        if (coach) {
          // SESSIONS COUNTER
          promises.push(
            getSessionsCounter(coach.id).then(res => { // get request
              const {
                totalSessions,
                canceledSessions,
                rescheduledSessions,
                noShowsSessions,
              } = res.data;

              this.setState({
                totalSessions,
                canceledSessions,
                rescheduledSessions,
                noShowsSessions,
              });
            })
          );
        }

        // TYPES
        promises.push(
          getCoachTypes().then(res => { // get request
            this.setState({
              coachTypes: res.data,
            });
          })
        );

        // ROLES
        promises.push(
          getCoachRoles().then(res => { // get request
            this.setState({
              coachRoles: res.data,
            });
          })
        );

        // COMPANY
        const {company_id} = this.state;
        this.setState({company: {id: null, name: 'Loading...'}}); // pre-loader

        if (company_id) {
          promises.push(
            getCompany(company_id).then(res => { // get request
              this.setState({
                company: res.data,
                company_id: res.data.id
              });
            })
          );
        } else {
          // if doesn't have - reset
          this.setState({
            company: {id: null, name: ''}
          });
        }

        // STATUS (get current statusObj {} from options mapping)
        const {status} = this.state;
        if (status) {
          statusOptions.forEach(i => {
            if (status === i.value) {
              this.setState({statusObj: i});
            }
          });
        } else {
          this.setState({statusObj: {}});
        }

        return Promise.all(promises);
      })
      .catch(error => this.catchErrors(error))
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getCoach = () => {
    const {match} = this.props;

    if (match.params.id === 'new') {
      return Promise.resolve();
    }

    return getCoach(match.params.id).then(res => {
      const {data} = res;
      const {general: user = {}} = data;

      this.setState({
        // fields
        id: data.id,
        name: user.name,
        surname: user.surname,
        oldName: `${user.name} ${user.surname}`,
        user: {
          ...user,
          id: data.userId,
        },
        paypal: data.paypal,
        company_id: user.company_id,
        previous_companies: data.previous_companies,
        url: data.url,
        defaultUrl: data.url,
        url_linkedin: data.links.linkedin,
        url_dribbble: data.links.dribbble,
        url_behance: data.links.behance,
        url_github: data.links.github,
        url_website: data.links.website,
        headline: data.headline,
        details: data.details || '',
        calendars: data.calendars || [],
        status: data.status,
        trusted: data.trusted || false,
        services: this.generateServiceObject(data.services || []),
        availability: this.generateAvailabilityObject(data.availability || []),
        availabilitySettings: data.availability_settings
          ? {
            ...data.availability_settings,
            available: data.availability_settings.available.split(','),
          }
          : {
            available: [],
            buffer: 0,
            max_pd: 0,
            max_pw: 0,
            lead_time: 0,
            days_out: 0,
            timezone: null,
          },

        created: data.created,
        lastLogin: user.lastLogin,
      });

      return data;
    });
  }

  onChangeServices = (services) => {
    this.setState({
      services,
    });
  };

  onChangeAvailabilitySettings = (availabilitySettings) => {
    this.setState({
      availabilitySettings,
    });
  };

  onChangeAvailability = (availability) => {
    this.setState({
      availability,
    });
  };

  render() {
    const {
      created, lastLogin,

      // fields
      id, name, surname, oldName, user, paypal,
      company, company_id, previous_companies,
      url, url_linkedin, url_dribbble, url_behance, url_github, url_website,
      headline, statusObj, details, calendars,
      services, availability, availabilitySettings, trusted,

      /* lifetimePayouts, */ totalSessions, canceledSessions, rescheduledSessions, noShowsSessions,

      // delete
      deleteModalIsOpen, deleteModalLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText, errorAlertIsOpen,

      // api
      loading, checkingUrl, existsUrl,

      coachTypes, coachRoles,

      // applied
      tabIndex,
    } = this.state;

    return (
      <section className="ph-detail-page  container  edit-coach">
        {
          alertIsOpen && (
            <Alerts id={id} name={name} surname={surname} type={alertType} errorText={alertErrorText}
                    errorAlertIsOpen={errorAlertIsOpen} closeErrorAlert={this.closeErrorAlert}/>
          )
        }

        <DeleteCoach
          isOpen={deleteModalIsOpen}
          modalLoading={deleteModalLoading}
          closeModal={this.closeDeleteModal}
          id={id} name={name} surname={surname}
          deleteSubmit={this.deleteSubmit}
        />

        {
          id
            ? <h4 className="ph-detail-page__title">Edit Coach: <b>{oldName}</b></h4>
            : <h4 className="ph-detail-page__title">Add Coach</h4>
        }
        <span className="ion-close-round ph-detail-page__close" onClick={this.closeDetail}/>

        {
          loading && <div className="ph-detail-page__is-loading"><Spinner/></div>
        }

        <form action="" onSubmit={id ? this.editSubmit : this.addSubmit}>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={tabIndex => this.setState({tabIndex})}
          >
            <TabList>
              <Tab>General Info</Tab>
              <Tab>Offerings</Tab>
              <Tab>Availability</Tab>
              {
                id ?
                  <Tab>Sessions</Tab>
                  : null
              }
            </TabList>
            <TabPanel>
              <div className="cardbox">
                <div className="cardbox-body">
                  {
                    id ?
                      <fieldset>
                        <div className="form-group row top-fields">
                          {/* payouts */}
                          {/*<div className="col-md-2 col-sm-6">*/}
                          {/*  <b>Lifetime Payouts</b>*/}
                          {/*  <span>${formatNumber(lifetimePayouts || 0)}</span>*/}
                          {/*</div>*/}

                          {/* total */}
                          <div className="col-md-2 col-sm-6">
                            <b>Total Sessions</b>
                            <span>{totalSessions || 0}</span>
                          </div>

                          {/* canceled */}
                          <div className="col-md-2 col-sm-6">
                            <b>Canceled</b>
                            <span>{canceledSessions || 0}</span>
                          </div>

                          {/* rescheduled */}
                          <div className="col-md-2 col-sm-6">
                            <b>Rescheduled</b>
                            <span>{rescheduledSessions || 0}</span>
                          </div>

                          {/* no-shows */}
                          <div className="col-md-2 col-sm-6">
                            <b>No-Shows</b>
                            <span>{noShowsSessions || 0}</span>
                          </div>
                        </div>
                      </fieldset>
                      : null
                  }

                  <fieldset>
                    <div className="form-group row">

                      {/* col-md-3  edit-coach__left */}
                      <div className="col-md-3  edit-coach__left">
                        <div className="edit-image">
                          <label htmlFor="edit-image" className="edit-image__label">Profile photo</label>
                          {user && user.image && user.image.url && user.image.url.length
                            ? <img
                              className="image"
                              src={user.image.url.includes('http') ? `${user.image.url}` : `${API_URL}${user.image.url}`}
                              alt=""
                            />
                            : <div className="no-image">No image</div>
                          }
                        </div>

                        {
                          id ?
                            <>
                              <div className="created">
                                <label htmlFor="edit-created">Created</label>
                                <div
                                  id="edit-created">{created ? `${created.substring(0, 10)}, ${created.substring(11, 16)} UTC` : '一'}</div>
                              </div>

                              <div className="lastLogin">
                                <label htmlFor="edit-lastLogin">Last login</label>
                                <div
                                  id="edit-lastLogin">{lastLogin ? `${lastLogin.substring(0, 10)}, ${lastLogin.substring(11, 16)} UTC` : '一'}</div>
                              </div>
                            </>
                            : null
                        }
                        <div className="trusted">
                          <label htmlFor="">Trusted</label>
                          <div className="custom-checkbox">
                          <label className="switch switch-warn switch-primary">
                          <input
                            id="edit-trusted"
                            name="trusted"
                            type="checkbox"
                            checked={trusted}
                            onChange={this.onChange}
                          />
                          <span/>
                          </label>
                         </div>
                        </div>
                        {/*<div className="banned">*/}
                        {/*  <div className="custom-checkbox">*/}
                        {/*    <label className="switch switch-warn switch-primary">*/}
                        {/*      <input*/}
                        {/*        id="edit-banned"*/}
                        {/*        name="banned"*/}
                        {/*        type="checkbox"*/}
                        {/*        checked={banned}*/}
                        {/*        onChange={this.onChangeRoles}*/}
                        {/*      />*/}
                        {/*      <span/>*/}
                        {/*    </label>*/}

                        {/*    <label*/}
                        {/*      htmlFor="edit-banned"*/}
                        {/*      style={{*/}
                        {/*        fontWeight: 'normal',*/}
                        {/*        paddingLeft: 0,*/}
                        {/*        marginBottom: 0*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      {banned ? 'Banned' : 'Not banned'}*/}
                        {/*    </label>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                      {/* col-md-3  edit-coach__left */}


                      {/* col-md-9  edit-coach__right */}
                      <div className="col-md-9  edit-coach__right">
                        <div className="row">

                          {/* user */}
                          <div className="col-md-4">
                            <label htmlFor="edit-owner">
                              User&nbsp;
                              {
                                user ?
                                  <Link
                                    style={{ fontWeight: 'normal' }}
                                    to={`/users/${user.id}`}
                                    title={`.../users/${user.id}`}
                                    target='_blank'>
                                    edit in a new tab
                                  </Link>
                                  : null
                              }
                            </label>

                            <AsyncSelect
                              menuPlacement="auto"
                              cacheOptions={true}
                              defaultOptions={true}
                              loadOptions={inputValue => getUsers(inputValue).then(res => res.data)}
                              getOptionValue={o => o.id}
                              getOptionLabel={o => (
                                <div>
                                  <span>{`${o.name} ${o.surname} `}</span>
                                  <span style={{color: '#1976D2'}}>{o.email}</span>
                                </div>
                              )}
                              onChange={this.onChangeUser}
                              value={user}
                            />
                          </div>

                          {/* paypal */}
                          <div className="col-md-4">
                            <label htmlFor="edit-paypal">PayPal Email</label>

                            <input
                              name="paypal"
                              value={paypal}
                              id="edit-paypal"
                              onChange={this.onChange}
                              type="email"
                              className="form-control"
                              readOnly={id}
                            />
                          </div>

                          {/* company */}
                          <div className="col-md-4">
                            <label htmlFor="edit-company_id">
                              Current Company&nbsp;
                              {
                                company_id ?
                                  <Link
                                    style={{ fontWeight: 'normal' }}
                                    to={`/companies/${company_id}`}
                                    title={`.../companies/${company_id}`}
                                    target='_blank'>
                                    edit in a new tab
                                  </Link>
                                  : null
                              }
                            </label>

                            <input
                              hidden
                              name="company_id"
                              value={company_id}
                              id="edit-company_id"
                              onChange={this.onChange}
                              type="number"
                              className="form-control"
                            />

                            <AsyncSelect
                              menuPlacement="auto"
                              cacheOptions={true}
                              defaultOptions={true}
                              loadOptions={inputValue => getCompanies(inputValue).then(res => res.data)}
                              getOptionValue={o => o.id}
                              getOptionLabel={o => o.name}
                              value={company}
                              onChange={this.onChangeCompany}
                            />

                          </div>

                          {/* previous_companies */}
                          <div className="col-md-12">
                            <label htmlFor="edit-previous_companies">
                              Past Companies&nbsp;
                              <Link
                                style={{ fontWeight: 'normal' }}
                                to="/companies"
                                title=".../companies"
                                target='_blank'>
                                open in a new tab
                              </Link>
                            </label>

                            <input
                              hidden
                              name="previous_companies"
                              value={previous_companies}
                              id="edit-previous_companies"
                              onChange={this.onChange}
                              type="number"
                              className="form-control"
                            />

                            <AsyncSelect
                              isMulti
                              menuPlacement="auto"
                              cacheOptions={true}
                              defaultOptions={true}
                              loadOptions={inputValue => getCompanies(inputValue).then(res => res.data)}
                              getOptionValue={o => o.id}
                              getOptionLabel={o => o.name}
                              value={previous_companies}
                              onChange={this.onChangePastCompanies}
                            />

                          </div>


                          {/* url */}
                          <div className="col-md-4">
                            <label htmlFor="edit-coaching_url">Coaching URL</label>

                            <div className="input-group mb-3">
                              <input
                                required
                                name="url"
                                value={url}
                                id="edit-url"
                                onChange={this.onChangeUrl}
                                type="text"
                                className="form-control"
                              />
                              {
                                url && url.length ?
                                  <div className="input-group-text">
                                    {
                                      checkingUrl
                                        ? <i className="ion-load-c icon-fw" />
                                        : (
                                          existsUrl
                                            ? <i className="ion-android-close icon-fw" />
                                            : <i className="ion-android-done icon-fw" />
                                        )
                                    }
                                  </div>
                                  : null
                              }
                            </div>
                          </div>

                          {/* url_linkedin */}
                          <div className="col-md-4">
                            <label htmlFor="edit-url_linkedin">LinkedIn</label>

                            <input
                              name="url_linkedin"
                              value={url_linkedin}
                              id="edit-url_linkedin"
                              onChange={this.onChange}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          {/* url_dribbble */}
                          <div className="col-md-4">
                            <label htmlFor="edit-url_dribbble">Dribbble</label>

                            <input
                              name="url_dribbble"
                              value={url_dribbble}
                              id="edit-url_dribbble"
                              onChange={this.onChange}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          {/* url_behance */}
                          <div className="col-md-4">
                            <label htmlFor="edit-url_behance">Behance</label>

                            <input
                              name="url_behance"
                              value={url_behance}
                              id="edit-url_behance"
                              onChange={this.onChange}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          {/* url_github */}
                          <div className="col-md-4">
                            <label htmlFor="edit-url_github">GitHub</label>

                            <input
                              name="url_github"
                              value={url_github}
                              id="edit-url_github"
                              onChange={this.onChange}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          {/* url_website */}
                          <div className="col-md-4">
                            <label htmlFor="edit-url_website">Website</label>

                            <input
                              name="url_website"
                              value={url_website}
                              id="edit-url_website"
                              onChange={this.onChange}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          {/* headline */}
                          <div className="col-md-8">
                            <label htmlFor="edit-headline">Headline</label>

                            <input
                              name="headline"
                              value={headline}
                              id="edit-headline"
                              onChange={this.onChange}
                              type="text"
                              className="form-control"
                            />
                          </div>

                          {/* status */}
                          <div className="col-md-4">
                            <label htmlFor="edit-status">Visibility</label>

                            <Select
                              value={statusObj}
                              onChange={this.onChangeStatus}
                              options={statusOptions}
                            />
                          </div>

                          {/* calendars */}
                          <div className="col-md-12">
                            <label htmlFor="edit-calendars">Calendars</label>

                            <input
                              hidden
                              name="calendars"
                              value={calendars}
                              id="edit-calendars"
                              onChange={this.onChange}
                              type="number"
                              className="form-control"
                            />

                            <AsyncSelect
                              isMulti
                              menuPlacement="auto"
                              cacheOptions={true}
                              defaultOptions={true}
                              loadOptions={inputValue => getCoachCalendars(inputValue).then(res => res.data)}
                              getOptionValue={o => o.id}
                              getOptionLabel={o => o.name}
                              value={calendars}
                              onChange={this.onChangeCalendars}
                            />

                          </div>

                          {/* details */}
                          <div className="col-md-12">
                            <label htmlFor="edit-details">Details</label>

                            <CKEditor
                              editor={ClassicEditor}
                              data={details}
                              onInit={editor => {
                                // You can store the "editor" and use when it is needed.
                                // console.log( 'Editor is ready to use!', editor );
                              }}
                              // onChange={onChangeDetails}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log( { event, editor, data } );
                                this.onChangeDetails(data);
                              }}
                              onBlur={(event, editor) => {
                                // console.log( 'Blur.', editor );
                              }}
                              onFocus={(event, editor) => {
                                // console.log( 'Focus.', editor );
                              }}
                            />
                          </div>

                        </div>
                      </div>
                      {/* col-md-9  edit-coach__right */}

                    </div>
                  </fieldset>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <Offerings
                services={services}
                types={coachTypes}
                roles={coachRoles}
                onChange={this.onChangeServices}
              />
            </TabPanel>
            <TabPanel>
              <Availability
                availability={availability}
                settings={availabilitySettings}
                onChange={this.onChangeAvailability}
                onChangeSettings={this.onChangeAvailabilitySettings}
              />
            </TabPanel>
            {
              id ?
                <TabPanel>
                  <Sessions
                    coachId={id}
                  />
                </TabPanel>
                : null
            }
          </Tabs>

          <footer className="ph-detail-page__buttons" style={{paddingBottom: '16px'}}>
            {
              id
                ? <Button outline color="danger" onClick={this.deleteClick}>Delete</Button>
                : null
            }
            <Button outline color="secondary" onClick={this.closeDetail}>Cancel</Button>
            <Button disabled={!user || !url.length || existsUrl || checkingUrl} outline color="primary" type="submit">Save</Button>
          </footer>
        </form>
      </section>
    );
  }
}

export default CoachDetail;
