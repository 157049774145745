import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const editCoach = state => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;
  const {
    id,
    status,
    url,
    user,
    company_id,
    previous_companies,
    headline,
    details,
    url_linkedin,
    url_dribbble,
    url_behance,
    url_github,
    url_website,
    availability,
    availabilitySettings,
    services,
    calendars,
    trusted,
  } = state;

  const resultAvailability = [];

  Object.keys(availability).forEach(monthId => {
    availability[monthId].forEach(({ start, end }) => {
      resultAvailability.push({
        id: monthId,
        start,
        end,
      });
    });
  });

  const resultServices = [];

  Object.keys(services).forEach(type_id => {
    Object.keys(services[type_id]).forEach(role_id => {
      const { duration, price } = services[type_id][role_id];

      resultServices.push({
        role_id,
        type_id,
        price: price * 100,
        duration,
      })
    });
  });

  return axios.post(
    `${API_URL}/${subUrl}/coaches/${id}/update`,

    {
      status,
      url,
      userId: user.id,
      company_id,
      previous_companies,
      headline,
      details,
      url_linkedin,
      url_dribbble,
      url_behance,
      url_github,
      url_website,
      trusted,
      modified: `${new Date().toISOString()}`,
      availability: resultAvailability,
      availability_settings: {
        ...availabilitySettings,
        available: availabilitySettings.available.join(','),
      },
      services: resultServices,
      calendars: calendars.map(calendar => calendar.id),
    },

    {
      headers: { Authorization: token }
    }
  );
}

export default editCoach;
