import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const getAvailablePlans = () => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.get(
    `${API_URL}/${subUrl}/plans/available`,
    {
      headers: { Authorization: token }
    }
  ).then(res => res.data);
};

export default getAvailablePlans;
