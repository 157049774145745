import React, {Component} from 'react';
import cx from 'classnames';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import { Input } from 'debounce-input-decorator';
import 'react-tabs/style/react-tabs.css';

import DeletePromoCode from './delete';
import Payments from './payments';

import {Button} from 'reactstrap';
import Alerts from '../../components/Alerts';
import Spinner from '../../../components/Spinner';

import getPromoCode from './api/getPromoCode';
import addPromoCode from "./api/addPromoCode";
import editPromoCode from './api/editPromoCode';
import deletePromoCode from './api/deletePromoCode';
import checkPromoCodeUniqueName from "./api/checkPromoCodeUniqueName";
import getAvailablePlans from "./api/getAvailablePlans";

import './edit.scss';
import getPayments from './api/getPayments';

class PromoCodeDetail extends Component {
  state = {
    created: '',

    // fields
    id: null,
    name: '',
    discount: 0,
    limit: null,
    limitPerUser: null,
    start: null,
    used: 0,
    end: null,
    plans: null,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '',

    // api
    loading: false,
    checkingName: false,
    existsName: false,

    availablePlans: null,
    paymants: null,

    // delete
    deleteModalIsOpen: false, deleteModalLoading: false,

    // applied
    tabIndex: 0,
  }

  onChange = e => {
    if (e.target.type === 'checkbox') {
      this.setState({[e.target.name]: e.target.checked})
    } else {
      this.setState({[e.target.name]: e.target.value})
    }
  }

  // close page and go back to table
  closeDetail = () => this.props.history.push('/jobs-promo-codes');

  closeDeleteModal = () => {
    const {deleteModalLoading} = this.state
    !deleteModalLoading && this.setState({deleteModalIsOpen: false});
  }

  deleteClick = () => {
    this.setState({deleteModalIsOpen: true, alertIsOpen: false});
  }

  deleteSubmit = () => {
    const {id} = this.state;

    this.setState({
      loading: true,
      deleteModalIsOpen: false, // it may be lower in logic (now it is not needs if false)
      deleteModalLoading: true,
      errorAlertIsOpen: false
    });

    deletePromoCode(id)
      .then(() => {
        this.setState({
          deleteModalIsOpen: false,
          deleteModalLoading: false,
          alertType: 'delete', alertIsOpen: true
        });

        setTimeout(() => {
          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/jobs-promo-codes',
            state: {deletedId: id}
          });

          this.setState({alertIsOpen: false});
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }

  // edit request
  editSubmit = e => {
    e.preventDefault();
    this.setState({loading: true, errorAlertIsOpen: false});

    const {state} = this;

    editPromoCode(state)
      .then((res) => {
        // open alert
        this.setState({alertIsOpen: true, alertType: 'edit'});

        // close alert after 2 sec and redirect to table
        setTimeout(() => {
          this.setState({loading: false, alertIsOpen: false});

          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/jobs-promo-codes',
            state: {
              afterEditData: res.data
            }
          })
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }
  onKeyUp = e => {
    if (this.state.name !== e.target.value)
    this.setState({checkingName: true});
  }
  onChangeName = e => {
    const name = e.target.value.toUpperCase();
    this.setState({name});
    console.log(name);
    if (name.trim().length)
    checkPromoCodeUniqueName(name)
      .then(existsName => {
        this.setState({
          checkingName: false,
          existsName,
        });
      })
      .catch(() => {
        this.setState({
          checkingName: false,
          existsName: true,
        });
      });
    else this.setState({checkingName: false, existsName: false});

  }

  onChangeDiscount = e => {
    const discount = parseInt(e.target.value);
    this.setState({discount});
  }

  onChangePlan = (e, plan) => {
    const plans = this.state.plans || [];
    const index = plans.indexOf(plan.id);
    if (index === -1) plans.push(plan.id); 
    else plans.splice(index, 1);
    this.setState({
        plans: plans.length ? plans : null
    });
  }

  // add request
  addSubmit = e => {
    e.preventDefault();

    this.setState({loading: true, errorAlertIsOpen: false});

    const {state} = this;

    addPromoCode(state)
      .then(res => {
        // open alert
        this.setState({alertIsOpen: true, alertType: 'add'});

        // close alert after 2 sec and redirect to table
        setTimeout(() => {
          this.setState({loading: false, alertIsOpen: false});
          res.data.used = 0;

          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/jobs-promo-codes',
            state: {
              afterAddData: res.data
            }
          })
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  };

  catchErrors = error => {
    const {name, statusCode, message} = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');

    } else {
      this.setState({
        errorAlertIsOpen: true,
        loading: false,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }


  componentDidMount() {
    this.loadPromoCode(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tabIndex: 0,
    })

    this.loadPromoCode(nextProps);
  }

loadPromoCode(props) {
    this.setState({loading: true});

    // get request
    this.getCode(props)
      .then(() => {
        getPayments(this.state.id).then(res => {
            const {data} = res;
            this.setState({
                payments: data
            })
        });
      })
      .catch(error => this.catchErrors(error))
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
     getAvailablePlans()
        .then((plans) => {
            this.setState({
                availablePlans: plans
            });
        })
        .catch(error => this.catchErrors(error))
        .finally(() => {

        });
  }

  getCode = (props) => {
    const {match} = props;
    if (match.params.id === 'new') return Promise.resolve();
    else 
    return getPromoCode(match.params.id).then(res => {
      const {data} = res;
      
      this.setState({
          created: data.created || '',
          
          // fields
          id: data.id,
          name: data.name,
          discount: data.value,
          limit: data.codeLimit,
          limitPerUser: data.limitPerUser,
          start: data.dateStart,
          end: data.dateEnd,
          used: data.used,
          plans: data.plans,
        }
      );

      return data;
    });
  }

  render() {
    const {
      created,

      // fields
      id, name, discount,
      limit, limitPerUser, start, end, used, checkingName, existsName, plans,

      // delete
      deleteModalIsOpen, deleteModalLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText, errorAlertIsOpen,

      // api
      loading,
      availablePlans,
      payments,

      // applied
      tabIndex,
    } = this.state;

    return (
      <section className="ph-detail-page container edit-promo-code">
        {
          alertIsOpen && (
            <Alerts id={id} name={name} type={alertType} errorText={alertErrorText}
                    errorAlertIsOpen={errorAlertIsOpen} closeErrorAlert={this.closeErrorAlert}/>
          )
        }

        <DeletePromoCode
          isOpen={deleteModalIsOpen}
          modalLoading={deleteModalLoading}
          closeModal={this.closeDeleteModal}
          id={id} name={name}
          deleteSubmit={this.deleteSubmit}
        />

        <div className="table-top">
          {
            id
              ? <h4 className="ph-detail-page__title">Edit Promo Code: <b>{name}, {discount}%</b></h4>
              : <h4 className="ph-detail-page__title">Add Promo Code</h4>
          }
        </div>
        <span className="ion-close-round ph-detail-page__close" onClick={this.closeDetail}/>

        {
          loading && <div className="ph-detail-page__is-loading"><Spinner/></div>
        }

        <form action="" onSubmit={id ? this.editSubmit : this.addSubmit}>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={tabIndex => this.setState({tabIndex})}
          >
            <TabList>
              <Tab>General Info</Tab>
              {id && <Tab>Payments ({used})</Tab>}
            </TabList>
            <TabPanel>
              <div className="cardbox">
                <div className="cardbox-body">
                  {
                    id ?
                      <fieldset>
                        <div className="form-group row top-fields">
                          {/* created */}
                          <div className="col-md-2 col-sm-6">
                            <b>Created</b>
                            <span>{`${created.substring(0, 10)}, ${created.substring(11, 16)} UTC`}</span>
                          </div>

                          {/* used */}
                          <div className="col-md-2 col-sm-6">
                            <b>Used</b>
                            <span>{used}</span>
                          </div>
                        </div>
                      </fieldset>
                      : null
                  }

                  <fieldset>
                    <div className="form-group row">

                      {/* name */}
                      <div className="col-md-4">
                        <label htmlFor="edit-promo-code_name">Name</label>

                        <div className="input-group mb-3">
                          <Input
                            required
                            name="name"
                            value={name}
                            id="edit-promo-code_name"
                            disabled={id}
                            onChange={this.onChangeName}
                            onKeyUp={this.onKeyUp}
                            debounceTimeout={1000}
                            type="text"
                            className={cx('form-control', {
                              'check-name-error': null
                            })}
                          />
                          {
                            !id ?
                              <div className="input-group-text">
                                {
                                  checkingName
                                    ? <i className="ion-load-c icon-fw"/>
                                    : (
                                      existsName
                                        ? <i className="ion-android-close icon-fw"/>
                                        : <i className="ion-android-done icon-fw"/>
                                    )
                                }
                              </div>
                              : null
                          }
                        </div>
                      </div>

                      {/* discount */}
                      <div className="col-md-2">
                        <label htmlFor="edit-discount">Discount, %</label>

                        <input
                          min={1}
                          max={100}
                          name="discount"
                          type="number"
                          value={discount}
                          id="edit-discount"
                          disabled={id}
                          onChange={this.onChangeDiscount}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">

                      {/* limit */}
                      <div className="col-md-2">
                        <label htmlFor="edit-limit">Limit</label>

                        <input
                          min={0}
                          max={99999}
                          name="limit"
                          type="number"
                          value={limit ? limit : ""}
                          id="edit-limit"
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>

                      {/* limit per user*/}
                      <div className="col-md-2">
                        <label htmlFor="edit-limit">Limit Per User</label>

                        <input
                          min={0}
                          max={99999}
                          name="limitPerUser"
                          type="number"
                          value={limitPerUser ? limitPerUser : ""}
                          id="edit-limit"
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>

                      {/* start */}
                      <div className="col-md-2">
                        <label htmlFor="edit-start">Start</label>
                        <input
                          type="date"
                          name="start"
                          id="edit-start"
                          value={start ? start.substring(0, 10) : ''}
                          style={{height: '38px'}}
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>

                      {/* end */}
                      <div className="col-md-2">
                        <label htmlFor="edit-end">End</label>
                        <input
                          type="date"
                          name="end"
                          id="edit-end"
                          value={end ? end.substring(0, 10) : ''}
                          style={{height: '38px'}}
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>

                    </div>
                    <div className="form-group row">

                      <div className="col-md-6">
                        <label>Plans</label>
                        <div className="row">
                            {availablePlans ? availablePlans.map(plan => 
                                <div className="col-md-4" key={plan.id}>
                                <div className="custom-control custom-checkbox custom-checkbox--service">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`edit-plans-${plan.id}`}
                                    name={`plans-${plan.id}`}
                                    checked={plans && plans.includes(plan.id) ? true : false}
                                    onChange={e => this.onChangePlan(e, plan)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`edit-plans-${plan.id}`}
                                    style={{fontWeight: 'normal'}}
                                  >
                                    {plan.name} (${plan.price})
                                  </label>
                                </div>
                              </div>
                            ) : <span>Loading ...</span>}
                        </div>
                      </div>

                    </div>
                  </fieldset>
                </div>
              </div>

              <footer className="ph-detail-page__buttons" style={{paddingBottom: '16px'}}>
                {
                  id
                    ? <Button outline color="danger" onClick={this.deleteClick}>Delete</Button>
                    : null
                }
                <Button outline color="secondary" onClick={this.closeDetail}>Cancel</Button>
                <Button disabled={!name.length || !discount || checkingName || existsName} outline
                        color="primary"
                        type="submit">Save</Button>
              </footer>
            </TabPanel>
            {id &&
              <TabPanel>
                  {
                      payments ?
                      <Payments
                          availablePlans = {availablePlans}
                          payments = {payments}
                      />
                      : <span>Loading ...</span>
                  }
              </TabPanel>
            }
          </Tabs>
        </form>
      </section>
    );
  }
}

export default PromoCodeDetail;
