import React, { Component } from 'react';

import Table from '../../components/Table';
import columns from './paymentsColumns';

class Payments extends Component {
  exportToCsv = (e) => {
    e.preventDefault();
  };
  getPlanName = (id, plans) => {
    const plan = plans.filter(p => p.id === id)
    return plan.length ? plan[0].name : 'undefined';
  }
  render() {
    const { payments, availablePlans } = this.props;
    return (
      <div className="promo-codes-page">
        <div className="table-top">
          <p className="md-lg">
            Total records:&nbsp;<b>{payments.length}</b>
          </p>
        </div>

        <Table
          data={payments.map(p => {return {...p, planName: this.getPlanName(p.plan_id, availablePlans)}})}
          //showPagination={false}
          //pages={payments.length}
          columns={[...columns]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
        />
      </div>
    )
  }
}

export default Payments;
