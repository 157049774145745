import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';

// state - own react-table state
// data-path - url-parh for request
const getSessionsCount = (state) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const { filtered } = state;

  // where template for request
  const where = {};

  // when we are typing something inside filter-fields state of react-table is changing
  // we take filter inputs values and inject to request params
  filtered.forEach(i => {
    // created //
    if (i.id === 'created') {
      const createdDate = i.value && format(i.value, 'yyyy-MM-dd');
      if (createdDate) {
        return where.created = {'gt': createdDate}
      }

      // coach //
    } else if (i.id === 'coach') {
      where.coach = {'like': '%' + i.value + '%'}

      // paypal //
    } else if (i.id === 'paypal') {
      where.paypal = {'like': '%' + i.value + '%'}

      // status //
    } else if (i.id === 'status') {
      where.status = i.value;

      // sessions //
    } else if (i.id === 'sessions') {
      if (i.value) {
        where.sessions = parseInt(i.value);
      }

      // amount //
    } else if (i.id === 'amount') {
      if (i.value) {
        where.amount = parseInt(i.value);
      }

      // total //
    } else if (i.id === 'total') {
      if (i.value) {
        where.total = parseInt(i.value);
      }

    } else {
      where[i.id] = {'like': '%' + i.value + '%'}
    }
  });

  // get-request for count
  return axios.get(`${API_URL}/${subUrl}/payouts/searchExtra`, {
    // inject where to params
    params: {
      filter: { where },
      count: true
    },
    headers: { Authorization: token } // backend doesn't check it
  })
};

export default getSessionsCount;
