import React from 'react';

import Table from '../../components/Table';
import coachesColumns from "./coachesColumns";

const Coaches = ({ tabIndex, payouts, controlsColumn, tableLoading }) => (
  <>
    <div className="form-group row top-fields">
      {/* coaches */}
      <div className="col-md-2 col-sm-4">
        Coaches: {!tableLoading ? payouts.length : 0}
      </div>

      {/* payouts total */}
      <div className="col-md-3 col-sm-4">
        Payouts Total Amount: ${!tableLoading ? Math.round(payouts.reduce((prev, payout) => prev + payout.total, 0) * 100) / 100 : 0}
      </div>

      {/* payouts total less fees */}
      <div className="col-md-3 col-sm-4">
        <b>Payouts Total Less Fees: ${!tableLoading ? Math.round(payouts.reduce((prev, payout) => prev + payout.amount, 0) * 100) / 100 : 0}</b>
      </div>
    </div>

    <Table
      showPagination={false}
      data={payouts}
      loading={tableLoading}
      columns={[...coachesColumns(tabIndex), ...controlsColumn]}
      getTheadFilterThProps={(state, rowInfo, column) => {
        return { style: { overflow: 'visible' }};
      }}
    />
  </>
);

export default Coaches;
