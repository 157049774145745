import axios from 'axios';
import {API_URL, subUrl} from '../../../api/apiUrl';

const getCoaches = inputValue => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const where = {};

  if (inputValue && inputValue.length) {
    where.fullName = {
      like: `%${inputValue}%`
    };
  }

  return axios.get(
    `${API_URL}/${subUrl}/coaches/searchExtra`,
    {
      params: {
        filter: {
          where,
          limit: 10
        }
      },
      headers: {Authorization: token}
    }
  );
}

export default getCoaches;
