const paymentStatusOptions = [
  {value: null, label: 'All'},
  {value: 'pending', label: 'Pending'},
  {value: 'success', label: 'Successful'},
  {value: 'refund_pending', label: 'Refund pending'},
  {value: 'refund_fail', label: 'Refund fail'},
  {value: 'refunded', label: 'Refunded'},
];

export default paymentStatusOptions;
