import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getCampaignLogs = (state, campaign_id) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const { pageSize, page, filtered, sorted } = state; // from own state of react-table

  // filter-template
  const filter = {
    where: {
      campaign_id,
    },
    limit: pageSize,
    skip: page * pageSize,
    order: 'id DESC'
  };

  // inject where to filter
  filtered.forEach(i => {
    // Id // +
    if (i.id === 'created') {
      const createdDate = i.value && format(i.value, 'yyyy-MM-dd');
      filter.where.created = { 'gt': createdDate };
      // URL
    } else if (i.id === 'url') {
      filter.where[i.id] = { 'like': '%' + i.value + '%' };
      // User
    } else if (i.id === 'user' && i.value) {
      filter.where['user_id'] = i.value.id;
    }
  });


  // inject order to filter
  sorted.forEach(i => {
    const desc = i.desc ? 'DESC' : 'ASC'
      filter.order = `${i.id} ${desc}`;
  });

  // get-request for data
  return axios.get(`${API_URL}/${subUrl}/campaign_logs/searchExtra`, {
    params: { filter },
    headers: { Authorization: token }
  })
};

export default getCampaignLogs;
