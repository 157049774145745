import React from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';

const columns = [
    // created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    filterable: false,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      )
    },
  },
  // area //
  {
    Header: 'Session Type',
    accessor: 'sessionType',
    width: 120,
    filterable: false,
    Cell: ({ original }) => {
      const { service, id  } = original;
      return (
        <div className="ellipsis-text">
          <NavLink target='_blank' to={`/coaching-sessions/${id}`}>{service.role.name}</NavLink>
        </div>
      );
    },
  },

  // name //
  {
    Header: 'Coach',
    accessor: 'coach',
    style: { fontWeight: 'bold' },
    filterable: false,
    Cell: ({ original }) => {
      const { coach, coachId } = original;

      return (
        <div className="table-column-name ellipsis-text" title={`${coach.general.name}`}>
          <NavLink target='_blank' to={`/coaches/${coachId}`}>{`${coach.general.name} ${coach.general.surname}`}</NavLink>
        </div>
      );
    },
  },
  // plan //
  {
    Header: 'Scheduled',
    accessor: 'scheduled',
    width: 120,
    filterable: false,
    Cell: ({ original }) => {
      const { start } = original;

      return (
        <div className="ellipsis-text">
            {format(new Date(start), 'yyyy-MM-dd HH:mm')}
        </div>
      );
    },
  },
  // status //
  {
    Header: 'Status',
    accessor: 'status',
    width: 120,
    filterable: false,
    Cell: ({ original }) => {
      const { sessionStatus  } = original;

      return (
        <div className="ellipsis-text">
          {sessionStatus}
        </div>
      );
    },
  },
  // amount //
  {
    Header: 'Price',
    accessor: 'price',
    width: 80,
    style: { textAlign: 'right' },
    filterable: false,
    Cell: ({ original }) => {
      const { amount  } = original;

      return (
        <div className="ellipsis-text">
          ${amount}
        </div>
      );
    },
  },
  // amount //
  {
    Header: 'Promo Code Applied',
    accessor: 'promoCode',
    width: 170,
    filterable: false,
    Cell: ({ original }) => {
      const { promoCode } = original;
      return (
        <div className="ellipsis-text">
          {promoCode ? promoCode.name : ''}
        </div>
      );
    },
  },
  // area //
  {
    Header: 'Session Area',
    accessor: 'sessionArea',
    width: 120,
    filterable: false,
    Cell: ({ original }) => {
      const { service  } = original;
      return (
        <div className="ellipsis-text">
          {service.type.name}
        </div>
      );
    },
  },
];

export default columns;