import React from 'react';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { Input } from 'debounce-input-decorator';

import 'react-datepicker/dist/react-datepicker.css';

const columns = [

  // id //
  {
    Header: 'Id',
    accessor: 'id',
    width: 60,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { id } = original;
      return (
        <div className="ellipsis-text" title={id || ''}>{id || ''}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // name //
  {
    Header: 'Full name',
    accessor: 'name',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { id, general: {name = '', surname = ''} = {} } = original;
      return (
        <div className="table-column-name  ellipsis-text" title={`${name} ${surname}`}>
          <NavLink to={`/coaches/${id}`}>
            {`${name} ${surname}`}
          </NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // email //
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ original }) => {
      const { general: {email} = {} } = original;

      if (email) {
        return (
          <div className="ellipsis-text">
            <a href={`mailto:${email}`} title={email} onClick={e => e.stopPropagation()}>
              {email}
            </a>
          </div>
        )
      } else return '';
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // paypal //
  {
    Header: 'PayPal',
    accessor: 'paypal',
    Cell: ({ original }) => {
      const { paypal } = original;

      if (paypal) {
        return (
          <div className="ellipsis-text">
            <a href={`mailto:${paypal}`} title={paypal} onClick={e => e.stopPropagation()}>
              {paypal}
            </a>
          </div>
        )
      } else return (
        <p className="paypal-warning">
          NULL
        </p>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },
  // lastLogin //
  {
    Header: 'Last login',
    accessor: 'lastLogin',
    width: 120,
    Cell: ({ original }) => {
      const { general: { lastLogin } = {} } = original;
      const lastLoginString = lastLogin ? lastLogin.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={lastLoginString}>
          <span>{lastLoginString}</span>
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="lastLogin-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => {
            console.log(date);
            onChange(date)
          }}
        />
      );
    }
  },

  // visibility //
  {
    Header: 'Visibility',
    accessor: 'status',
    width: 110,
    Cell: ({ original }) => {
      const { status } = original;
      return (
        <div
          className="text-capitalize"
          title={status}
        >
          {status}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <select
          onChange={
            event => {
              if (event.target.value === '') {
                return onChange(null);
              }

              return onChange(event.target.value);
            }
          }
          style={{ width: "100%", height: '38px' }}
          value={filter ? filter.value : ''}
        >
          <option value=''>All</option>
          <option value='visible'>Visible</option>
          <option value='hidden'>Hidden</option>
          <option value='paused'>Paused</option>
          <option value='off'>Off</option>
        </select>
      )
    }
  },

  // Total sessions //
  {
    Header: 'Total',
    accessor: 'total',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { totalSessions } = original;
      return (
        <div className="ellipsis-text" title={totalSessions || 0}>
          {totalSessions || 0}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // Canceled sessions //
  {
    Header: 'Canceled',
    accessor: 'canceled',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { canceledSessions } = original;
      return (
        <div className="ellipsis-text" title={canceledSessions || 0}>
          {canceledSessions || 0}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // Rescheduled sessions //
  {
    Header: 'Resched.',
    accessor: 'rescheduled',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { rescheduledSessions } = original;
      return (
        <div className="ellipsis-text" title={rescheduledSessions || 0}>
          {rescheduledSessions || 0}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // No-Shows sessions //
  {
    Header: 'No-Shows',
    accessor: 'noShows',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { noShowsSessions } = original;
      return (
        <div className="ellipsis-text" title={noShowsSessions || 0}>
          {noShowsSessions || 0}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },
  // trusted //
  {
    Header: 'Trusted',
    accessor: 'trusted',
    width: 110,
    Cell: ({ original }) => {
      const { trusted } = original;
      return (
        <div
          className="text-capitalize"
        >
          {trusted ? 'Trusted' : ''}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <select
          onChange={
            event => {
              if (event.target.value === '') {
                return onChange(null);
              }

              return onChange(event.target.value);
            }
          }
          style={{ width: "100%", height: '38px' }}
          value={filter ? filter.value : ''}
        >
          <option value=''>All</option>
          <option value='true'>Trusted</option>
        </select>
      )
    }
  },
];

export default columns;
