import React, { Component } from 'react';

import Table from '../../components/Table';
import columns from './sessionsColumns';

import formatNumber from './../../utils/formatNumber';
import getExportCoachSessionsUrl from "./api/getExportCoachSessionsUrl";

class Sessions extends Component {
  exportToCsv = (e) => {
    e.preventDefault();

    window.open(getExportCoachSessionsUrl(this.props.codeId), '_blank');
  };

  render() {
    const { sessions } = this.props;

    return (
      <div className="promo-codes-page">
        <div className="table-top">
          <p className="md-lg">
            Total records:&nbsp;<b>{formatNumber(sessions.length)}</b>
          </p>

          <a
            href='#'
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.exportToCsv}
          >
            Export as CSV
          </a>
        </div>

        <Table
          data={sessions}
          showPagination={false}
          pages={sessions.length}
          columns={[...columns]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
        />
      </div>
    )
  }
}

export default Sessions;
