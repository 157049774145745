import React from 'react';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { Input } from 'debounce-input-decorator';

import 'react-datepicker/dist/react-datepicker.css';

const columns = [

  // created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },

  // name //
  {
    Header: 'Name',
    accessor: 'name',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { id, name } = original;

      return (
        <div className="table-column-name ellipsis-text" title={name}>
          <NavLink to={`/jobs-promo-codes/${id}`}>
            {name}
          </NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // discount //
  {
    Header: 'Discount %',
    accessor: 'value',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { value = {} } = original;

      return (
        <div className="ellipsis-text">
          {value}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // limit //
  {
    Header: 'Limit',
    accessor: 'codeLimit',
    width: 50,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { codeLimit } = original;

      return (
        <div className="ellipsis-text">
          {codeLimit}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // limit per user //
  {
    Header: 'LimitPerUser',
    accessor: 'limitPerUser',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { limitPerUser } = original;

      return (
        <div className="ellipsis-text">
          {limitPerUser}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // used //
  {
    Header: 'Used',
    accessor: 'used',
    width: 50,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { used } = original;

      return (
        <div className="ellipsis-text">
          {used}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },
];

export default columns;
