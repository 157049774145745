import React from 'react';

import Table from '../../components/Table';
import columns from './logsColumns';

const Logs = ({ logs }) => (
  <div className="coaching-sessions-page">
    <Table
      data={logs}
      columns={[...columns]}
      startOrder='id DESC'
      dataPath='coach_session_logs'
      filterable={false}
      getTheadFilterThProps={(state, rowInfo, column) => {
        return { style: { overflow: 'visible' }};
      }}
    />
  </div>
);

export default Logs;
