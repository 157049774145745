import React, {Component} from 'react';
import Select from 'react-select';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {format} from "date-fns";

import Logs from './logs';

import {CopyToClipboard} from "react-copy-to-clipboard";
import {Link} from "react-router-dom";
import {Button} from 'reactstrap';
import Alerts from '../../components/Alerts';
import Spinner from '../../../components/Spinner';
import ConfirmPayModal from './confirmPayModal';
import DeleteReview from "./deleteReview";

import getSession from './api/getSession';
import changeAdminSessionStatus from './api/changeAdminSessionStatus';
import refund from './api/refund';
import rescheduleRequest from './api/rescheduleRequest';
import sessionStatusOptions from './api/sessionStatusOptions';
import adminSessionStatusOptions from './api/adminSessionStatusOptions';
import paymentStatusOptions from "./api/paymentStatusOptions";
import deleteReview from "./api/deleteReview";
import saveReview from "./api/saveReview";

import 'react-tabs/style/react-tabs.css';
import './edit.scss';

const AGORA_PROJECT_ID = 'uxqVJq_LC';
const BRAINTREE_MERCHANT = 'n6yd33mqg6x57gj8';
const PH_PERCENT = 30; // percents

class SessionDetail extends Component {
  state = {
    promoCode: null,
    start: '',
    duration: 0,
    price: 0,
    role: {},
    type: {},
    rescheduled: 0,
    created: '',

    // fields
    id: null,
    oldName: '',
    user: null,
    coach: null,
    status: '',
    sessionStatus: 'scheduled',
    adminSessionStatus: null,
    coachSessionUrl: null,
    userSessionUrl: null,

    sessionStatusObj: null,
    adminSessionStatusObj: null,

    rescheduleReason: '',
    logs: [],
    videos: [],
    payout: null,
    transaction: null,

    coachReview: null,
    coachReviewNote: '',
    coachReviewHidden: false,
    coachReviewScale1: '',
    coachReviewScale2: '',
    coachReviewScale3: '',

    userReview: null,
    userReviewNote: '',
    userReviewHidden: false,
    userReviewScale1: '',
    userReviewScale2: '',
    userReviewScale3: '',

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', alertMessage: null, alertRefundMessage: '',

    // delete
    deleteReviewModalIsOpen: false, deleteReviewModalLoading: false, deleteReviewModalId: null, deleteReviewModalType: 'user',

    // modal
    confirmModalIsOpen: false, confirmModalLoading: false, confirmModalText: '',

    // api
    loading: false,

    // applied
    tabIndex: 0,
  }

  // change fields
  onChange = e => {
    if (e.target.type === 'checkbox') {
      this.setState({[e.target.name]: e.target.checked})
    } else {
      this.setState({[e.target.name]: e.target.value})
    }
  }

  onChangeAdminSessionStatus = adminSessionStatusObj => this.setState({
    adminSessionStatusObj,
    adminSessionStatus: adminSessionStatusObj.value
  });
  onChangeRescheduleReason = rescheduleReason => this.setState({rescheduleReason});

  // close page and go back to table
  closeDetail = () => this.props.history.push('/coaching-sessions');

  closeErrorAlert = () => this.setState({errorAlertIsOpen: false});

  // edit request
  changeAdminSessionStatusSubmit = e => {
    e.preventDefault();
    this.setState({loading: true, errorAlertIsOpen: false});

    const {id, adminSessionStatus} = this.state;

    changeAdminSessionStatus(id, adminSessionStatus).then(res => {
      // open alert
      this.setState({alertIsOpen: true, alertType: 'edit'});

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({loading: false, alertIsOpen: false});

        // push data to router
        const {history} = this.props;
        history.push({
          pathname: '/coaching-sessions',
          state: {
            afterEditData: res.data
          }
        });
      }, 2000);

    }).catch(error => this.catchErrors(error));
  }

  requestRescheduleSubmit = e => {
    e.preventDefault();
    this.setState({loading: true, errorAlertIsOpen: false});

    const {id, rescheduleReason} = this.state;

    rescheduleRequest(id, rescheduleReason).then(res => {
      // open alert
      this.setState({
        alertIsOpen: true,
        alertType: 'edit',
        rescheduleReason: '',
        logs: [
          ...this.state.logs,
          res.data
        ],
      });

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({loading: false, alertIsOpen: false});
      }, 2000);

    }).catch(error => this.catchErrors(error));
  };

  catchErrors = error => {
    if (!error || !error.response || !error.response.data || !error.response.data.error) {
      this.setState({
        errorAlertIsOpen: true,
        loading: false,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: 'Internal error',
        confirmModalLoading: false,
        confirmModalIsOpen: false,
      });
    }

    const {name, statusCode, message} = error.response.data.error;

    if (statusCode === 401) {

      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');

    } else {
      this.setState({
        errorAlertIsOpen: true,
        loading: false,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  componentDidMount() {
    const {match} = this.props;

    if (match.params.id === 'new') {
      return;
    }

    this.setState({loading: true});

    // get request
    getSession(match.params.id).then(res => {
      const {data} = res;
      const {user = {}, coach = {}, service = {}} = data;
      const {general: coachUser} = coach;

      data.reviews.sort((a, b) => {
        return b.id - a.id;
      });

      const coachReview = data.reviews.find(review => review.type === 'coach') || null;
      const userReview = data.reviews.find(review => review.type === 'user') || null;

      this.setState({
        loading: false,

        // fields
        id: data.id,
        oldName: `${coachUser.name} ${coachUser.surname} & ${user.name} ${user.surname}`,
        user: user,
        coach: coach,
        coachUser: coachUser,
        status: data.status,
        sessionStatus: data.sessionStatus,

        promoCode: data.promoCode,
        start: data.start,
        duration: data.duration,
        role: service.role,
        type: service.type,
        rescheduled: data.logs.filter(log => (log.type === 'Reschedule session')).length,
        created: data.created,

        coachSessionUrl: data.coachSessionUrl,
        userSessionUrl: data.userSessionUrl,
        price: service.price || 0,
        payout: data.payout,

        coachReview,
        coachReviewNote: coachReview ? coachReview.note : '',
        coachReviewHidden: coachReview ? !coachReview.visible : false,
        coachReviewScale1: coachReview ? coachReview.ratingArray.split(',')[0] : '',
        coachReviewScale2: coachReview ? coachReview.ratingArray.split(',')[1] : '',
        coachReviewScale3: coachReview ? coachReview.ratingArray.split(',')[2] : '',
        userReview,
        userReviewNote: userReview ? userReview.note : '',
        userReviewHidden: userReview ? !userReview.visible : false,
        userReviewScale1: userReview ? userReview.ratingArray.split(',')[0] : '',
        userReviewScale2: userReview ? userReview.ratingArray.split(',')[1] : '',
        userReviewScale3: userReview ? userReview.ratingArray.split(',')[2] : '',

        logs: data.logs,
        videos: data.videos.map(video => ({
          ...video,
          createdString: format(new Date(video.created), 'MM/dd/yyyy kk:mm')
        })),
        transaction: data.transaction,
      });
    })
      .then(() => {
        // STATUS (get current sessionStatusObj {} from options mapping)
        const {sessionStatus} = this.state;
        if (sessionStatus) {
          sessionStatusOptions.forEach(i => {
            if (sessionStatus === i.value) {
              this.setState({sessionStatusObj: i});
            }
          });
        } else {
          this.setState({sessionStatusObj: null});
        }
      })
      .catch(error => this.catchErrors(error));
  }

  onCopy = e => {
    e.preventDefault();

    this.setState({alertType: 'copy', alertIsOpen: true});

    // close alert after 2 sec
    setTimeout(() => {
      this.setState({alertIsOpen: false});
    }, 2000);
  }

  getPaymentStatus = (status) => {
    const option = paymentStatusOptions.find(option => option.value === status);

    return option ? option.label : '';
  };

  openAgora = (e) => {
    e.preventDefault();

    window.open(`https://console.agora.io/analytics/call/search?projectId=${AGORA_PROJECT_ID}`, '_blank');
  };

  openBrainTree = (e) => {
    e.preventDefault();

    const {transaction} = this.state;

    window.open(`https://sandbox.braintreegateway.com/merchants/${BRAINTREE_MERCHANT}/transactions/${transaction}`, '_blank');
  };

  refundClick = (e) => {
    e.preventDefault();

    this.setState({
      confirmModalIsOpen: true,
      confirmModalText: `Are you sure you want to refund this session?`,
      alertIsOpen: false
    });
  };

  refund = () => {
    this.setState({loading: true, errorAlertIsOpen: false, confirmModalLoading: true});

    const {id} = this.state;

    refund(id).then(res => {
      if (!res.data.success) {
        // open alert
        this.setState({
          errorAlertIsOpen: true,
          loading: false,
          alertType: 'error',
          alertIsOpen: true,
          alertErrorText: 'Refund error: ' + res.data.message,
          confirmModalLoading: false,
          confirmModalIsOpen: false,
        });

        // close alert after 2 sec and redirect to table
        setTimeout(() => {
          this.setState({
            errorAlertIsOpen: false,
            loading: false,
            alertType: '',
            alertIsOpen: false,
            alertErrorText: '',
          });
        }, 2000);

        return;
      }

      // open alert
      this.setState({
        alertIsOpen: true,
        alertType: 'refund',
        alertRefundMessage: 'Refund successful',
        confirmModalLoading: false,
        confirmModalIsOpen: false,
      });

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({
          loading: false,
          alertIsOpen: false,
          alertRefundMessage: '',
        });

        // push data to router
        const {history} = this.props;
        history.push({
          pathname: '/coaching-sessions',
          state: {
            afterRefundData: {
              id,
            },
          }
        });
      }, 2000);

    }).catch(error => this.catchErrors(error));
  };

  closeConfirmModal = () => {
    this.setState({
      confirmModalIsOpen: false
    })
  };

  saveReview = (e, type) => {
    e.preventDefault();

    const review = this.state[`${type}Review`];

    this.setState({
      loading: true,
    });

    saveReview({
      type,
      sessionId: this.state.id,
      note: this.state[`${type}ReviewNote`],
      visible: !this.state[`${type}ReviewHidden`],
      ratingArray: [
        Number(this.state[`${type}ReviewScale1`] || 0),
        Number(this.state[`${type}ReviewScale2`] || 0),
        Number(this.state[`${type}ReviewScale3`] || 0)
      ],
    }).then(res => {
      this.setState({
        [`${type}Review`]: res.data,
        loading: false,
        alertType: review ? 'edit' : 'add',
        alertIsOpen: true,
        alertMessage: `${type === 'user' ? 'Talent' : 'Coach'} review was ${review ? 'edited' : 'added'} successfully`,
      });

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({
          alertIsOpen: false,
          alertMessage: null,
        });
      }, 2000);
    }).catch(error => this.catchErrors(error));
  };

  deleteReviewClick = (e, type, id) => {
    e.preventDefault();

    this.setState({
      deleteReviewModalIsOpen: true,
      deleteReviewModalId: id,
      deleteReviewModalType: type,
      alertIsOpen: false
    });
  }

  closeDeleteModal = () => {
    const { deleteReviewModalLoading } = this.state
    !deleteReviewModalLoading && this.setState({ deleteReviewModalIsOpen: false });
  }

  deleteReview = () => {
    const id = this.state.deleteReviewModalId;
    const type = this.state.deleteReviewModalType;

    this.setState({
      loading: true,
      deleteReviewModalIsOpen: false, // it may be lower in logic (now it is not needs if false)
      deleteReviewModalLoading: true,
      errorAlertIsOpen: false
    });

    deleteReview(id).then(res => {
      this.setState({
        loading: false,
        alertType: 'delete',
        alertIsOpen: true,
        alertMessage: `${type === 'user' ? 'Talent' : 'Coach'} review was deleted`,
        deleteReviewModalIsOpen: false,
        deleteReviewModalLoading: false,
      });

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({
          alertIsOpen: false,
          alertMessage: null,
        });
      }, 2000);

      if (res.data && Object.keys(res.data).length) {
        this.setState({
          [`${type}Review`]: res.data,
          [`${type}ReviewNote`]: res.data.note,
          [`${type}ReviewHidden`]: !res.data.visible,
          [`${type}ReviewScale1`]: res.data.ratingArray.split(',')[0],
          [`${type}ReviewScale2`]: res.data.ratingArray.split(',')[1],
          [`${type}ReviewScale3`]: res.data.ratingArray.split(',')[2],
        })
      } else {
        this.setState({
          [`${type}Review`]: null,
          [`${type}ReviewNote`]: '',
          [`${type}ReviewHidden`]: false,
          [`${type}ReviewScale1`]: '',
          [`${type}ReviewScale2`]: '',
          [`${type}ReviewScale3`]: '',
        });
      }
    }).catch(error => this.catchErrors(error));
  }

  render() {
    const {
      promoCode, start, duration, type, role, rescheduled, created,

      // fields
      id, oldName, user, coach, coachUser,
      price, coachSessionUrl, userSessionUrl,
      status, sessionStatus, sessionStatusObj, adminSessionStatus, adminSessionStatusObj,
      logs, videos, transaction, payout,

      coachReview,
      coachReviewNote,
      coachReviewHidden,
      coachReviewScale1,
      coachReviewScale2,
      coachReviewScale3,
      userReview,
      userReviewNote,
      userReviewHidden,
      userReviewScale1,
      userReviewScale2,
      userReviewScale3,

      rescheduleReason,

      // alerts
      alertIsOpen, alertType, alertErrorText, alertMessage, alertRefundMessage, errorAlertIsOpen,

      // modal
      confirmModalIsOpen, confirmModalLoading, confirmModalText,

      // delete
      deleteReviewModalIsOpen, deleteReviewModalLoading, deleteReviewModalType,

      // api
      loading,

      // applied
      tabIndex,
    } = this.state;

    const startString = start && `${start.substring(0, 10)}, ${start.substring(11, 16)} UTC`;
    const createdString = created && `${created.substring(0, 10)}, ${created.substring(11, 16)} UTC`;

    return (
      <section className="ph-detail-page  container  edit-coach-session">
        {
          alertIsOpen && (
            <Alerts id={id} name="Session" type={alertType} message={alertMessage} refundMessage={alertRefundMessage}
                    errorText={alertErrorText}
                    errorAlertIsOpen={errorAlertIsOpen} closeErrorAlert={this.closeErrorAlert}/>
          )
        }

        <DeleteReview
          isOpen={deleteReviewModalIsOpen}
          modalLoading={deleteReviewModalLoading}
          closeModal={this.closeDeleteModal}
          type={deleteReviewModalType}
          deleteSubmit={this.deleteReview}
        />

        <ConfirmPayModal
          isOpen={confirmModalIsOpen}
          confirmSubmit={this.refund}
          modalLoading={confirmModalLoading}
          closeModal={this.closeConfirmModal}
        >
          <span>
            {confirmModalText}
          </span>
        </ConfirmPayModal>

        <h4 className="ph-detail-page__title">Edit Session: <b>{oldName}</b></h4>
        <span className="ion-close-round ph-detail-page__close" onClick={this.closeDetail}/>

        {
          loading && <div className="ph-detail-page__is-loading"><Spinner/></div>
        }


        <Tabs
          selectedIndex={tabIndex}
          onSelect={tabIndex => this.setState({tabIndex})}
        >
          <TabList>
            <Tab>General Info</Tab>
            <Tab>Log</Tab>
          </TabList>
          <TabPanel>
            <div className="cardbox">
              <div className="cardbox-body">
                {
                  id ?
                    <fieldset>
                      <div className="form-group row top-fields">

                        {/* scheduled */}
                        <div className="col-md-2 col-sm-6">
                          <b>Scheduled</b>
                          <span>{startString || ''}</span>
                        </div>

                        {/* duration */}
                        <div className="col-md-2 col-sm-6">
                          <b>Duration</b>
                          <span>{duration || 0}</span>
                        </div>

                        {/* type */}
                        <div className="col-md-2 col-sm-6">
                          <b>Session Type</b>
                          <span>{type ? type.name : ''}</span>
                        </div>

                        {/* role */}
                        <div className="col-md-2 col-sm-6">
                          <b>Session Area</b>
                          <span>{role ? role.name : ''}</span>
                        </div>

                        {/* rescheduled */}
                        <div className="col-md-2 col-sm-6">
                          <b>Rescheduled</b>
                          <span>{rescheduled || 0}</span>
                        </div>

                        {/* created */}
                        <div className="col-md-2 col-sm-6">
                          <b>Created</b>
                          <span>{createdString || ''}</span>
                        </div>
                      </div>
                    </fieldset>
                    : null
                }

                <fieldset>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <div className="row">

                        {/* status */}
                        <div className="col-md-4">
                          <label htmlFor="edit-status">Session Status</label>

                          <p style={{marginTop: 8}}>{sessionStatusObj ? sessionStatusObj.label : ''}</p>
                        </div>

                        <div className="col-md-8">
                          <label htmlFor="edit-status">Agora Session ID</label>
                          <div className='row'>
                            <div className="col-md-7">
                              <span style={{marginRight: 20}}>{`session-${id || '0'}`}</span>
                              <CopyToClipboard text={`session-${id}`}>
                                <Button
                                  title="Copy session id to clipboard"
                                  disabled={!id} outline
                                  color="primary"
                                  onClick={this.onCopy}
                                >
                                  Copy
                                </Button>
                              </CopyToClipboard>
                            </div>
                            <div className="col-md-5">
                              <Button
                                color="primary"
                                onClick={this.openAgora}
                              >
                                Open Agora
                              </Button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row session-fields">

                        {/* status */}
                        <div className="col-md-3 col-sm-6">
                          <b>Payment Status</b>
                          <span>{this.getPaymentStatus(status) || ''}</span>
                        </div>

                        {/* Session Fee */}
                        <div className="col-md-2 col-sm-6">
                          <b>Total Fee</b>
                          <span>{price ? `$${Math.round((price / 100) * ((100 + (PH_PERCENT * (promoCode ? 1 - (promoCode.value / 100) : 1))) / 100))}` : 'Free'}</span>
                        </div>

                        {/* Coach Fee */}
                        <div className="col-md-2 col-sm-6">
                          <b>Coach Fee</b>
                          <span>{Math.round(price / 100) || 'Free'}</span>
                        </div>

                        {/* PH Fee */}
                        {
                          price ?
                            <div className="col-md-2 col-sm-6">
                              <b>PH Fee</b>
                              <span style={{fontWeight: 'bold', color: '#4fc0b5'}}>
                                  {price ? `$${Math.round((price / 100) * (PH_PERCENT * (promoCode ? 1 - (promoCode.value / 100) : 1) / 100))}` : 'Free'}
                                </span>
                            </div>
                            : null
                        }

                        {/* BrainTree */}
                        {
                          price && transaction ?
                            <div className="col-md-3 col-sm-6" style={{paddingRight: 0}}>
                              <b>&nbsp;</b>
                              <Button
                                color="primary"
                                onClick={this.openBrainTree}
                              >
                                Open BrainTree
                              </Button>
                            </div>
                            : null
                        }

                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <form action="" onSubmit={this.changeAdminSessionStatusSubmit}>
                        <div className="row">
                          {/* Admin Session Status */}
                          <div className="col-md-6">
                            <label htmlFor="edit-status">Admin Session Status</label>

                            <Select
                              value={adminSessionStatusObj}
                              onChange={this.onChangeAdminSessionStatus}
                              options={adminSessionStatusOptions}
                            />
                          </div>

                          <div className="col-md-6" style={{paddingTop: '25px'}}>
                            <Button disabled={!adminSessionStatus} color="primary" type="submit">Save</Button>
                          </div>
                        </div>
                      </form>
                    </div>

                    {
                      payout ?
                        <div className="col-md-6">
                          <div className="row session-fields">

                            {/* payout */}
                            <div className="col-md-3 col-sm-6">
                              <b>Payout Status</b>
                              <span className="text-capitalize">{payout.status.toLowerCase()}</span>
                            </div>

                            {/* Coach Fee */}
                            <div className="col-md-4 col-sm-6">
                              <b>Payout Info</b>
                              <span>
                                  <Link
                                    style={{fontWeight: 'normal'}}
                                    to={`/payout-history/${payout.id}`}
                                    title={`/payout-history/${payout.id}`}
                                    rel="noopener noreferrer"
                                    target='_blank'
                                  >
                                    Open in a new tab
                                  </Link>
                                </span>
                            </div>

                          </div>
                        </div>
                        : null
                    }
                    {/* Refund button */}
                    {
                      status === 'refund_pending' ?
                        <div className="col-md-4 col-sm-6">
                          <br/>
                          <Button
                            color="warning"
                            onClick={this.refundClick}
                          >
                            Refund
                          </Button>
                        </div>
                        : null
                    }
                    {/* promoCode */}
                    {
                      promoCode ?
                        <div className="col-md-4 col-sm-6">
                          <b>Promo Code Applied</b>&nbsp;&nbsp;&nbsp;&nbsp;
                          <Link
                            style={{fontWeight: 'normal'}}
                            to={`/promo-codes/${promoCode.id}`}
                            title={`.../promo-codes/${promoCode.id}`}
                            target='_blank'>
                            Edit code in a new tab
                          </Link>
                          <span
                            className="promo-code">{promoCode.name}, {promoCode.value}% — {promoCode.user ? `${promoCode.user.name} ${promoCode.user.surname}` : 'Sitewide'}</span>
                        </div>
                        : null
                    }
                  </div>
                  <br/>
                  <br/>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <b>Coach</b>
                      {
                        coachUser ?
                          <>
                            <div className="session-row">
                                <span className='user-name'>
                                  {coachUser.name} {coachUser.surname}, <a
                                  href={`mailto:${coachUser.email}`}>{coachUser.email || '—'}</a>
                                </span>&nbsp;
                              <CopyToClipboard text={`${coachUser.name} ${coachUser.surname} <${coachUser.email}>`}>
                                <Button
                                  title="Copy coach data to clipboard"
                                  disabled={!coachUser} outline
                                  color="primary"
                                  onClick={this.onCopy}
                                >
                                  Copy Coach
                                </Button>
                              </CopyToClipboard>
                            </div>
                            <div className="session-row">
                              <Link
                                style={{fontWeight: 'normal'}}
                                to={`/users/${coachUser.id}`}
                                title={`/users/${coachUser.id}`}
                                rel="noopener noreferrer"
                                target='_blank'>
                                Edit user in a new tab
                              </Link> | <Link
                              style={{fontWeight: 'normal'}}
                              to={`/coaches/${coach.id}`}
                              title={`/coaches/${coach.id}`}
                              rel="noopener noreferrer"
                              target='_blank'>
                              Edit coach in a new tab
                            </Link> | <a
                              style={{fontWeight: 'normal'}}
                              href={`https://producthired.com/coaches/${coach.url}`}
                              rel="noopener noreferrer"
                              target='_blank'>
                              Open Coach Profile
                            </a>
                            </div>
                            {
                              coachSessionUrl ?
                                <div className="session-fields session-row session-url">
                                  <b>Coach Session URL</b>
                                  <p><a
                                    href={coachSessionUrl}
                                    rel="noopener noreferrer"
                                    target='_blank'
                                  >
                                    {coachSessionUrl}
                                  </a></p>
                                </div>
                                : null
                            }
                          </>
                          : null
                      }
                    </div>
                    <div className="col-md-6">
                      <b>Talent</b>
                      {
                        user ?
                          <>
                            <div className="session-row">
                                <span className='user-name'>
                                  {user.name} {user.surname}, <a href={`mailto:${user.email}`}>{user.email || '—'}</a>
                                </span>&nbsp;
                              <CopyToClipboard text={`${user.name} ${user.surname} <${user.email}>`}>
                                <Button
                                  title="Copy coach data to clipboard"
                                  disabled={!user} outline
                                  color="primary"
                                  onClick={this.onCopy}
                                >
                                  Copy Talent
                                </Button>
                              </CopyToClipboard>
                            </div>
                            <div className="session-row">
                              <Link
                                style={{fontWeight: 'normal'}}
                                to={`/users/${user.id}`}
                                title={`/users/${user.id}`}
                                rel="noopener noreferrer"
                                target='_blank'>
                                Edit user in a new tab
                              </Link>
                            </div>
                            {
                              userSessionUrl ?
                                <div className="session-fields session-row session-url">
                                  <b>Talent Session URL</b>
                                  <p><a
                                    href={userSessionUrl}
                                    rel="noopener noreferrer"
                                    target='_blank'
                                  >
                                    {userSessionUrl}
                                  </a></p>
                                </div>
                                : null
                            }
                          </>
                          : null
                      }
                    </div>
                  </div>
                  {
                    false && sessionStatus === 'scheduled' ?
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="edit-reschedule-reason">
                            <b>Reschedule Request Reason</b>
                          </label>

                          <textarea
                            rows={3}
                            name="reschedule-reason"
                            id="edit-reschedule-reason"
                            value={rescheduleReason}
                            onChange={e => this.onChangeRescheduleReason(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-12">
                          <Button
                            style={{marginTop: '10px'}}
                            disabled={!rescheduleReason.length}
                            color="primary"
                            onClick={this.requestRescheduleSubmit}
                          >
                            Request Reschedule
                          </Button>
                        </div>
                      </div>
                      : null
                  }
                  <br/>
                  <br/>
                  {
                    price && videos.length ?
                      <div className="row">
                        <div className="col-md-12">
                          <b>Download Video</b>
                          <div className="session-downloads">
                            {
                              videos.map((video, index) => (
                                <Button
                                  key={index}
                                  tag='a'
                                  href={video.url}
                                  color="primary"
                                  outline
                                  download
                                  rel="noopener noreferrer"
                                >
                                  File #{index + 1}, {video.createdString}
                                </Button>
                              ))
                            }
                            {/*<Button*/}
                            {/*  tag='a'*/}
                            {/*  href='#'*/}
                            {/*  disabled={true}*/}
                            {/*  color="primary"*/}
                            {/*  outline*/}
                            {/*  download*/}
                            {/*  rel="noopener noreferrer"*/}
                            {/*>*/}
                            {/*  File #3, 10:26*/}
                            {/*</Button>*/}
                            {/*<span>*/}
                            {/*  Deleted: 2021-01-25, 10:30, GMT -9:00*/}
                            {/*</span>*/}
                          </div>
                        </div>
                      </div>
                      : null
                  }

                  {
                    id &&
                    type.slug !== 'intro-call' &&
                    !['canceled', 'scheduled', 'rescheduling_request'].includes(sessionStatus) ? (
                      <>
                        <br/>
                        <br/>

                        <div className="form-group row">
                          <div className="col-md-6">
                            <form action="" onSubmit={(e) => this.saveReview(e, 'coach')}>

                              <label htmlFor="coach-review-note">Coach said:</label>

                              <textarea
                                rows={4}
                                type="text"
                                name="coachReviewNote"
                                value={coachReviewNote}
                                id="coach-review-note"
                                onChange={this.onChange}
                                className="form-control"
                              />

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="coach-review-hidden"
                                      name="coachReviewHidden"
                                      checked={coachReviewHidden}
                                      onChange={this.onChange}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="coach-review-hidden"
                                      style={{fontWeight: 'normal'}}
                                    >
                                      Hidden review
                                    </label>
                                  </div>
                                </div>
                                {coachReview && coachReview.deleted ? (
                                  <div className="col-md-6 review-disabled">
                                    Doesn't affect the rating
                                  </div>
                                ) : null}
                              </div>

                              <br/>

                              <div className="row">
                                {/* scale 1 */}
                                <div className="col-md-2">
                                  <label htmlFor="coach-review-scale1">Scale 1</label>

                                  <input
                                    min={1}
                                    max={10}
                                    name="coachReviewScale1"
                                    type="number"
                                    value={coachReviewScale1}
                                    id="coach-review-scale1"
                                    onChange={this.onChange}
                                    className="form-control"
                                  />
                                </div>

                                {/* scale 2 */}
                                <div className="col-md-2">
                                  <label htmlFor="coach-review-scale2">Scale 2</label>

                                  <input
                                    min={1}
                                    max={10}
                                    name="coachReviewScale2"
                                    type="number"
                                    value={coachReviewScale2}
                                    id="coach-review-scale2"
                                    onChange={this.onChange}
                                    className="form-control"
                                  />
                                </div>

                                {/* scale 3 */}
                                <div className="col-md-2">
                                  <label htmlFor="coach-review-scale3">Scale 3</label>

                                  <input
                                    min={1}
                                    max={10}
                                    name="coachReviewScale3"
                                    type="number"
                                    value={coachReviewScale3}
                                    id="coach-review-scale3"
                                    onChange={this.onChange}
                                    className="form-control"
                                  />
                                </div>

                                {/* buttons */}
                                <div className="col-md-6" style={{textAlign: 'right', paddingTop: '24px'}}>
                                  {
                                    coachReview
                                      ? <>
                                        <Button
                                          color="danger"
                                          outline
                                          onClick={(e) => this.deleteReviewClick(e, 'coach', coachReview.id)}
                                        >
                                          Delete Review
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button
                                          color="primary"
                                          type="submit"
                                          disabled={
                                            !coachReviewNote.length ||
                                            (
                                              !coachReviewScale1.length ||
                                              Number(coachReviewScale1) < 1 ||
                                              Number(coachReviewScale1) > 10
                                            ) ||
                                            (
                                              !coachReviewScale2.length ||
                                              Number(coachReviewScale2) < 1 ||
                                              Number(coachReviewScale2) > 10
                                            ) ||
                                            (
                                              !coachReviewScale3.length ||
                                              Number(coachReviewScale3) < 1 ||
                                              Number(coachReviewScale3) > 10
                                            )
                                          }
                                        >
                                          Save Review
                                        </Button>
                                      </>
                                      : <Button
                                        color="primary"
                                        type="submit"
                                        disabled={
                                          !coachReviewNote.length ||
                                          (
                                            !coachReviewScale1.length ||
                                            Number(coachReviewScale1) < 1 ||
                                            Number(coachReviewScale1) > 10
                                          ) ||
                                          (
                                            !coachReviewScale2.length ||
                                            Number(coachReviewScale2) < 1 ||
                                            Number(coachReviewScale2) > 10
                                          ) ||
                                          (
                                            !coachReviewScale3.length ||
                                            Number(coachReviewScale3) < 1 ||
                                            Number(coachReviewScale3) > 10
                                          )
                                        }
                                      >
                                        Add Review
                                      </Button>
                                  }
                                </div>
                              </div>
                            </form>
                          </div>

                          <div className="col-md-6">
                            <form action="" onSubmit={(e) => this.saveReview(e, 'user')}>
                              <label htmlFor="user-review-note">Talent said:</label>

                              <textarea
                                rows={4}
                                type="text"
                                name="userReviewNote"
                                value={userReviewNote}
                                id="user-review-note"
                                onChange={this.onChange}
                                className="form-control"
                              />

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="user-review-hidden"
                                      name="userReviewHidden"
                                      checked={userReviewHidden}
                                      onChange={this.onChange}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="user-review-hidden"
                                      style={{fontWeight: 'normal'}}
                                    >
                                      Hidden review
                                    </label>
                                  </div>
                                </div>
                                {userReview && userReview.deleted ? (
                                  <div className="col-md-6 review-disabled">
                                    Doesn't affect the rating
                                  </div>
                                ) : null}
                              </div>

                              <br/>

                              <div className="row">
                                {/* scale 1 */}
                                <div className="col-md-2">
                                  <label htmlFor="user-review-scale1">Scale 1</label>

                                  <input
                                    min={1}
                                    max={10}
                                    name="userReviewScale1"
                                    type="number"
                                    value={userReviewScale1}
                                    id="user-review-scale1"
                                    onChange={this.onChange}
                                    className="form-control"
                                  />
                                </div>

                                {/* scale 2 */}
                                <div className="col-md-2">
                                  <label htmlFor="user-review-scale2">Scale 2</label>

                                  <input
                                    min={1}
                                    max={10}
                                    name="userReviewScale2"
                                    type="number"
                                    value={userReviewScale2}
                                    id="user-review-scale2"
                                    onChange={this.onChange}
                                    className="form-control"
                                  />
                                </div>

                                {/* scale 3 */}
                                <div className="col-md-2">
                                  <label htmlFor="user-review-scale3">Scale 3</label>

                                  <input
                                    min={1}
                                    max={10}
                                    name="userReviewScale3"
                                    type="number"
                                    value={userReviewScale3}
                                    id="user-review-scale3"
                                    onChange={this.onChange}
                                    className="form-control"
                                  />
                                </div>

                                {/* buttons */}
                                <div className="col-md-6" style={{textAlign: 'right', paddingTop: '24px'}}>
                                  {
                                    userReview
                                      ? <>
                                        <Button
                                          color="danger"
                                          outline
                                          onClick={(e) => this.deleteReviewClick(e, 'user', userReview.id)}
                                        >
                                          Delete Review
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button
                                          color="primary"
                                          type="submit"
                                          disabled={
                                            (userReviewNote && !userReviewNote.length) ||
                                            (
                                              !userReviewScale1.length ||
                                              Number(userReviewScale1) < 1 ||
                                              Number(userReviewScale1) > 10
                                            ) ||
                                            (
                                              !userReviewScale2.length ||
                                              Number(userReviewScale2) < 1 ||
                                              Number(userReviewScale2) > 10
                                            ) ||
                                            (
                                              !userReviewScale3.length ||
                                              Number(userReviewScale3) < 1 ||
                                              Number(userReviewScale3) > 10
                                            )
                                          }
                                        >
                                          Save Review
                                        </Button>
                                      </>
                                      : <Button
                                        color="primary"
                                        type="submit"
                                        disabled={
                                          (userReviewNote && !userReviewNote.length) ||
                                          (
                                            !userReviewScale1.length ||
                                            Number(userReviewScale1) < 1 ||
                                            Number(userReviewScale1) > 10
                                          ) ||
                                          (
                                            !userReviewScale2.length ||
                                            Number(userReviewScale2) < 1 ||
                                            Number(userReviewScale2) > 10
                                          ) ||
                                          (
                                            !userReviewScale3.length ||
                                            Number(userReviewScale3) < 1 ||
                                            Number(userReviewScale3) > 10
                                          )
                                        }
                                      >
                                        Add Review
                                      </Button>
                                  }
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    ) : null
                  }
                </fieldset>

              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <Logs
              logs={logs}
            />
          </TabPanel>
        </Tabs>

        <br/>
      </section>
    );
  }
}

export default SessionDetail;
