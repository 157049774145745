import React from 'react';

import {Link} from "react-router-dom";

import 'react-datepicker/dist/react-datepicker.css';

const columns = [

  // created //
  {
    Header: 'Date and Time',
    accessor: 'created',
    width: 140,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 19).replace('T', ' ') : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      )
    },
  },

  // initiator //
  {
    Header: 'Initiator',
    accessor: 'initiator',
    width: 200,
    Cell: ({ original }) => {
      const { coachId, coach, user, admin } = original;
      const { id, name, surname } = coach || user || admin || {};
      return (
        <div
          className="text-capitalize"
          title={`${name} ${surname}`}
        >
          {
            coach ?
              <>
                <Link
                  to={`/coaches/${coachId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${name} ${surname}`}
                </Link> (Coach)
              </>
              : null
          }
          {
            user ?
              <>
                <Link
                  to={`/users/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${name} ${surname}`}
                </Link> (Talent)
              </>
              : null
          }
          {
            admin ?
              <>
                <Link
                  to={`/users/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${name} ${surname}`}
                </Link> (Admin)
              </>
              : null
          }
        </div>
      );
    },
  },

  // event //
  {
    Header: 'Event',
    accessor: 'type',
    width: 200,
    Cell: ({ original }) => {
      const { type } = original;
      return (
        <div
          className="ellipsis-text"
          title={type}
        >
          {type}
        </div>
      );
    },
  },

  // reason //
  {
    Header: 'Comment',
    accessor: 'reason',
    Cell: ({ original }) => {
      const { reason } = original;
      return (
        <div
          className="ellipsis-text"
          title={reason}
        >
          {reason}
        </div>
      );
    },
  },

];

export default columns;
