import React from 'react';
import { NavLink } from 'react-router-dom';

import { Input } from 'debounce-input-decorator';
import DatePicker from 'react-datepicker';
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Select from "react-select";

import getCoachTypes from "./api/getCoachTypes";
import getCoachRoles from "./api/getCoachRoles";
import sessionStatusOptions from "./api/sessionStatusOptions";
import paymentStatusOptions from "./api/paymentStatusOptions";

import 'react-datepicker/dist/react-datepicker.css';
import './table.scss';

const customStyles = {
  // dropdown menu
  menu: (provided) => {
    return { ...provided, textAlign: 'left', width: 320 }
  },
  // control
  control: (provided) => {
    return { ...provided, padding: 0, border: '1px solid rgba(0,0,0,0.1)' }
  },
  // single options
  option: (provided, state) => ({ ...provided, borderBottom: '1px solid rgba(0,0,0,0.1)', minHeight: '22px' }),
};

const columns = [

  // id //
  {
    Header: 'Id',
    accessor: 'id',
    width: 60,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { id } = original;
      return (
        <div className="ellipsis-text" title={id || ''}>{id || ''}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // type //
  {
    Header: 'Session Type',
    accessor: 'type',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { id, service } = original;
      return (
        <div className="table-column-name ellipsis-text" title={service.type.name}>
          <NavLink to={`/coaching-sessions/${id}`}>
            {service.type.name}
          </NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <AsyncSelect
          className="session-type-select"
          placeholder="Select type..."
          isClearable={true}
          styles={customStyles}
          menuPlacement="auto"
          cacheOptions={true}
          defaultOptions={true}
          loadOptions={inputValue => getCoachTypes(inputValue).then(res => res.data)}
          getOptionValue={o => o.id}
          getOptionLabel={o => o.name}
          onChange={onChange}
          value={filter ? filter.value : ''}
        />
      )
    }
  },

  // talent //
  {
    Header: 'Talent',
    accessor: 'talent',
    width: 180,
    Cell: ({ original }) => {
      const { user: {name = '', surname = ''} = {} } = original;
      return (
        <div
          className="text-capitalize"
          title={`${name} ${surname}`}
        >
          {`${name} ${surname}`}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // coach //
  {
    Header: 'Coach',
    accessor: 'coach',
    width: 180,
    Cell: ({ original }) => {
      const { coach: { general: {name = '', surname = ''} } = { general: {} } } = original;
      return (
        <div
          className="text-capitalize"
          title={`${name} ${surname}`}
        >
          {`${name} ${surname}`}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // start //
  {
    Header: 'Scheduled',
    accessor: 'start',
    width: 120,
    Cell: ({ original }) => {
      const { start } = original;
      const startString = start ? start.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={startString}>
          <span>{startString}</span>
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },

  // sessionStatus //
  {
    Header: 'Session Status',
    accessor: 'sessionStatus',
    width: 160,
    Cell: ({ original }) => {
      const { sessionStatus } = original;
      const sessionStatusString = sessionStatus ? sessionStatus.replace(/_/g, ' ') : '';
      return (
        <div
          className="text-capitalize"
          title={sessionStatusString}
        >
          {sessionStatusString}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <Select
          onChange={
            option => {
              if (!option) {
                return onChange(null);
              }

              return onChange(option.value);
            }
          }
          options={sessionStatusOptions}
          className='sessions-select'
          menuPosition='fixed'
          value={filter ? sessionStatusOptions.find(option => option.value === filter.value) : sessionStatusOptions[0]}
        />
        // <select
        //   onChange={
        //     event => {
        //       if (event.target.value === '') {
        //         return onChange(null);
        //       }
        //
        //       return onChange(event.target.value);
        //     }
        //   }
        //   style={{ width: "100%", height: '38px' }}
        //   value={filter ? filter.value : ''}
        // >
        //   <option value=''>All</option>
        //   <option value='visible'>Visible</option>
        //   <option value='hidden'>Hidden</option>
        //   <option value='paused'>Paused</option>
        //   <option value='off'>Off</option>
        // </select>
      )
    }
  },

  // status //
  {
    Header: 'Payment Status',
    accessor: 'status',
    width: 120,
    Cell: ({ original }) => {
      const { status } = original;
      const paymentStatusString = status ? status.replace(/_/g, ' ') : '';
      return (
        <div
          className="text-capitalize"
          title={paymentStatusString}
        >
          {paymentStatusString}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <Select
          onChange={
            option => {
              if (!option) {
                return onChange(null);
              }

              return onChange(option.value);
            }
          }
          options={paymentStatusOptions}
          className='sessions-select'
          menuPosition='fixed'
          value={filter ? paymentStatusOptions.find(option => option.value === filter.value) : paymentStatusOptions[0]}
        />
      )
    }
  },

  // price //
  {
    Header: 'Total Fee',
    accessor: 'price',
    width: 80,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { service, promoCode } = original;
      const price = Math.round(service.price / 100 * (1 + (0.3 * (promoCode ? 1 - (promoCode.value / 100) : 1))));

      return (
        <div
          className="ellipsis-text"
          title={price ? `$${price}` : 'Free'}
        >
          {price ? `$${price}` : 'Free'}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // role //
  {
    Header: 'Session Area',
    accessor: 'role',
    width: 200,
    Cell: ({ original }) => {
      const { service } = original;
      return (
        <div
          className="text-capitalize"
          title={service.role.name}
        >
          {service.role.name}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <AsyncSelect
          className="session-role-select"
          placeholder="Select area..."
          isClearable={true}
          styles={customStyles}
          menuPlacement="auto"
          cacheOptions={true}
          defaultOptions={true}
          loadOptions={inputValue => getCoachRoles(inputValue).then(res => res.data)}
          getOptionValue={o => o.id}
          getOptionLabel={o => o.name}
          onChange={onChange}
          value={filter ? filter.value : ''}
        />
      )
    }
  },
];

export default columns;
