import React from 'react';
import DatePicker from 'react-datepicker';

import AsyncSelect from 'react-select/async';
import { Input } from 'debounce-input-decorator';

import getUsers        from './api/getUsers';

import 'react-datepicker/dist/react-datepicker.css';
import './selects.scss';
import {NavLink} from "react-router-dom";


const customStyles = {
  // dropdown menu
  menu: (provided) => {
    return { ...provided, textAlign: 'left', width: 320 }
  },
  // control
  control: (provided) => {
    return { ...provided, padding: 0, border: '1px solid rgba(0,0,0,0.1)' }
  },
  // single options
  option: (provided, state) => ({ ...provided, borderBottom: '1px solid rgba(0,0,0,0.1)', minHeight: '22px' }),
};

// const customStyles2 = {
//   // dropdown menu
//   menu: (provided) => {
//     return { ...provided, textAlign: 'left'}
//   },
//   // control
//   control: (provided) => {
//     return { ...provided, padding: 0, border: '1px solid rgba(0,0,0,0.1)' }
//   },
//   // single options
//   option: (provided, state) => ({ ...provided, borderBottom: '1px solid rgba(0,0,0,0.1)', minHeight: '22px' }),
// };

const columns = [

  // Created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },

  // User //
  {
    Header: 'User',
    accessor: 'user',
    width: 150,
    sortable: false,
    Cell: ({ original }) => {
      const { user } = original;

      if (!user) {
        return (
          <div
            title='Anonymous'
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            Anonymous
          </div>
        );
      }

      return (
        <div
          title={`${user.name} ${user.surname}, ${user.email}` || ''}
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          <NavLink to={`/users/${user.id}`}>
            {`${user.name} ${user.surname}` || ''}
          </NavLink>
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <AsyncSelect
          className="jobs-owner-select"
          placeholder="Select user..."
          isClearable={true}
          styles={customStyles}
          menuPlacement="auto"
          cacheOptions={true}
          defaultOptions={true}
          loadOptions={inputValue => getUsers(inputValue).then(res => res.data)}
          getOptionValue={o => o.id}
          getOptionLabel={o => (
            <div>
              <span>{`${o.name} ${o.surname}, `}</span>
              <span style={{ color: '#3498db', textShadow: '1px 1px 0 #fff' }}>
                {o.email}
              </span>
            </div>
          )}
          onChange={onChange}
          value={filter ? filter.value : ''}
        />
      );
    }
  },

  // ProductHired URL //
  {
    Header: 'ProductHired URL',
    accessor: 'url',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { url } = original;
      return (
        <div className="table-column-name ellipsis-text" title={url || ''}>
          {url}
        </div>
      );
    },
    Filter: ({filter, onChange}) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

];

export default columns;
