import React, { Component } from 'react';

import Table from '../../components/Table';
import columns from './columns';

import Alerts from '../../components/Alerts';

import formatNumber from './../../utils/formatNumber';

import { withHeaderTitle } from '../../../components/Header/HeaderTitle';

import getTotal from "./api/getTotal";
import getPayouts      from './api/getPayouts';
import getPayoutsCount from './api/getPayoutsCount';
import retryRequest from "./api/retryRequest";
import ConfirmPayModal from "./confirmPayModal";

class PayoutsHistory extends Component {
  UNSAFE_componentWillMount() { this.props.setHeaderTitle('Payouts History') }

  state = {
    // table
    tableState: { filtered: [], sorted: [] },
    payouts: [], payoutsCount: null, tableLoading: false, count: null, total: { amount: 0, total: 0 },

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', errorAlertIsOpen: false,

    // modal
    confirmModalIsOpen: false, confirmModalLoading: false, confirmModalText: '', payout: null,

    retryLoading: false,
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  closeErrorAlert  = () => this.setState({ errorAlertIsOpen: false });

  catchErrors = error => {
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`,
        confirmModalLoading: false,
        confirmModalIsOpen: false,
      });
    }
  }

  componentDidMount() {
    this.loadTotal();
  }

  loadTotal = () => {
    getTotal()
      .then(res => {
        this.setState({
          total: res.data,
        });
      })
      .catch(error => this.catchErrors(error));
  };

  retryClick = (e, payout) => {
    e.preventDefault();

    if (!payout || !payout.coach) {
      return;
    }

    const { general } = payout.coach;

    this.setState({
      payout,
      confirmModalIsOpen: true,
      confirmModalText: `Are you sure you want to try to pay coach [${payout.coachId}] - ${general.name} ${general.surname} again?`,
      alertIsOpen: false
    });
  };

  retry = (payout) => {
    if (this.state.retryLoading) {
      return;
    }

    this.setState({
      retryLoading: true,
      errorAlertIsOpen: false,
      confirmModalLoading: true,
    });

    retryRequest(payout.id).then(res => {
      // open alert
      this.setState({
        payouts: this.state.payouts.map((cPayout) => cPayout.id === res.data.id ? res.data : cPayout),
      });

      if (['ERROR', 'FAILED'].includes(payout.status)) {
        this.setState({
          alertType: 'error',
          alertIsOpen: true,
          alertErrorText: `${payout.status}: ${payout.reason}`,
          confirmModalLoading: false,
          confirmModalIsOpen: false,
        });
      } else {
        this.setState({
          alertIsOpen: true,
          alertType: 'paid',
          confirmModalLoading: false,
          confirmModalIsOpen: false,
        });
      }

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({retryLoading: false, alertIsOpen: false});
      }, 2000);

    }).catch(error => this.catchErrors(error));
  }

  confirmSubmit = () => {
    const { payout } = this.state;

    this.retry(payout);

    this.setState({
      payout: null,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      payout: null,
      confirmModalIsOpen: false
    })
  };

  render() {
    const {
      // table
      payouts, payoutsCount, count, tableLoading, total,

      // alerts
      alertIsOpen, alertType, alertErrorText,

      // modal
      confirmModalIsOpen, confirmModalLoading, confirmModalText,

      retryLoading,
    } = this.state;

    const controlsColumn = [
      {
        Header: '',
        width: 180,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => {
          if (!['ERROR', 'FAILED'].includes(original.status)) {
            return null;
          }

          return (
            <div className="actions">
              <a href="#" onClick={(e) => this.retryClick(e, original)}>Retry</a>
            </div>
          );
        }
      }
    ];

    return (
      <div className="coach-payouts-page">
        <div className="form-group row top-fields">
          {/* coaches */}
          <div className="col-md-2 col-sm-6">
            Total records:&nbsp;<b>{count && formatNumber(this.state.count)}</b>
          </div>

          {/* payouts total */}
          <div className="col-md-3 col-sm-6">
            Lifetime Total: ${formatNumber(total.total)}
          </div>

          {/* payouts total less fees */}
          <div className="col-md-3 col-sm-6">
            <b>Lifetime Total Less Fee: ${formatNumber(total.amount)}</b>
          </div>
        </div>

        { alertIsOpen && <Alerts type={alertType} errorText={alertErrorText} /> }

        <ConfirmPayModal
          isOpen={confirmModalIsOpen}
          confirmSubmit={this.confirmSubmit}
          modalLoading={confirmModalLoading}
          closeModal={this.closeConfirmModal}
        >
          <span>
            {confirmModalText}
          </span>
        </ConfirmPayModal>

        <Table
          data={payouts}
          manual={true}
          pages={payoutsCount}
          loading={tableLoading || retryLoading}
          columns={[...columns, ...controlsColumn]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
          onFetchData={state => {
            this.setState({ tableState: state, tableLoading: true });

            // count request
            getPayoutsCount(state)
              .then(res => {
                this.setState({
                  count: res.data.count,
                  payoutsCount: Math.ceil(res.data.count / state.pageSize)
                })

                // data request
                getPayouts(state)
                  .then(res => this.setState({ payouts: res.data, tableLoading: false }))
                  .catch(error => this.catchErrors(error));
              }).catch(error => this.catchErrors(error));
          }}
        />
      </div>
    )
  }
}

export default withHeaderTitle(PayoutsHistory);
