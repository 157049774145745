import React from 'react';
import { NavLink } from 'react-router-dom';

import { Input } from 'debounce-input-decorator';

import './table.scss';

const coachesColumns = (tabIndex) => ([

  // coach //
  {
    Header: 'Coach',
    accessor: 'coach',
    Cell: ({ original }) => {
      const { coach: {id, name = '', surname = ''} = {} } = original;
      return (
        <div className="table-column-name ellipsis-text" title={`${name} ${surname}`}>
          <NavLink to={`/coaches/${id}`}>
            {`${name} ${surname}`}
          </NavLink>
        </div>
      );
    },
    filterMethod: ({value}, { coach: {name, surname} = {} }) => {
      return (
        name.toLowerCase().includes(value.toLowerCase()) ||
        surname.toLowerCase().includes(value.toLowerCase())
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // paypal //
  {
    Header: 'PayPal Account',
    accessor: 'paypal',
    width: 280,
    Cell: ({ original }) => {
      const { paypal } = original;
      return (
        <div
          title={paypal || '-'}
        >
          {paypal || '-'}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // sessions //
  {
    Header: 'Sessions',
    accessor: 'coachSessions',
    width: 120,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { coachSessions, coachId } = original;
      return (
        <div
          title={coachSessions.length}
        >
          {coachSessions.length} <NavLink to={`/payouts/${coachId}${tabIndex ? '?tab=' + tabIndex : ''}`}>(view all)</NavLink>
        </div>
      );
    },
    filterMethod: ({value}, { coachSessions }) => {
      return !value || !value.length || coachSessions.length === parseInt(value);
    },
    Filter: ({ filter, onChange }) => (
      <Input
        type='number'
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // total //
  {
    Header: 'Amount',
    accessor: 'total',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { total } = original;
      return (
        <div
          className="ellipsis-text"
          title={Math.round(total * 100) / 100 ? `$${Math.round(total * 100) / 100}` : 'Free'}
        >
          {Math.round(total * 100) / 100 ? `$${Math.round(total * 100) / 100}` : 'Free'}
        </div>
      );
    },
    filterMethod: ({value}, { total }) => {
      return !value || !value.length || (Math.round(total * 100) / 100).toString().includes(value);
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // amount //
  {
    Header: 'Less Fees',
    accessor: 'amount',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { amount } = original;
      return (
        <div
          className="ellipsis-text"
          title={Math.round(amount * 100) / 100 ? `$${Math.round(amount * 100) / 100}` : 'Free'}
        >
          {Math.round(amount * 100) / 100 ? `$${Math.round(amount * 100) / 100}` : 'Free'}
        </div>
      );
    },
    filterMethod: ({value}, { amount }) => {
      return !value || !value.length || (Math.round(amount * 100) / 100).toString().includes(value);
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },
]);

export default coachesColumns;
