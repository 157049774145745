import React, { Component } from 'react';

import Table from '../../components/Table';
import columns from './postHostroryColumns';

class PostHistory extends Component {
  exportToCsv = (e) => {
    e.preventDefault();
  };
  render() {
    const { payments } = this.props;
    return (
      <div className="promo-codes-page">
        <div className="table-top">
          <p className="md-lg">
            Revenue total:&nbsp;<b>${payments.length ? payments.reduce((n, {status, amount}) => status === 'succeeded' ? n + amount : n, 0) : 0}</b>
          </p>
        </div>

        <Table
          data={payments}
          //showPagination={false}
          //pages={payments.length}
          columns={[...columns]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
        />
      </div>
    )
  }
}

export default PostHistory;
