import React, { Component } from 'react';

import Table from '../../components/Table';
import columns from './sessionsColumns';

import Alerts from '../../components/Alerts';

import formatNumber from './../../utils/formatNumber';

import getSessions      from './api/getSessions';
import getSessionsCount from './api/getSessionsCount';

class Sessions extends Component {
  state = {
    // table
    sessions: [], sessionsCount: null, tableLoading: false, count: null,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', errorAlertIsOpen: false,
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  closeErrorAlert  = () => this.setState({ errorAlertIsOpen: false });

  catchErrors = error => {
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  render() {
    const { coachId } = this.props;
    const {
      // table
      sessions, sessionsCount, count, tableLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText,
    } = this.state;

    return (
      <div className="coaches-page">
        <div className="table-top">
          <p className="md-lg">
            Total records:&nbsp;<b>{count && formatNumber(this.state.count)}</b>
          </p>
        </div>

        { alertIsOpen && <Alerts type={alertType} errorText={alertErrorText} /> }

        <Table
          data={sessions}
          manual={true}
          pages={sessionsCount}
          loading={tableLoading}
          columns={[...columns]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
          onFetchData={state => {
            this.setState({ tableLoading: true });

            // count request
            getSessionsCount(coachId, state)
              .then(res => {
                this.setState({
                  count: res.data.count,
                  sessionsCount: Math.ceil(res.data.count / state.pageSize)
                })

                // data request
                getSessions(coachId, state)
                  .then(res => this.setState({ sessions: res.data, tableLoading: false }))
                  .catch(error => this.catchErrors(error));
              }).catch(error => this.catchErrors(error));
          }}
        />
      </div>
    )
  }
}

export default Sessions;
