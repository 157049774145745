import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const addPromoCode = state => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const {
    name,
    user,
    system,
    discount,
    limit,
    start,
    end,
    status,
    services,
  } = state;

  return axios.post(
    `${API_URL}/${subUrl}/promo_codes`,

    {
      name,
      userId: !system ? user.id : null,
      system,
      value: discount,
      codeLimit: limit,
      start,
      end,
      status,
      services,
    },

    {
      headers: { Authorization: token },
      params: {
        filter: {
          include: [
            'user'
          ]
        }
      }
    }
  );
}

export default addPromoCode;
