import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getCampaigns = state => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const { pageSize, page, filtered, sorted } = state; // from own state of react-table

  // filter-template
  const filter = {
    where: {},
    limit: pageSize,
    skip: page * pageSize,
    order: 'id DESC'
  };

  // inject where to filter
  filtered.forEach(i => {
    // Id // +
    if (i.id === 'id') {
      filter.where[i.id] = i.value;

      // Job // +
    } else if (i.id === 'title') {
      filter.where[i.id] = { 'like': '%' + i.value + '%' };

      // Owner // +
    } else if (i.id === 'owner' && i.value) {
      filter.where['owner_id'] = i.value.id;

      // Locations // +
    } else if (i.id === 'locations') {
      if (i.value) {
        filter.where.locations = { 'inq': i.value.map(i => i.id) };
      } else {
        filter.where.locations = {};
      }

      // Skills // +
    } else if (i.id === 'skills') {
      if (i.value) {
        filter.where.skills = { 'inq': i.value.map(i => i.id) };
      } else {
        filter.where.skills = {};
      }

      // Roles // +
    } else if (i.id === 'roles') {
      if (i.value) {
        filter.where.roles = { 'inq': i.value.map(i => i.id) };
      } else {
        filter.where.roles = {};
      }

      // Pages // +
    } else if (i.id === 'pages') {
      if (i.value) {
        filter.where.pages = { 'inq': i.value.map(i => i.id) };
      } else {
        filter.where.pages = {};
      }

      // Companies // +
    } else if (i.id === 'companies') {
      if (i.value) {
        // where.companies = [i.value.id];
        filter.where.companies = { 'inq': i.value.map(i => i.id) };
      } else {
        filter.where.companies = {};
      }

      // Status // +
    } else if (i.id === 'status') {
      filter.where[i.id] = i.value;

      // Created //
    } else if (i.id === 'created' && i.value) {
      const createdDate = i.value && format(i.value, 'yyyy-MM-dd');
      filter.where.created = { 'gt': createdDate };
    }
  });


  // inject order to filter
  sorted.forEach(i => {
    const desc = i.desc ? 'DESC' : 'ASC'
      filter.order = `${i.id} ${desc}`;
  });

  // get-request for data
  return axios.get(`${API_URL}/${subUrl}/campaigns`, {
    params: { filter },
    headers: { Authorization: token }
  })
    .then(res => {
      const {data} = res;
      return Promise.all(
        data.map(campaign => axios.get(`${API_URL}/${subUrl}/campaign_logs/searchExtra`, {
          params: {
            filter: {
              where: {
                campaign_id: campaign.id
              }
            },
            count: true
          },
          headers: { Authorization: token }
        }))
      )
        .then(countLogs => ({
          data: countLogs.map(({data:{count}}, index) => ({
            ...data[index],
            clicks: count,
          }))
        }));
    });
};

export default getCampaigns;
