import React from 'react';
import { NavLink } from 'react-router-dom';

import { Input } from 'debounce-input-decorator';
import DatePicker from 'react-datepicker';
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Select from "react-select";


import 'react-datepicker/dist/react-datepicker.css';
import './table.scss';

const customStyles = {
  // dropdown menu
  menu: (provided) => {
    return { ...provided, textAlign: 'left', width: 320 }
  },
  // control
  control: (provided) => {
    return { ...provided, padding: 0, border: '1px solid rgba(0,0,0,0.1)' }
  },
  // single options
  option: (provided, state) => ({ ...provided, borderBottom: '1px solid rgba(0,0,0,0.1)', minHeight: '22px' }),
};

const columns = [

  // created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },

  // job id //
  {
    Header: 'Job ID',
    accessor: 'vacancy_id',
    width: 60,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { vacancy_id } = original;
      return (
        <div className="ellipsis-text" title={vacancy_id || ''}>{vacancy_id || ''}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // job post //
  {
    Header: 'Job post',
    accessor: 'job',
    width: 160,
    sortable: false,
    Cell: ({ original }) => {
      const { job, vacancy_id } = original;
      return (
        <NavLink target='_blank' to={`/jobs/${vacancy_id}`}><div className="ellipsis-text" title={job || ''}>{job || ''}</div></NavLink>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // user //
  {
    Header: 'User',
    accessor: 'user',
    width: 180,
    sortable: false,
    Cell: ({ original }) => {
      const { user: {id = '', name = '', surname = ''} = {} } = original;
      return (
        <div
          className="text-capitalize"
          title={`${name} ${surname}`}
        >
          <NavLink target='_blank' to={`/users/${id}`}>{`${name} ${surname}`}</NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // email //
  {
    Header: 'Email',
    accessor: 'email',
    width: 160,
    sortable: false,
    Cell: ({ original }) => {
      const { user: {email = ''} = {} } = original;
      return (
        <div
          title={`${email}`}
        >
          {`${email}`}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // plan //
  {
    Header: 'Plan',
    accessor: 'plan',
    width: 80,
    sortable: false,
    Cell: ({ original }) => {
      const { plan } = original;
      return (
        <div
          title={`${plan}`}
        >
          {`${plan}`}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <select
          onChange={
            event => {
               return onChange(event.target.value);
            }
          }
          style={{ width: '100%', height: '38px' }}
          value={filter ? filter.value : ''}
        >
          <option value=''>All</option>
          <option value='all_paid'>All Paid</option>
          <option value={null}>Null</option>
          <option value={1}>Free</option>
          <option value={2}>Bronze</option>
          <option value={3}>Silver</option>
          <option value={4}>Gold</option>
        </select>
    ),
  },

  // amount //
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 80,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { amount } = original;
      
      return (
        <div
          className="ellipsis-text"
        >
          ${amount}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },
  //status//
  {
    Header: 'Status',
    accessor: 'status',
    width: 180,
    sortable: false,
    Cell: ({ original }) => {
      const { status } = original;
      
      return (
        <div
          className="ellipsis-text"
        >
          {status ? status : 'null'}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <select
          onChange={
            event => {
              return onChange(event.target.value);
            }
          }
          style={{ width: "100%", height: '38px' }}
          value={filter ? filter.value : ''}
        >
          <option value=''>All</option>
          <option value='null'>null</option>
          <option value='succeeded'>Succeeded</option>
        </select>
    ),
  },
  // promo //
  {
    Header: 'Promo Code Applied',
    accessor: 'promoCode',
    width: 180,
    sortable: false,
    Cell: ({ original }) => {
      const { code, promoCodeId } = original;
      
      return (
        <div
          className="ellipsis-text"
        >
          <NavLink target='_blank' to={`/jobs-promo-codes/${promoCodeId}`}>{code ? code : ''}</NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },
];

export default columns;
