import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const changeAdminSessionStatus = (id, sessionStatus) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.post(
    `${API_URL}/${subUrl}/coach_sessions/${id}/saveByAdmin`,

    {
      sessionStatus,
    },

    {
      headers: { Authorization: token }
    }
  );
}

export default changeAdminSessionStatus;
