import React, {Children, Component, cloneElement} from "react";
import {Tooltip as SourceTooltip} from "reactstrap";

class Tooltip extends Component {
  state = {
    _id:
      "id4tooltip_" +
      new Date().getUTCMilliseconds() +
      (Math.floor(Math.random() * 10) + 1),
    tooltipOpen: false
  };

  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  render() {
    const {content, children, ...props} = this.props;
    const {_id, tooltipOpen} = this.state;

    return [
      <SourceTooltip
        {...props}
        isOpen={tooltipOpen}
        toggle={this.toggle}
        target={_id}
        key="1"
      >
        {content}
      </SourceTooltip>,
      cloneElement(Children.only(children), {
        id: _id,
        key: "2"
      })
    ];
  }
}

export default Tooltip;
