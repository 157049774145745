import React from 'react';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import isEmpty from 'lodash/isEmpty';

import { Input } from 'debounce-input-decorator';

import 'react-datepicker/dist/react-datepicker.css';

const columns = [

  // id //
  {
    Header: 'Id',
    accessor: 'id',
    width: 60,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { id } = original;
      return (
        <div className="ellipsis-text" title={id || ''}>{id || ''}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // name //
  {
    Header: 'Full name',
    accessor: 'name',
    width: 170,
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { id } = original;
      const name    = original.name || '';
      const surname = original.surname || '';
      return (
        <div className="table-column-name  ellipsis-text" title={`${name} ${surname}`}>
          <NavLink to={`/users/${id}`}>
            {`${name} ${surname}`}
          </NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // email //
  {
    Header: 'Email',
    accessor: 'email',
    width: 170,
    Cell: ({ original }) => {
      const { email } = original;
      if (email) {
        return (
          <div className="ellipsis-text">
            <a href={`mailto:${original.email}`} title={email} onClick={e => e.stopPropagation()}>
              {email}
            </a>
          </div>
        )
      } else return '';
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // role //
  {
    Header: 'Role',
    accessor: 'user_role_id',
    width: 100,
    Cell: ({ original }) => {
      const { user_role_id } = original;
      const userRole = user_role_id ? (
        user_role_id === 1 ? 'Talent'    :
        user_role_id === 2 ? 'Both'      :
        user_role_id === 3 ? 'Recruiter' :
        user_role_id === 4 ? 'Wanting to coach' :
        user_role_id === 5 ? 'Looking for coach' :
        'Null'
      ) : 'Null';

      return (
        <div className="ellipsis-text" title={userRole}>
          {userRole}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <select
          onChange={
            event => {
              if (event.target.value === 'Null') {
                return onChange(null);
              } else return onChange(event.target.value);
            }
          }
          style={{ width: "100%", height: '38px' }}
          value={filter ? filter.value : ''}
        >
          <option value=''>All</option>
          <option value={null}>Null</option>
          <option value={1}>Talent</option>
          <option value={2}>Both</option>
          <option value={3}>Recruiter</option>
          <option value={4}>Wanting to coach</option>
          <option value={5}>Looking for coach</option>
        </select>
      )
    }
  },

  {
    Header: 'Rev. Total',
    accessor: 'total',
    width: 100,
    filterable: false,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { total } = original;
      return (
        <div>
          <strong>${total}</strong>
        </div>
      )
    }
  },
  {
    Header: 'Job Posting',
    columns: [
      {
        Header: 'Paid',
        accessor: 'jpCountPaid',
        width: 100,
        filterable: false,
        style: { textAlign: 'right' },
        Cell: ({ original }) => {
          const { jpCountPaid } = original;
          return (
            <div>
              <span style={{ color: '#008a00' }}>{jpCountPaid}</span>
            </div>
          )
        }
      },
      {
        Header: 'Free',
        width: 100,
        accessor: 'jpCountFree',
        filterable: false,
        style: { textAlign: 'right' },
        Cell: ({ original }) => {
          const {jpCountFree} = original;
          return (
            <div>
              <span style={{ color: '#008a00' }}>{jpCountFree}</span>
            </div>
          )
        }
      },
      {
        Header: 'Rev.',
        width: 100,
        accessor: 'jpSum',
        filterable: false,
        style: { textAlign: 'right' },
        Cell: ({ original }) => {
          const { jpRev } = original;
          return (
            <div>
              <span style={{ color: '#008a00' }}>${jpRev}</span>
            </div>
          )
        }
      },
    ],
  },
  {
    Header: 'Coaching Sessions',
    columns: [
      {
        Header: 'Paid',
        accessor: 'csCountPaid',
        filterable: false,
        width: 100,
        style: { textAlign: 'right' },
        Cell: ({ original }) => {
          const { csCountPaid } = original;
          return (
            <div>
              <span style={{ color: '#007bff' }}>{csCountPaid}</span>
            </div>
          )
        }
      },
      {
        Header: 'Free',
        accessor: 'csCountFree',
        width: 100,
        filterable: false,
        style: { textAlign: 'right' },
        Cell: ({ original }) => {
          const { csCountFree } = original;
          return (
            <div>
              <span style={{ color: '#007bff' }}>{csCountFree}</span>
            </div>
          )
        }
      },
      {
        Header: 'Rev.',
        accessor: 'csSum',
        width: 100,
        filterable: false,
        style: { textAlign: 'right' },
        Cell: ({ original }) => {
          const { csRev } = original;
          return (
            <div>
              <span style={{ color: '#007bff' }}>${csRev}</span>
            </div>
          )
        }
      },
    ],
  },
  // created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },
  // lastLogin //
  {
    Header: 'Last login',
    accessor: 'lastLogin',
    width: 120,
    Cell: ({ original }) => {
      const { lastLogin } = original;
      const lastLoginString = lastLogin ? lastLogin.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={lastLoginString}>
          <span>{lastLoginString}</span>
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="lastLogin-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => {
            console.log(date);
            onChange(date)
          }}
        />
      );
    }
  },

  // access //
  {
    Header: 'Access',
    accessor: 'roles',
    width: 110,
    sortable: false,
    Cell: ({ original }) => {
      const { roles } = original;
      const rolesArray = roles ? roles.map(i => i.name) : [];

      if (!isEmpty(rolesArray)) {
        return <span className="roles-string">{rolesArray.join(', ')}</span>;
      } else return null;
    },
    Filter: ({ filter, onChange }) => {
      return (
        <select
          onChange={
            event => {
              if (event.target.value === 'Null') {
                return onChange(null);
              } else return onChange(event.target.value);
            }
          }
          style={{ width: "100%", height: '38px' }}
          value={filter ? filter.value : ''}
        >
          <option value=''>All</option>
          <option value={4}>Admin</option>
          <option value={5}>Banned</option>
        </select>
      )
    }
  },

  // status //
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   width: 100,
  //   Cell: ({ original }) => {
  //     const { status } = original;
  //     return (
  //       <div
  //         className="text-ellipsis"
  //         style={{ color: status ? 'rgb(0,203,131)' : '#dc3545' }}
  //         title={status ? 'Active' : 'Blocked'}
  //       >
  //         {status ? 'Active' : 'Blocked'}
  //       </div>
  //     )
  //   },
  //   Filter: ({ filter, onChange }) => {
  //     return (
  //       <select
  //         onChange={event => onChange(event.target.value)}
  //         style={{ width: "100%", height: '38px' }}
  //         value={filter ? filter.value : ''}
  //       >
  //         <option value=''>All</option>
  //         <option value={true}>Active</option>
  //         <option value={false}>Blocked</option>
  //       </select>
  //     )
  //   }
  // },
];

export default columns;
