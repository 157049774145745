const weekdaysOptions = [
  {
    label: 'Sunday',
    name: 'sun'
  },
  {
    label: 'Monday',
    name: 'mon'
  },
  {
    label: 'Tuesday',
    name: 'tue'
  },
  {
    label: 'Wednesday',
    name: 'wed'
  },
  {
    label: 'Thursday',
    name: 'thu'
  },
  {
    label: 'Friday',
    name: 'fri'
  },
  {
    label: 'Saturday',
    name: 'sat'
  },
];

export default weekdaysOptions;
