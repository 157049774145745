import React from 'react';
import DatePicker from 'react-datepicker';

import { NavLink } from 'react-router-dom';

import AsyncSelect from 'react-select/async';
import { Input } from 'debounce-input-decorator';

import getUsers        from './api/getUsers';
// import getCompanies    from './api/getCompanies';
// import getLocations    from './api/getLocations';
// import planOptions     from './api/planOptions';

// import customFiltering from './../../components/Table/customFiltering';

import 'react-datepicker/dist/react-datepicker.css';
import './selects.scss';


const customStyles = {
  // dropdown menu
  menu: (provided) => {
    return { ...provided, textAlign: 'left', width: 320 }
  },
  // control
  control: (provided) => {
    return { ...provided, padding: 0, border: '1px solid rgba(0,0,0,0.1)' }
  },
  // single options
  option: (provided, state) => ({ ...provided, borderBottom: '1px solid rgba(0,0,0,0.1)', minHeight: '22px' }),
};

// const customStyles2 = {
//   // dropdown menu
//   menu: (provided) => {
//     return { ...provided, textAlign: 'left'}
//   },
//   // control
//   control: (provided) => {
//     return { ...provided, padding: 0, border: '1px solid rgba(0,0,0,0.1)' }
//   },
//   // single options
//   option: (provided, state) => ({ ...provided, borderBottom: '1px solid rgba(0,0,0,0.1)', minHeight: '22px' }),
// };

const columns = [

  // Id //
  {
    Header: 'Id',
    accessor: 'id',
    width: 65,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { id } = original;
      return (
        <div className="ellipsis-text" title={id || ''}>{id || ''}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // Campaign title //
  {
    Header: 'Campaign title',
    accessor: 'title',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { id, title } = original;
      return (
        <div className="table-column-name  ellipsis-text" title={title || ''}>
          <NavLink to={`/campaigns/${id}`}>
            {title || ''}
          </NavLink>
        </div>
      );
    },
    Filter: ({filter, onChange}) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // Owner //
  {
    Header: 'Owner',
    accessor: 'owner',
    width: 150,
    sortable: false,
    Cell: ({ original }) => {
      const { owner } = original;
      return (
        <div
          title={`${owner.name} ${owner.surname}, ${owner.email}` || ''}
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {`${owner.name} ${owner.surname}` || ''}
        </div>
      )
    },
    Filter: ({ filter, onChange }) => {
      return (
        <AsyncSelect
          className="jobs-owner-select"
          placeholder="Select owner..."
          isClearable={true}
          styles={customStyles}
          menuPlacement="auto"
          cacheOptions={true}
          defaultOptions={true}
          loadOptions={inputValue => getUsers(inputValue).then(res => res.data)}
          getOptionValue={o => o.id}
          getOptionLabel={o => (
            <div>
              <span>{`${o.name} ${o.surname}, `}</span>
              <span style={{ color: '#3498db', textShadow: '1px 1px 0 #fff' }}>
                {o.email}
              </span>
            </div>
          )}
          onChange={onChange}
          value={filter ? filter.value : ''}
        />
      );
    }
  },

  // Created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },

  // Impressions //
  {
    Header: 'Impressions',
    accessor: 'impressions',
    width: 100,
    style: { textAlign: 'right' },
    filterable: false, // if filer will work comment this string
    Cell: ({ original }) => {
      const { impressions } = original;
      return (
        <div className="ellipsis-text" title={impressions || 0}>{impressions || 0}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // Clicks //
  {
    Header: 'Clicks',
    accessor: 'clicks',
    width: 65,
    style: { textAlign: 'right' },
    filterable: false, // if filer will work comment this string
    Cell: ({ original }) => {
      const { clicks } = original;
      return (
        <div className="ellipsis-text" title={clicks || 0}>{clicks || 0}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // Status //
  {
    Header: 'Status',
    accessor: 'status',
    width: 85,
    Cell: ({ original }) => {
      const { status } = original;
      return (
        <div className="ellipsis-text" title={status || ''}>{status || ''}</div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%', height: '38px' }}
          value={filter ? filter.value : ''}
        >
          <option value=''>All</option>
          <option value='live'>Live</option>
          <option value='paused'>Paused</option>
        </select>
      )
    }
  },

  // Weight //
  {
    Header: 'Weight',
    accessor: 'weight',
    width: 65,
    style: { textAlign: 'right' },
    filterable: false, // if filer will work comment this string
    Cell: ({ original }) => {
      const { weight } = original;
      return (
        <div className="ellipsis-text" title={weight || 0}>{weight || 0}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', minHeight: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

];

export default columns;
