import React, {Component} from 'react';
import cx from 'classnames';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import DeletePromoCode from './delete';
import Sessions from './sessions';

import {Button} from 'reactstrap';
import Alerts from '../../components/Alerts';
import Spinner from '../../../components/Spinner';

// import formatNumber from "../../utils/formatNumber";
import {getQueryParam} from "../../utils/queryString";

import getPromoCode from './api/getPromoCode';
import addPromoCode from "./api/addPromoCode";
import editPromoCode from './api/editPromoCode';
import deletePromoCode from './api/deletePromoCode';
import getCoaches from './api/getCoaches';
import getCoachTypes from "./api/getCoachTypes";
import checkPromoCodeUniqueName from "./api/checkPromoCodeUniqueName";
import statusOptions from './api/statusOptions';

import './edit.scss';

class PromoCodeDetail extends Component {
  state = {
    created: '',
    sessions: [],

    // fields
    id: null,
    name: '',
    oldName: '',
    user: null,
    system: false,
    discount: 0,
    totalDiscount: 0,
    limit: 1,
    start: null,
    end: null,
    status: 'live',

    services: [],

    statusObj: statusOptions.find(status => status.value === 'live') || null,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '',

    // api
    loading: false,
    checkingName: false,
    existsName: false,

    // delete
    deleteModalIsOpen: false, deleteModalLoading: false,

    coachTypes: [],

    // applied
    tabIndex: 0,
  }

  checkName = (system, name) => {
    if (!name || !name.length) {
      return;
    }

    if (name === this.state.oldName) {
      this.setState({
        checkingName: false,
        existsName: false,
      });

      return;
    }

    this.setState({
      checkingName: true,
      existsName: false,
    });

    checkPromoCodeUniqueName(system, name)
      .then(existsName => {
        this.setState({
          checkingName: false,
          existsName,
        });
      })
      .catch(() => {
        this.setState({
          checkingName: false,
          existsName: true,
        });
      });
  };

  // change fields
  onChange = e => {
    if (e.target.type === 'checkbox') {
      this.setState({[e.target.name]: e.target.checked})
    } else {
      this.setState({[e.target.name]: e.target.value})
    }
  }

  onChangeService = (service, checked) => {
    const services = this.state.services.slice();

    if (checked) {
      services.push(service);
    } else {
      const index = services.findIndex(cService => service.slug === cService.slug);

      if (index > -1) {
        services.splice(index, 1);
      }
    }

    this.setState({
      services,
    });
  };
  onChangeUser = user => this.setState({user});
  onChangeDiscount = (value, type) => {
    const cValue = Math.min(Number(value), type === 'discount' ? 100 : 23);

    this.setState({
      discount: type === 'discount' ? Math.round(cValue) : Math.round(cValue / 0.23),
      totalDiscount: type === 'totalDiscount' ? Math.round(cValue) : Math.round(cValue * 0.23),
    });
  };
  onChangeSystem = e => {
    const system = e.target.checked;

    this.setState({system});

    this.checkName(system, this.state.name);
  };
  onChangeName = e => {
    const name = e.target.value.toUpperCase();

    this.setState({name});

    this.checkName(this.state.system, name);
  }
  onChangeStatus = statusObj => this.setState({statusObj, status: statusObj.value});

  // close page and go back to table
  closeDetail = () => this.props.history.push('/promo-codes');

  closeDeleteModal = () => {
    const {deleteModalLoading} = this.state
    !deleteModalLoading && this.setState({deleteModalIsOpen: false});
  }

  closeErrorAlert = () => this.setState({errorAlertIsOpen: false});

  // add request
  addSubmit = e => {
    e.preventDefault();

    this.setState({loading: true, errorAlertIsOpen: false});

    const {state} = this;

    addPromoCode(state)
      .then(res => {
        // open alert
        this.setState({alertIsOpen: true, alertType: 'add'});

        // close alert after 2 sec and redirect to table
        setTimeout(() => {
          this.setState({loading: false, alertIsOpen: false});

          res.data.user = !state.system ? state.user : undefined;
          res.data.used = 0;

          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/promo-codes',
            state: {
              afterAddData: res.data
            }
          })
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  };

  // edit request
  editSubmit = e => {
    e.preventDefault();
    this.setState({loading: true, errorAlertIsOpen: false});

    const {state} = this;

    editPromoCode(state)
      .then((res) => {
        // open alert
        this.setState({alertIsOpen: true, alertType: 'edit'});

        // close alert after 2 sec and redirect to table
        setTimeout(() => {
          this.setState({loading: false, alertIsOpen: false});

          res.data.user = !state.system ? state.user : undefined;
          res.data.used = state.sessions.length;

          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/promo-codes',
            state: {
              afterEditData: res.data
            }
          })
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }

  deleteClick = () => {
    this.setState({deleteModalIsOpen: true, alertIsOpen: false});
  }

  deleteSubmit = () => {
    const {id} = this.state;

    this.setState({
      loading: true,
      deleteModalIsOpen: false, // it may be lower in logic (now it is not needs if false)
      deleteModalLoading: true,
      errorAlertIsOpen: false
    });

    deletePromoCode(id)
      .then(() => {
        this.setState({
          deleteModalIsOpen: false,
          deleteModalLoading: false,
          alertType: 'delete', alertIsOpen: true
        });

        setTimeout(() => {
          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/promo-codes',
            state: {deletedId: id}
          });

          this.setState({alertIsOpen: false});
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }

  selectAll = (e) => {
    e.preventDefault();

    this.setState({
      services: this.state.coachTypes.slice(),
    });
  };

  deselectAll = (e) => {
    e.preventDefault();

    this.setState({
      services: [],
    });
  };

  catchErrors = error => {
    const {name, statusCode, message} = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');

    } else {
      this.setState({
        errorAlertIsOpen: true,
        loading: false,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  copyClick = () => {
    this.props.history.push({
      pathname: `/promo-codes/new?copyId=${this.state.id}`,
    });
  }

  componentDidMount() {
    this.loadPromoCode(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const oldPromoCodeId = this.props.match.params.id ? this.props.match.params.id.split('?')[0] : null;
    const promoCodeId = nextProps.match.params.id ? nextProps.match.params.id.split('?')[0] : null;
    const copyIdInParams = nextProps.match.params.id ? (nextProps.match.params.id.split('?')[1] || null) : null;

    if (oldPromoCodeId === promoCodeId) {
      return;
    }

    if (copyIdInParams) {
      nextProps.match.params.id = promoCodeId;
      nextProps.location.search = `?${copyIdInParams}`;
    }

    this.setState({
      tabIndex: 0,
    })

    this.loadPromoCode(nextProps);
  }

  loadPromoCode(props) {
    this.setState({loading: true});

    // get request
    this.getCode(props)
      .then(() => {
        const promises = [];

        // TYPES
        promises.push(
          getCoachTypes().then(res => { // get request
            this.setState({
              coachTypes: res.data.map(type => ({
                slug: type.slug,
                name: type.name,
              })),
            });
          })
        );

        // STATUS (get current statusObj {} from options mapping)
        const {status} = this.state;
        if (status) {
          statusOptions.forEach(i => {
            if (status === i.value) {
              this.setState({statusObj: i});
            }
          });
        } else {
          this.setState({statusObj: {}});
        }

        return Promise.all(promises);
      })
      .catch(error => this.catchErrors(error))
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getCode = (props) => {
    const {match, location} = props;

    const copyId = match.params.id === 'new' ? getQueryParam(location.search, 'copyId', null) : null;

    if (match.params.id === 'new' && !copyId) {
      return Promise.resolve();
    }

    return getPromoCode(copyId || match.params.id).then(res => {
      const {data} = res;
      const {user = {}} = data;

      this.setState(copyId
        ? {
          created: '',
          sessions: [],

          id: null,
          name: '',
          oldName: '',
          user: data.userId && user ? {
            ...user,
            id: data.userId,
          } : null,
          system: data.system,
          discount: data.value,
          totalDiscount: Math.round(data.value * 0.23),
          limit: data.codeLimit,
          start: data.start,
          end: data.end,
          status: data.status,
          services: data.services,
        }
        : {
          created: data.created || '',
          sessions: data.sessions,

          // fields
          id: data.id,
          name: data.name,
          oldName: data.name,
          user: data.userId && user ? {
            ...user,
            id: data.userId,
          } : null,
          system: data.system,
          discount: data.value,
          totalDiscount: Math.round(data.value * 0.23),
          limit: data.codeLimit,
          start: data.start,
          end: data.end,
          status: data.status,
          services: data.services,
        }
      );

      return data;
    });
  }

  render() {
    const {
      created, sessions,

      // fields
      id, name, oldName, user,
      system, discount, totalDiscount,
      limit, start, end,
      services, statusObj,

      // delete
      deleteModalIsOpen, deleteModalLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText, errorAlertIsOpen,

      // api
      loading, checkingName, existsName,

      coachTypes,

      // applied
      tabIndex,
    } = this.state;

    const serviceSlugs = services.map(service => service.slug);

    return (
      <section className="ph-detail-page container edit-promo-code">
        {
          alertIsOpen && (
            <Alerts id={id} name={name} type={alertType} errorText={alertErrorText}
                    errorAlertIsOpen={errorAlertIsOpen} closeErrorAlert={this.closeErrorAlert}/>
          )
        }

        <DeletePromoCode
          isOpen={deleteModalIsOpen}
          modalLoading={deleteModalLoading}
          closeModal={this.closeDeleteModal}
          id={id} name={name}
          deleteSubmit={this.deleteSubmit}
        />

        <div className="table-top">
          {
            id
              ? <h4 className="ph-detail-page__title">Edit Promo Code: <b>{oldName}, {discount}%
                - {user ? `${user.name} ${user.surname}` : 'Sitewide'}</b></h4>
              : <h4 className="ph-detail-page__title">Add Promo Code</h4>
          }
          {
            id ? (
              <Button
                type="button"
                color="primary"
                onClick={this.copyClick}
                className="copy-button"
              >
                Duplicate Promo Code
              </Button>
            ) : null
          }
        </div>
        <span className="ion-close-round ph-detail-page__close" onClick={this.closeDetail}/>

        {
          loading && <div className="ph-detail-page__is-loading"><Spinner/></div>
        }

        <form action="" onSubmit={id ? this.editSubmit : this.addSubmit}>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={tabIndex => this.setState({tabIndex})}
          >
            <TabList>
              <Tab>General Info</Tab>
              {
                id ?
                  <Tab>Sessions</Tab>
                  : null
              }
            </TabList>
            <TabPanel>
              <div className="cardbox">
                <div className="cardbox-body">
                  {
                    id ?
                      <fieldset>
                        <div className="form-group row top-fields">
                          {/* created */}
                          <div className="col-md-2 col-sm-6">
                            <b>Created</b>
                            <span>{`${created.substring(0, 10)}, ${created.substring(11, 16)} UTC`}</span>
                          </div>

                          {/* used */}
                          <div className="col-md-2 col-sm-6">
                            <b>Used</b>
                            <span>{sessions.length || 0}</span>
                          </div>
                        </div>
                      </fieldset>
                      : null
                  }

                  <fieldset>
                    <div className="form-group row">

                      {/* name */}
                      <div className="col-md-2">
                        <label htmlFor="edit-promo-code_name">Name</label>

                        <div className="input-group mb-3">
                          <input
                            required
                            name="name"
                            value={name}
                            id="edit-promo-code_name"
                            disabled={id}
                            onChange={this.onChangeName}
                            type="text"
                            className={cx('form-control', {
                              'check-name-error': existsName
                            })}
                          />
                          {
                            !id && name && name.length ?
                              <div className="input-group-text">
                                {
                                  checkingName
                                    ? <i className="ion-load-c icon-fw"/>
                                    : (
                                      existsName
                                        ? <i className="ion-android-close icon-fw"/>
                                        : <i className="ion-android-done icon-fw"/>
                                    )
                                }
                              </div>
                              : null
                          }
                        </div>
                      </div>

                      {/* coach */}
                      <div className="col-md-3">
                        <label htmlFor="edit-owner">
                          Coach
                        </label>

                        <AsyncSelect
                          isDisabled={id || system}
                          menuPlacement="auto"
                          cacheOptions={true}
                          defaultOptions={true}
                          loadOptions={inputValue => getCoaches(inputValue).then(res => res.data.map(coach => coach.general))}
                          getOptionValue={o => o.id}
                          getOptionLabel={o => (
                            <div>
                              <span>{`${o.name} ${o.surname} `}</span>
                              <span style={{color: '#1976D2'}}>{o.email}</span>
                            </div>
                          )}
                          onChange={this.onChangeUser}
                          value={!system ? user : null}
                        />
                      </div>

                      {/* system */}
                      <div className="col-md-2">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="edit-system"
                            name="system"
                            checked={system}
                            disabled={id}
                            onChange={this.onChangeSystem}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="edit-system"
                            style={{fontWeight: 'normal'}}
                          >
                            Sitewide
                          </label>
                        </div>
                      </div>

                      {/* discount */}
                      <div className="col-md-2">
                        <label htmlFor="edit-discount">Discount, %</label>

                        <input
                          min={1}
                          max={100}
                          name="discount"
                          type="number"
                          value={discount}
                          id="edit-discount"
                          onChange={e => this.onChangeDiscount(e.target.value, 'discount')}
                          disabled={id}
                          className="form-control"
                        />
                      </div>

                      {/* totalDiscount */}
                      {system ? (
                        <div className="col-md-2">
                          <label htmlFor="edit-total-discount">Total Discount, %</label>

                          <input
                            min={1}
                            max={23}
                            name="total-discount"
                            type="number"
                            value={totalDiscount}
                            id="edit-total-discount"
                            onChange={e => this.onChangeDiscount(e.target.value, 'totalDiscount')}
                            disabled={id}
                            className="form-control"
                          />
                        </div>
                      ) : null}

                    </div>
                    <div className="form-group row">

                      {/* limit */}
                      <div className="col-md-2">
                        <label htmlFor="edit-limit">Limit</label>

                        <input
                          min={1}
                          max={99999}
                          name="limit"
                          type="number"
                          value={limit}
                          id="edit-limit"
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>

                      {/* start */}
                      <div className="col-md-2">
                        <label htmlFor="edit-start">Start</label>
                        <input
                          type="date"
                          name="start"
                          id="edit-start"
                          value={start ? start.substring(0, 10) : ''}
                          style={{height: '38px'}}
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>

                      {/* end */}
                      <div className="col-md-2">
                        <label htmlFor="edit-end">End</label>
                        <input
                          type="date"
                          name="end"
                          id="edit-end"
                          value={end ? end.substring(0, 10) : ''}
                          style={{height: '38px'}}
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>

                      {/* status */}
                      <div className="col-md-2">
                        <label htmlFor="edit-status">Status</label>

                        <Select
                          value={statusObj}
                          onChange={this.onChangeStatus}
                          options={statusOptions}
                        />
                      </div>

                    </div>
                    <div className="form-group row">

                      <div className="col-md-6">
                        <label className="select-label">
                          Services&nbsp;
                          <a
                            href="#"
                            className={cx('select-link', {
                              'select-link--disabled': services.length === coachTypes.length
                            })}
                            onClick={this.selectAll}
                          >
                            Select all
                          </a>&nbsp;
                          <a
                            href="#"
                            className={cx('select-link', {
                              'select-link--disabled': !services.length
                            })}
                            onClick={this.deselectAll}
                          >
                            Deselect all
                          </a>&nbsp;
                        </label>

                        <div className="row">
                          {coachTypes.map(type => (
                            <div className="col-md-4" key={type.slug}>
                              <div className="custom-control custom-checkbox custom-checkbox--service">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`edit-services-${type.slug}`}
                                  name={`services-${type.slug}`}
                                  checked={serviceSlugs.includes(type.slug)}
                                  onChange={e => this.onChangeService(type, e.target.checked)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`edit-services-${type.slug}`}
                                  style={{fontWeight: 'normal'}}
                                >
                                  {type.name}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                    </div>
                  </fieldset>
                </div>
              </div>

              <footer className="ph-detail-page__buttons" style={{paddingBottom: '16px'}}>
                {
                  id
                    ? <Button outline color="danger" onClick={this.deleteClick}>Delete</Button>
                    : null
                }
                <Button outline color="secondary" onClick={this.closeDetail}>Cancel</Button>
                <Button disabled={(!user && !system) || !name.length || existsName || checkingName || !discount} outline
                        color="primary"
                        type="submit">Save</Button>
              </footer>
            </TabPanel>
            {
              id ?
                <TabPanel>
                  <Sessions
                    codeId={id}
                    sessions={sessions}
                  />
                </TabPanel>
                : null
            }
          </Tabs>
        </form>
      </section>
    );
  }
}

export default PromoCodeDetail;
