import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const refund = (id) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.post(
    `${API_URL}/${subUrl}/coach_sessions/${id}/refund`,

    {},

    {
      headers: { Authorization: token }
    }
  );
}

export default refund;
