import React from 'react';
import ReactTable from 'react-table';

import './styles.scss';

const Table = React.forwardRef((props, ref) => (
  <ReactTable
    ref={ref}
    filterable={true}
    resizable={true}
    className="-striped -highlight"
    {...props}
  />
));

export default Table;

