import React from 'react';
import { Alert } from "reactstrap";

const Alerts = ({ type, id, name, surname, message, errorText, errorAlertIsOpen, closeErrorAlert, refundMessage }) => {
  const Name = surname ? `${name} ${surname}` : `${name}`;

  return (
    <>
      {
        // add //
        type === 'add' ? <Alert color="success">{message || 'New item has been added'}</Alert> :

          // edit //
          type === 'edit' ? (
              <Alert color="warning">
                {message || <>{`${id}`} - <b>{Name} has been edited</b></>}
              </Alert>
            ) :

            // delete //
            type === 'delete' ? (
                <Alert color="danger">
                  {message || <>{`${id}`} - <b>{Name} has been deleted</b></>}
                </Alert>
              ) :

              // refund //
              type === 'refund' ? (
                  <Alert color="success">{refundMessage && refundMessage.length ? refundMessage : 'Refund request has been sent'}</Alert>
                ) :

                // paid //
                type === 'paid' ? (
                    <Alert color="success">{refundMessage && refundMessage.length ? refundMessage : 'You paid'}</Alert>
                  ) :

                  // error //
                  type === 'error' ? (
                      <Alert color="danger" isOpen={errorAlertIsOpen} toggle={closeErrorAlert}>{errorText}</Alert>
                    ) :

                    // copy //
                    type === 'copy' && <Alert color="warning">Copied</Alert>
      }
    </>
  )
};

export default Alerts;
