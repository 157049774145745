import axios from 'axios';
import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import {Tab, TabList, Tabs} from "react-tabs";
import {Button} from "reactstrap";

import Alerts from '../../components/Alerts';

import { withHeaderTitle } from '../../../components/Header/HeaderTitle';

import {getQueryParam} from "../../utils/queryString";

import getPayouts      from './api/getPayouts';
import payRequest from "./api/payRequest";

import ConfirmPayModal from './confirmPayModal';
import SuccessPayModal from "./successPayModal";

import Coaches from "./coaches";
import Sessions from "./sessions";

import cln from "classnames";

const payoutDay = 15;

const todayDate = window.moment();
const nextDate = window.moment().startOf('month').add(payoutDay - 1, 'day') > window.moment() ?
  window.moment().startOf('month').add(payoutDay - 1, 'day') :
  window.moment().startOf('month').add(1, 'month').add(payoutDay - 1, 'day');

class Payouts extends Component {
  UNSAFE_componentWillMount() { this.props.setHeaderTitle('Payouts') }
  cancelRequest = null;

  state = {
    // table
    coach: null, payouts: [], tableLoading: false,

    // payment
    payLoading: false,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', errorAlertIsOpen: false,

    // modals
    confirmModalIsOpen: false, confirmModalText: '', payCoach: null,
    successPayModalIsOpen: false, successPayModalLoading: false, payoutResults: [],

    coachId: null,
    tabIndex: parseInt(getQueryParam(this.props.location.search, 'tab', 0)),
  }

  catchErrors = error => {
    if (axios.isCancel(error)) {
      return;
    }

    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`,
        confirmModalIsOpen: false,
        successPayModalIsOpen: false,
        successPayModalLoading: false,
      });
    }
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems = () => {
    const {tabIndex} = this.state;
    const {match: {params: {coachId = null}}} = this.props;

    if (typeof this.cancelRequest === 'function') {
      this.cancelRequest();
      this.cancelRequest = null;
    }

    this.setState({ tableLoading: true, coachId });

    const options = {};

    if (coachId) {
      options.coachId = coachId;
    }

    if (tabIndex === 2) {
      options.type = 'escrow';
    } else {
      options.date = (tabIndex === 0 ? todayDate : nextDate).toISOString();
    }

    const { request, cancel } = getPayouts(options);
    this.cancelRequest = cancel;

    // data request
    request
      .then(res => {
        if (tabIndex === this.state.tabIndex) {
          this.setState({
            coach: coachId && res.data && res.data.length ? res.data[0].coach : null,
            payouts: res.data,
            tableLoading: false
          });
        }
      })
      .catch(error => this.catchErrors(error))
      .finally(() => {
        this.cancelRequest = null;
      });
  };

  selectTabIndex = (tabIndex) => {
    if (this.state.tabIndex === tabIndex) {
      return;
    }

    if (tabIndex > 0) {
      this.props.history.replace({
        search: `?tab=${tabIndex}`,
      });
    } else {
      this.props.history.replace({
        search: '',
      });
    }

    this.setState({tabIndex}, () => {
      this.loadItems();
    });
  };

  payCoachClick = (e, coach) => {
    e.preventDefault();

    this.setState({
      payCoach: coach,
      confirmModalIsOpen: true,
      confirmModalText: `Are you sure you want to pay coach [${coach.coach.id}] - ${coach.coach.name} ${coach.coach.surname}?`,
      alertIsOpen: false,
    });
  }

  payCoach = (coach) => {
    const sessions = coach.coachSessions.map((session) => session.id);

    this.setState({
      payLoading: true,
      errorAlertIsOpen: false,
      confirmModalIsOpen: false,
      successPayModalIsOpen: true,
      successPayModalLoading: true,
      payoutResults: [],
    });

    payRequest(sessions).then(res => {
      // open alert
      this.setState({
        successPayModalLoading: false,
        payoutResults: [{
          ...(res.data.success.length ? res.data.success[0] : res.data.error[0]),
          coach: coach.coach,
        }]
      });

      const successPayoutsCoachIds = res.data.success.map((payout) => payout.coachId);

      if (!this.state.coachId) {
        this.setState({
          payouts: this.state.payouts.filter((payout) => !successPayoutsCoachIds.includes(payout.coachId)),
        });
      }

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({payLoading: false});
      }, 2000);

    }).catch(error => this.catchErrors(error));
  };

  payCoachesClick = (e) => {
    e.preventDefault();

    this.setState({
      payCoach: null,
      confirmModalIsOpen: true,
      confirmModalText: 'Are you sure you want to pay all coaches?',
      alertIsOpen: false
    });
  }

  payCoaches = () => {
    const {payouts} = this.state;

    const sessions = [];
    const coachMap = {};

    payouts
      .filter((payout) => !!payout.paypal)
      .forEach((payout) => {
        coachMap[payout.coachId] = payout.coach;
        payout.coachSessions.forEach((session) => {
          sessions.push(session.id);
        });
      });

    this.setState({
      payLoading: true,
      errorAlertIsOpen: false,
      confirmModalIsOpen: false,
      successPayModalIsOpen: true,
      successPayModalLoading: true,
      payoutResults: [],
    });

    payRequest(sessions).then(res => {
      const successPayoutsCoachIds = res.data.success.map((payout) => payout.coachId);

      // open alert
      this.setState({
        successPayModalLoading: false,
        payoutResults: [
          ...res.data.success.map((payout) => ({
            ...payout,
            coach: coachMap[payout.coachId],
          })),
          ...res.data.error.map((payout) => ({
            ...payout,
            coach: coachMap[payout.coachId],
          }))
        ],
        payouts: this.state.payouts.filter((payout) => !successPayoutsCoachIds.includes(payout.coachId)),
      });

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({
          payLoading: false,
        });
      }, 2000);

    }).catch(error => this.catchErrors(error));
  };

  confirmSubmit = () => {
    const { payCoach } = this.state;

    if (payCoach) {
      this.payCoach(payCoach);
    } else {
      this.payCoaches();
    }

    this.setState({
      payCoach: null,
      errorAlertIsOpen: false,
    })
  };

  closeConfirmModal = () => {
    this.setState({
      payCoach: null,
      confirmModalIsOpen: false
    })
  };

  openPayoutsHistory = () => {
    const {history} = this.props;
    history.push('/payout-history');
  };

  closeSuccessPayModal = () => {
    this.setState({
      successPayModalIsOpen: false
    });

    if (this.state.coachId) {
      const {history} = this.props;
      history.push('/payouts');
    }
  };

  render() {
    const {
      // table
      coach, payouts, tableLoading,

      // payment
      payLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText,

      // modals
      confirmModalIsOpen, confirmModalText,
      successPayModalIsOpen, successPayModalLoading, payoutResults,

      coachId,
      tabIndex,
    } = this.state;

    const controlsColumn = [
      {
        Header: '',
        width: 180,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => {
          if (!original.paypal) {
            return (
              <div className="actions">
                <span className="color-dark-text-disabled">Pay This Coach Now</span>
              </div>
            );
          }

          return (
            <div className="actions">
              <a href="#" onClick={(e) => this.payCoachClick(e, original)}>Pay This Coach Now</a>
            </div>
          );
        }
      }
    ];

    return (
      <div className="payouts-page">
        <div className="table-top">
          <h4 className="ph-detail-page__title">
            {
              coach
                ? <b>Payout to {coach.name} {coach.surname}</b>
                : <b>Payouts</b>
            }
            {' '}
            (as of {todayDate.format('MMM D')})
          </h4>
          {
            coachId ?
              <>
                <NavLink to={`/payouts${tabIndex ? '?tab=' + tabIndex : ''}`}>
                  Back to Payouts
                </NavLink>
                <Button
                  type="button"
                  color="primary"
                  onClick={(e) => this.payCoachClick(e, payouts[0])}
                  className={cln('pay-button', { 'is-loading': payLoading })}
                  disabled={tableLoading || payLoading || !!(payouts && payouts.length && !payouts[0].paypal)}
                >
                  Pay This Coach Now
                </Button>
              </>
              : <Button
                type="button"
                color="primary"
                onClick={this.payCoachesClick}
                className={cln('pay-button', { 'is-loading': payLoading })}
                disabled={tableLoading || payLoading || !payouts || !payouts.length}
              >
                Pay All Coaches Now
              </Button>
          }
        </div>

        { alertIsOpen && <Alerts type={alertType} errorText={alertErrorText} /> }

        <ConfirmPayModal
          isOpen={confirmModalIsOpen}
          confirmSubmit={this.confirmSubmit}
          closeModal={this.closeConfirmModal}
        >
          <span>
            {confirmModalText}
          </span>
        </ConfirmPayModal>

        <SuccessPayModal
          isOpen={successPayModalIsOpen}
          modalLoading={successPayModalLoading}
          closeModal={this.closeSuccessPayModal}
          openPayoutsHistory={this.openPayoutsHistory}
        >
          <h4>Payout Results</h4>
          {
            payoutResults.map((payout) => (
              <div
                key={payout.coachId}
                className='form-group row'
              >
                <div className='col-md-4 col-sm-6'>
                  {`${payout.coach.name} ${payout.coach.surname}`}
                </div>
                <div className='col-md-4 col-sm-6'>
                  ${payout.total}
                </div>
                <div className='col-md-4 col-sm-6'>
                  <b className={cln('text-capitalize', {
                    'text-success': ['SUCCESS'].includes(payout.status),
                    'text-danger': ['ERROR', 'UNCLAIMED', 'FAILED'].includes(payout.status),
                  })}>
                    {payout.status.toLowerCase()}
                  </b>
                </div>
              </div>
            ))
          }
        </SuccessPayModal>

        <Tabs
          selectedIndex={tabIndex}
          onSelect={this.selectTabIndex}
        >
          <TabList>
            <Tab>Today</Tab>
            <Tab>Next Payout Date ({nextDate.format('MMM D')})</Tab>
            <Tab>Escrow</Tab>
          </TabList>
        </Tabs>

        {
          !coachId ?
            <Coaches
              tabIndex={tabIndex}
              tableLoading={tableLoading}
              payouts={payouts}
              controlsColumn={tabIndex === 0 ? controlsColumn : []}
            /> :
            <Sessions
              tableLoading={tableLoading}
              coach={payouts.length ? payouts[0].coach : {}}
              amount={payouts.length ? payouts[0].amount : 0}
              total={payouts.length ? payouts[0].total : 0}
              sessions={payouts.length ? payouts[0].coachSessions : []}
            />
        }
      </div>
    )
  }
}

export default withHeaderTitle(Payouts);
