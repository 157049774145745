import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const checkPromoCodeUniqueName = (name) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.get(
    `${API_URL}/${subUrl}/promo_codes_jobs/check/${name}`,
    {
      headers: { Authorization: token }
    }
  ).then(res => res.data.exists);
};

export default checkPromoCodeUniqueName;
