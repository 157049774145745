import React from 'react';
import { NavLink } from 'react-router-dom';
import cln from "classnames";

import DatePicker from "react-datepicker";
import { Input } from 'debounce-input-decorator';
import Tooltip from "../../components/Tooltip";
import Select from "react-select";

import statusOptions from "./api/statusOptions";

import './table.scss';

const columns = [

  // Created //
  {
    Header: 'Created',
    accessor: 'created',
    width: 120,
    Cell: ({ original }) => {
      const { created } = original;
      const createdString = created ? created.substring(0, 10) : '';
      return (
        <div className="ellipsis-text" title={createdString}>
          <span>{createdString}</span>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <DatePicker
          placeholderText="Select date..."
          isClearable={!!filter}
          className="created-datepicker"
          selected={filter ? filter.value : ''}
          onChange={date => onChange(date)}
        />
      );
    }
  },

  // coach //
  {
    Header: 'Coach',
    accessor: 'coach',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { coach: { id, general: {name = '', surname = ''} = { general: {} } } } = original;
      return (
        <div className="table-column-name ellipsis-text" title={`${name} ${surname}`}>
          <NavLink to={`/coaches/${id}`}>
            {`${name} ${surname}`}
          </NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // paypal //
  {
    Header: 'PayPal Account',
    accessor: 'paypal',
    width: 280,
    Cell: ({ original }) => {
      const { paypal } = original;
      return (
        <div
          title={paypal || '-'}
        >
          {paypal || '-'}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // sessions //
  {
    Header: 'Sessions',
    accessor: 'sessions',
    width: 120,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { sessions, coachSessions, id } = original;
      return (
        <div
          title={coachSessions ? coachSessions.length : sessions}
        >
          {coachSessions ? coachSessions.length : sessions} <NavLink to={`/payout-history/${id}`}>(view all)</NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        type='number'
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // total //
  {
    Header: 'Amount',
    accessor: 'total',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { total } = original;
      return (
        <div
          className="ellipsis-text"
          title={Math.round(total * 100) / 100 ? `$${Math.round(total * 100) / 100}` : 'Free'}
        >
          {Math.round(total * 100) / 100 ? `$${Math.round(total * 100) / 100}` : 'Free'}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // amount //
  {
    Header: 'Less Fees',
    accessor: 'amount',
    width: 100,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { amount } = original;
      return (
        <div
          className="ellipsis-text"
          title={Math.round(amount * 100) / 100 ? `$${Math.round(amount * 100) / 100}` : 'Free'}
        >
          {Math.round(amount * 100) / 100 ? `$${Math.round(amount * 100) / 100}` : 'Free'}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  // status //
  {
    Header: 'Status',
    accessor: 'status',
    width: 100,
    Cell: ({ original }) => {
      const { status } = original;
      const statusString = status ? status.replace(/_/g, ' ').toLowerCase() : '';
      return (
        <div
          className={cln('text-capitalize', {
            'status--error font-weight-bold': ['ERROR', 'UNCLAIMED', 'FAILED'].includes(status)
          })}
          title={statusString}
        >
          {statusString}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => {
      return (
        <Select
          onChange={
            option => {
              if (!option) {
                return onChange(null);
              }

              return onChange(option.value);
            }
          }
          options={statusOptions}
          className='sessions-select'
          menuPosition='fixed'
          value={filter ? statusOptions.find(option => option.value === filter.value) : statusOptions[0]}
        />
      )
    }
  },

  // error //
  {
    Header: 'Error',
    accessor: 'reason',
    sortable: false,
    filterable: false,
    style: { textAlign: 'center' },
    Cell: ({ original }) => {
      const { reason } = original;

      if (!reason) {
        return null;
      }

      return (
        <Tooltip content={reason}>
          <div
            className="text-capitalize"
            title={reason}
            data-toggle="tooltip" data-placement="top"
          >
            View
          </div>
        </Tooltip>
      );
    }
  },
];

export default columns;
