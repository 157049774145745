import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';

// state - own react-table state
// data-path - url-parh for request
const getSessionsCount = (state) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const { filtered } = state;

  // where template for request
  const where = {};

  // when we are typing something inside filter-fields state of react-table is changing
  // we take filter inputs values and inject to request params
  filtered.forEach(i => {
    // id //
    if (i.id === 'id') {
      where[i.id] = parseInt(i.value);

    // type //
    } else if (i.id === 'type') {
      if (i.value) {
        where.type = i.value.id;
      }

    // talent //
    } else if (i.id === 'talent') {
      where.talent = { 'like': '%' + i.value + '%' }

    // coach //
    } else if (i.id === 'coach') {
      where.coach = { 'like': '%' + i.value + '%' }

    // start //
    } else if (i.id === 'start') {
      const startDate = i.value && format(i.value, 'yyyy-MM-dd');
      if (startDate) {
        return where.start = { 'gt': startDate }
      }

    // session status //
    } else if (i.id === 'sessionStatus') {
      where.sessionStatus = i.value;

    // payment status //
    } else if (i.id === 'status') {
      where.status = i.value;

    // price //
    } else if (i.id === 'price') {
      if (i.value) {
        where.price = parseInt(i.value);
      }

    // role //
    } else if (i.id === 'role') {
      if (i.value) {
        where.role = i.value.id;
      }
    } else {
      where[i.id] = {'like': '%' + i.value + '%'}
    }
  });

  // get-request for count
  return axios.get(`${API_URL}/${subUrl}/coach_sessions/searchExtra`, {
    // inject where to params
    params: {
      filter: { where },
      count: true
    },
    headers: { Authorization: token } // backend doesn't check it
  })
};

export default getSessionsCount;
