import axios from 'axios';
import {format} from 'date-fns';

import {API_URL, subUrl} from '../../../api/apiUrl';

const getSessions = (state) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const {pageSize, page, filtered, sorted} = state; // from own state of react-table

  // filter-template
  const filter = {
    where: {},
    limit: pageSize,
    skip: page * pageSize,
    order: 'id DESC',
  };

  // inject where to filter
  filtered.forEach(i => {
    // created //
    if (i.id === 'created') {
      const createdDate = i.value && format(i.value, 'yyyy-MM-dd');
      if (createdDate) {
        return filter.where.created = {'gt': createdDate}
      }
      filter.order = i.value ? 'created DESC' : 'id DESC';

      // coach //
    } else if (i.id === 'coach') {
      filter.where.coach = {'like': '%' + i.value + '%'}

      // paypal //
    } else if (i.id === 'paypal') {
      filter.where.paypal = {'like': '%' + i.value + '%'}

      // status //
    } else if (i.id === 'status') {
      filter.where.status = i.value;

      // sessions //
    } else if (i.id === 'sessions') {
      if (i.value) {
        filter.where.sessions = parseInt(i.value);
      }

      // amount //
    } else if (i.id === 'amount') {
      if (i.value) {
        filter.where.amount = parseInt(i.value);
      }

      // total //
    } else if (i.id === 'total') {
      if (i.value) {
        filter.where.total = parseInt(i.value);
      }

    } else {
      filter.where[i.id] = {'like': '%' + i.value + '%'}
    }
  });

  // inject order to filter
  sorted.forEach(i => {
    const desc = i.desc ? 'DESC' : 'ASC'
    filter.order = `${i.id} ${desc}`;
  });

  // get-request for data
  return axios.get(`${API_URL}/${subUrl}/payouts/searchExtra`, {
    params: {filter},
    headers: {Authorization: token} // backend doesn't check it
  })
};

export default getSessions;
