import axios from 'axios';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getTotal = () => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.get(
    `${API_URL}/${subUrl}/payouts/getTotal`,
    {
      headers: { Authorization: token },
      params: {}
    }
  )
};

export default getTotal;
