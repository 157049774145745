import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';

// state - own react-table state
// data-path - url-parh for request
const getCampaignLogsCount = (state, campaign_id) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  // where template for request
  const where = {
    campaign_id,
  };

  if (state) {
    const { filtered } = state;

    // when we are typing something inside filter-fields the state of react-table is changing
    // we take filter inputs values and inject to request params
    filtered.forEach(i => {
      // Created // +
      if (i.id === 'created') {
        const createdDate = i.value && format(i.value, 'yyyy-MM-dd');
        where.created = { 'gt': createdDate };
        // URL
      } else if (i.id === 'url') {
        where[i.id] = { 'like': '%' + i.value + '%' };
        // User
      } else if (i.id === 'user' && i.value) {
        where['user_id'] = i.value.id;
      }
    });
  }

  // get-request for count
  return axios.get(`${API_URL}/${subUrl}/campaign_logs/searchExtra`, {
    // inject where to params
    params: {
      filter: { where },
      count: true
    },
    headers: { Authorization: token } // backend doesn't check it
  })
};

export default getCampaignLogsCount;
