import React, { Component } from 'react';

import isEmpty from 'lodash/isEmpty';

import Table from '../../components/Table';
import columns from './columns';

import Alerts from '../../components/Alerts';

import formatNumber from './../../utils/formatNumber';

import { withHeaderTitle } from '../../../components/Header/HeaderTitle';

import getPayments      from './api/getPayments';
import getPaymentsCount from './api/getPaymentsCount';
import csvExport from './api/csvExport';
//import getExportCoachSessionsUrl from "./api/getExportCoachSessionsUrl";

class Payments extends Component {
  UNSAFE_componentWillMount() { this.props.setHeaderTitle('Job Post History') }

  state = {
    // table
    tableState: { filtered: [], sorted: [] },
    payments: [], paymentsCount: null, tableLoading: false, count: null,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', errorAlertIsOpen: false,
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  exportToCsv = (e) => {
    e.preventDefault();

    //window.open(getExportCoachSessionsUrl(this.state.tableState), '_blank');
  };

  closeErrorAlert  = () => this.setState({ errorAlertIsOpen: false });

  catchErrors = error => {
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  componentWillReceiveProps() {
    
  }

  render() {
    const {
      // table
      payments, paymentsCount, count, tableLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText,
    } = this.state;

    return (
      <div className="payments-page">
        <div className="table-top">
          <p className="md-lg">
            Total records:&nbsp;<b>{count && formatNumber(this.state.count)}</b>
          </p>

          <a
            href='#'
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => { e.preventDefault(); csvExport(this.state.tableState); }}
          >
            Export as CSV
          </a>
        </div>

        { alertIsOpen && <Alerts type={alertType} errorText={alertErrorText} /> }

        <Table
          data={payments}
          manual={true}
          pages={paymentsCount}
          loading={tableLoading}
          columns={[...columns]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
          onFetchData={state => {
            this.setState({ tableState: state, tableLoading: true });

            // count request
            getPaymentsCount(state)
              .then(res => {
                this.setState({
                  count: res.data.count,
                  paymentsCount: Math.ceil(res.data.count / state.pageSize)
                })

                // data request
                getPayments(state)
                  .then(res => this.setState({ payments: res.data, tableLoading: false }))
                  .catch(error => this.catchErrors(error));
              }).catch(error => this.catchErrors(error));
          }}
        />
      </div>
    )
  }
}

export default withHeaderTitle(Payments);
