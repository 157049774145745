import React from 'react';

import Table from '../../components/Table';
import sessionsColumns from "./sessionsColumns";

const Sessions = ({ sessions, tableLoading, amount, total }) => (
  <>
    <div className="form-group row top-fields">
      {/* sessions */}
      <div className="col-md-2 col-sm-4">
        Sessions: {!tableLoading ? sessions.length : 0}
      </div>

      {/* total */}
      <div className="col-md-2 col-sm-4">
        Amount: ${!tableLoading ? Math.round(total * 100) / 100 : 0}
      </div>

      {/* amount */}
      <div className="col-md-2 col-sm-4">
        Less Fees: ${!tableLoading ? Math.round(amount * 100 / 100) : 0}
      </div>
    </div>

    <Table
      showPagination={false}
      data={sessions}
      loading={tableLoading}
      columns={[...sessionsColumns]}
      getTheadFilterThProps={(state, rowInfo, column) => {
        return { style: { overflow: 'visible' }};
      }}
    />
  </>
);

export default Sessions;
