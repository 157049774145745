import axios from 'axios';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getSessionsCounter = (coachId) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  // get-request for counter data
  return axios.get(`${API_URL}/${subUrl}/coaches/${coachId}/sessionsCounter`, {
    // inject where to params
    headers: { Authorization: token } // backend doesn't check it
  })
};

export default getSessionsCounter;
