import { format } from 'date-fns';
import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';
import filterConstructor from './filterConstructor';

const getExportUrl = state => {
  const { filtered, sorted } = state; // from own state of react-table
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;
  // filter-template
  const filter = {
    where: {},
    order: 'id DESC',
  };
  filterConstructor(filter, filtered, sorted);
  axios({
    url: `${API_URL}/${subUrl}/users/csvExport`,
    method: 'GET',
    params: { filter, csv: true },
    headers: { Authorization: token },
    responseType: 'arraybuffer', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users.csv');
    document.body.appendChild(link);
    link.click();
  });
};

export default getExportUrl;
