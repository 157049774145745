import { format } from 'date-fns';

const filterConstructor = (filter = {}, filtered, sorted) => {
    // inject where to filter
  filtered.forEach(i => {
    // id //
    if (i.id === 'created') {
      if (i.value) filter.where.created = { gt: format(i.value, 'yyyy-MM-dd') }
    }
    if (i.id === 'plan') filter.where.plan = i.value;
    if (i.id === 'status') filter.where.status = i.value;
    if (i.id === 'id') filter.where[i.id] = parseInt(i.value);
    if (i.id === 'vacancy_id') filter.where.vacancy_id = parseInt(i.value);
    if (i.id === 'user') filter.where.user = { 'like': '%' + i.value + '%' };
    if (i.id === 'email') filter.where.email = { 'like': '%' + i.value + '%' };
    if (i.id === 'job') filter.where.job = { 'like': '%' + i.value + '%' };
    if (i.id === 'promoCode') filter.where.promoCode = { 'like': '%' + i.value + '%' };
    if (i.id === 'amount') filter.where.amount = parseInt(i.value); 
    
  });

  // inject order to filter
  sorted.forEach(i => {
    const desc = i.desc ? 'DESC' : 'ASC'
    filter.order = `${i.id} ${desc}`;
  });
}

export default filterConstructor;