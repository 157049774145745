import React from 'react';
import { NavLink } from 'react-router-dom';
import { Input } from 'debounce-input-decorator';

const columns = [

  // id //
  {
    Header: 'Id',
    accessor: 'id',
    width: 60,
    style: { textAlign: 'right' },
    Cell: ({ original }) => {
      const { id } = original;
      return (
        <div className="ellipsis-text" title={id || ''}>{id || ''}</div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  {
    Header: 'Page title',
    accessor: 'title',
    style: { fontWeight: 'bold' },
    Cell: ({ original }) => {
      const { id, title } = original;
      return (
        <div className="table-column-name  ellipsis-text" title={title}>
          <NavLink to={`/pages-for-advertising/${id}`}>{title}</NavLink>
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },

  {
    Header: 'Page URL/Template',
    accessor: 'url_template',
    Cell: ({ original }) => {
      const { url_template } = original;
      return (
        <div className="table-column-name  ellipsis-text" title={url_template}>
          {url_template}
        </div>
      );
    },
    Filter: ({ filter, onChange }) => (
      <Input
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: '38px' }}
        debounceTimeout={800}
      />
    ),
  },
];

export default columns;
