import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const addCampaign = (
  title, url, owner, start, end, weight, status,
  locations, skills, roles, seniorities, companies, eventtypes, user_roles, pages,
  desktopBanner, mobileBanner
) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.post(
    `${API_URL}/${subUrl}/campaigns/new`,

    {
      start: start ? window.moment(start).startOf('day').toISOString() : null,
      end: end ? window.moment(end).endOf('day').toISOString() : null,
      title, url, owner, weight, status,
      locations, skills, roles, seniorities, companies, eventtypes, user_roles, pages,
      desktop_banner: desktopBanner && desktopBanner.length ? desktopBanner : null,
      mobile_banner: mobileBanner && mobileBanner.length ? mobileBanner : null
    },

    {
      headers: { Authorization: token }
    }
  );
}

export default addCampaign;
