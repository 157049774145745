const Menu = [
  {
    id: 0,
    name: 'Companies',
    icon: 'img/icons/ios-browsers.svg',
    path: '/companies'
  },

  {
    id: 1,
    name: 'Users',
    icon: 'img/icons/person-stalker.svg',
    path: '/users'
  },

  {
    id: 2,
    name: 'Jobs',
    icon: 'img/icons/clipboard.svg',
    path: '/jobs'
  },

  {
    id: 3,
    name: 'Jobs Promo Codes',
    icon: 'img/icons/navicon.svg',
    path: '/jobs-promo-codes'
  },

  {
    id: 4,
    name: 'Job Post History',
    icon: 'img/icons/navicon.svg',
    path: '/job-payments'
  },

  {
    id: 5,
    name: 'Skills',
    icon: 'img/icons/navicon.svg',
    path: '/skills'
  },

  {
    id: 6,
    name: 'Roles',
    icon: 'img/icons/navicon.svg',
    path: '/roles'
  },

  {
    id: 7,
    name: 'Plans',
    icon: 'img/icons/connection-bars.svg',
    path: '/plans'
  },

  {
    id: 8,
    name: 'Coaches',
    icon: 'img/icons/person-stalker.svg',
    path: '/coaches'
  },

  {
    id: 9,
    name: 'Coaching Sessions',
    icon: 'img/icons/navicon.svg',
    path: '/coaching-sessions'
  },

  {
    id: 10,
    name: 'Promo Codes',
    icon: 'img/icons/navicon.svg',
    path: '/promo-codes'
  },

  {
    id: 11,
    name: 'Payouts',
    icon: 'img/icons/clipboard.svg',
    path: '/payouts'
  },

  {
    id: 12,
    name: 'Payouts History',
    icon: 'img/icons/navicon.svg',
    path: '/payout-history'
  },

  {
    id: 13,
    name: 'Campaigns',
    icon: 'img/icons/ios-browsers.svg',
    path: '/campaigns'
  },

  {
    id: 14,
    name: 'Pages for advertising',
    icon: 'img/icons/clipboard.svg',
    path: '/pages-for-advertising'
  },
];

export default Menu;
