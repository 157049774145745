import { API_URL, subUrl } from '../../../api/apiUrl';

const getExportCoachSessionsUrl = (codeId) => {
  // filter-template
  const filter = {
    where: {
      codeId,
    },
    order: 'id DESC',
  };

  return `${API_URL}/${subUrl}/coach_sessions/csvExport?filter=${encodeURIComponent(JSON.stringify(filter))}`;
};

export default getExportCoachSessionsUrl;
