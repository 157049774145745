import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const editPromoCode = state => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;
  const {
    id,
    limit,
    limitPerUser,
    start,
    end,
    plans,
  } = state;

  return axios.patch(
    `${API_URL}/${subUrl}/promo_codes_jobs/${id}`,

    {
      codeLimit: limit,
      limitPerUser,
      dateStart: start === "" || start === null ? null : start,
      dateEnd: end === "" || end === null ? null : end,
      plans
    },

    {
      headers: { Authorization: token },
      params: {
        include: [
        ]
      }
    }
  );
}

export default editPromoCode;
