import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const editCampaign = state => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;
  const {
    id,
    title,
    url,
    owner,
    start,
    end,
    weight,
    status,
    locations,
    skills,
    roles,
    seniorities,
    companies,
    eventtypes,
    user_roles,
    pages,
    desktopBanner,
    mobileBanner
  } = state;

  return axios.post(
    `${API_URL}/${subUrl}/campaigns/${id}/update`,

    {
      id,
      title,
      url,
      owner,
      start: start ? window.moment(start).startOf('day').toISOString() : null,
      end: end ? window.moment(end).endOf('day').toISOString() : null,
      weight,
      status,
      locations,
      skills,
      roles,
      seniorities,
      companies,
      eventtypes,
      user_roles,
      pages,
      desktop_banner: desktopBanner && desktopBanner.length ? desktopBanner : null,
      mobile_banner: mobileBanner && mobileBanner.length ? mobileBanner : null
    },

    {
      headers: { Authorization: token }
    }
  );
}

export default editCampaign;
