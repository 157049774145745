import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';
import filterConstructor from './filterConstructor';

// state - own react-table state
// data-path - url-parh for request
const getPaymentsCount = (state) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const { filtered, sorted } = state;

  // where template for request
  const filter = { where: {} };
  
  filterConstructor(filter, filtered, sorted);

  // get-request for count
  return axios.get(`${API_URL}/${subUrl}/payments/searchExtra`, {
    // inject where to params
    params: {
      filter: filter,
      count: true
    },
    headers: { Authorization: token } // backend doesn't check it
  })
};

export default getPaymentsCount;
