import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const editPromoCode = state => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;
  const {
    id,
    limit,
    start,
    end,
    status,
    services,
  } = state;

  return axios.patch(
    `${API_URL}/${subUrl}/promo_codes/${id}`,

    {
      codeLimit: limit,
      start,
      end,
      status,
      services,
    },

    {
      headers: { Authorization: token },
      params: {
        include: [
          'user'
        ]
      }
    }
  );
}

export default editPromoCode;
