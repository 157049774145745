import React from 'react';
import { Button } from 'reactstrap';
import ReactModal from 'react-modal';

import Spinner from '../../../components/Spinner';

const SuccessPayModal = ({ isOpen, modalLoading, closeModal, openPayoutsHistory, children, ...props }) => (
  <ReactModal
    {...props}
    ariaHideApp={false}
    isOpen={isOpen}
    overlayClassName="ReactModal__Overlay"
    className="ReactModal ReactModal__confirm ReactModal__confirm--success"
    portalClassName={'ReactModal__Portal__confirm'}
    onRequestClose={modalLoading ? null : closeModal}
  >
    <section className="section-container confirm-container">
      <span className="ion-close-round ReactModal__confirm__close" onClick={closeModal} />

      <div className='ReactModal__confirm__title'>
        {children}
      </div>

      {
        modalLoading ? (
          <div className="ReactModal__confirm__is-loading">
            <Spinner />
          </div>
        ) : (
          <footer className="ReactModal__confirm__buttons">
            <Button outline color="primary" onClick={openPayoutsHistory}>Open Payouts History</Button>
          </footer>
        )
      }
    </section>
  </ReactModal>
);


export default SuccessPayModal;
