import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getPromoCodes = state => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const { pageSize, page, filtered, sorted } = state; // from own state of react-table

  // filter-template
  const filter = {
    where: {},
    limit: pageSize,
    skip: page * pageSize,
    order: 'id DESC',
  };

  // inject where to filter
  filtered.forEach(i => {
    // id //
    if (i.id === 'id') {
      filter.where[i.id] = i.value;

    // created //
    } else if (i.id === 'created') {
      const createdDate = i.value && format(i.value, 'yyyy-MM-dd');
      if (createdDate) {
        return filter.where.created = { 'gt': createdDate }
      }
      filter.order = i.value ? 'created DESC' : 'id DESC';

    // name //
    } else if (i.id === 'name') {
      filter.where[i.id] = { 'like': '%' + i.value + '%' }

    // discount //
    } else if (i.id === 'value') {
      filter.where[i.id] = parseInt(i.value);

    // limit //
    } else if (i.id === 'codeLimit') {
      filter.where[i.id] = parseInt(i.value);

    // used //
    } else if (i.id === 'used') {
      filter.where[i.id] = parseInt(i.value);

    // user //
    } else if (i.id === 'user') {
      filter.where[i.id] = { 'like': '%' + i.value + '%' };

    // status //
    } else if (i.id === 'status') {
      filter.where.status = i.value;

    } else {
      filter.where[i.id] = {'like': '%' + i.value + '%'}
    }
  });

  // inject order to filter
  sorted.forEach(i => {
    const desc = i.desc ? 'DESC' : 'ASC'
    filter.order = `${i.id} ${desc}`;
  });

  // get-request for data
  return axios.get(`${API_URL}/${subUrl}/promo_codes/searchExtra`, {
    params: { filter },
    headers: { Authorization: token } // backend doesn't check it
  })
};

export default getPromoCodes;
