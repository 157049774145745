import React, { Component } from 'react';

import Select from "react-select";
import TimeField from 'react-simple-timefield';

import timezoneOptions from "./api/timezoneOptions";
import weekdaysOptions from "./api/weekdaysOptions";
import {Button} from "reactstrap";

class Availability extends Component {
  onChange = (e) => {
    const {name, value} = e.target;

    const { settings, onChangeSettings } = this.props;

    settings[name] = value || 0;

    onChangeSettings(settings);
  };

  onSwitch = (dayName, checked) => {
    const { settings, onChangeSettings } = this.props;

    if (checked) {
      settings.available.push(dayName);
    } else {
      settings.available.splice(settings.available.indexOf(dayName), 1);
    }

    onChangeSettings(settings);
  };

  onTimeChange = (dayName, index, type, value) => {
    const { availability, onChange } = this.props;

    availability[dayName][index][type] = value;

    onChange(availability);
  };

  addTimespan = (dayName) => {
    const { availability, onChange } = this.props;

    if (!availability[dayName]) {
      availability[dayName] = [];
    }

    availability[dayName].push({
      start: '09:00:00',
      end: '17:00:00',
    });

    onChange(availability);
  };

  deleteTimespan = (dayName, index) => {
    const { availability, onChange } = this.props;

    availability[dayName].splice(index, 1);

    onChange(availability);
  };

  render() {
    const { availability, settings } = this.props;
    const { available, buffer, max_pd, max_pw, lead_time, days_out, timezone } = settings;

    return (
      <div className="coaches-availability cardbox">
        <div className="cardbox-body">
          <fieldset>
            <div className="form-group row">

              {/* buffer */}
              <div className="col-md-3">
                <label htmlFor="edit-buffer">Buffer between sessions, min</label>
                <input
                  name="buffer"
                  type="number"
                  value={buffer}
                  id="edit-buffer"
                  style={{ height: '38px'}}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>

              {/* max_pd */}
              <div className="col-md-2">
                <label htmlFor="edit-max_pd">Max sessions: DAY</label>
                <input
                  name="max_pd"
                  type="number"
                  value={max_pd}
                  id="edit-max_pd"
                  style={{ height: '38px'}}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>

              {/* max_pw */}
              <div className="col-md-2">
                <label htmlFor="edit-max_pw">Max sessions: WEEK</label>
                <input
                  name="max_pw"
                  type="number"
                  value={max_pw}
                  id="edit-max_pw"
                  style={{ height: '38px'}}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>

              {/* lead_time */}
              <div className="col-md-2">
                <label htmlFor="edit-lead_time">Lead time, hours</label>
                <input
                  name="lead_time"
                  type="number"
                  value={lead_time}
                  id="edit-lead_time"
                  style={{ height: '38px'}}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>

              {/* days_out */}
              <div className="col-md-2">
                <label htmlFor="edit-days_out">Scheduling days out</label>
                <input
                  name="days_out"
                  type="number"
                  value={days_out}
                  id="edit-days_out"
                  style={{ height: '38px'}}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>

              {/* timezone */}
              <div className="col-md-5">
                <label htmlFor="edit-timezone">Time Zone</label>
                <Select
                  id='timezone'
                  onChange={option => this.onChange({target: {name: 'timezone', value: option.value}})}
                  value={timezoneOptions.find(tz => tz.value === timezone)}
                  options={timezoneOptions}
                />
              </div>

            </div>
          </fieldset>

          <footer>
            <div className="form-group week-row">
              {
                weekdaysOptions.map(day => (
                  <div
                    key={day.name}
                    className="week-column"
                  >
                    <label>{day.label}</label>
                    <div className="custom-checkbox form-group">
                      <label className="switch switch-warn switch-primary">
                        <input
                          name={day.name}
                          type="checkbox"
                          checked={(available || []).includes(day.name)}
                          onChange={event => this.onSwitch(day.name, event.target.checked)}
                        />
                        <span/>
                      </label>
                    </div>
                    {
                      availability[day.name] ?
                        <div>
                          {
                            availability[day.name].map((cDay, index) => (
                              <div key={index} className='form-group'>
                                <TimeField
                                  key={`start-${day.name}-${index}`}
                                  className='form-control timepicker'
                                  value={cDay.start}
                                  disabled={!(available || []).includes(day.name)}
                                  onChange={e => this.onTimeChange(day.name, index, 'start', e.target.value)}
                                />
                                <TimeField
                                  key={`end-${day.name}-${index}`}
                                  className='form-control timepicker'
                                  value={cDay.end}
                                  disabled={!(available || []).includes(day.name)}
                                  onChange={e => this.onTimeChange(day.name, index, 'end', e.target.value)}
                                />
                                <i
                                  className="ion-android-delete"
                                  style={{marginLeft: '5px'}}
                                  onClick={() => this.deleteTimespan(day.name, index)}
                                />
                              </div>
                            ))
                          }
                        </div>
                        : null
                    }
                    <Button
                      color='primary'
                      onClick={() => this.addTimespan(day.name)}
                      disabled={!(available || []).includes(day.name)}
                    >
                      Add Timespan
                    </Button>
                  </div>
                ))
              }
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default Availability;
