import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const addPage = (title, url_template, step) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.post(
    `${API_URL}/${subUrl}/pages`,

    { title, url_template, step },

    {
      headers: { Authorization: token }
    }
  );
}

export default addPage;
