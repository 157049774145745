import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import cln from "classnames";

import { withHeaderTitle } from '../../../components/Header/HeaderTitle';

import {Button} from "reactstrap";
import Alerts from '../../components/Alerts';
import Table     from '../../components/Table';
import ConfirmPayModal from './confirmPayModal';

import getSessions      from './api/getSessions';
import retryRequest from './api/retryRequest';

import sessionsColumns from './sessionsColumns';

class Sessions extends Component {
  UNSAFE_componentWillMount() { this.props.setHeaderTitle(`Payouts History`) }

  state = {
    // table
    coach: null, payout: null, sessions: [], tableLoading: false,

    // alerts
    alertIsOpen: false, alertType: '', alertErrorText: '', errorAlertIsOpen: false,

    // modal
    confirmModalIsOpen: false, confirmModalLoading: false, confirmModalText: '',

    retryLoading: false,
  }

  catchErrors = error => {
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');
    } else {
      this.setState({
        errorAlertIsOpen: true,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`,
        confirmModalLoading: false,
        confirmModalIsOpen: false,
      });
    }
  }

  componentDidMount() {
    this.getSessions();
  }

  getSessions = () => {
    const {match: {params: {id = null}}} = this.props;

    this.setState({ tableLoading: true });

    // data request
    getSessions(id)
      .then(res => {
        this.setState({
          coach: res.data && res.data.length ? res.data[0].coach : null,
          payout: res.data.length ? res.data[0].payout : null,
          sessions: res.data,
          tableLoading: false
        });
      })
      .catch(error => this.catchErrors(error));
  };

  retryClick = (e) => {
    e.preventDefault();

    const {payout, coach} = this.state;

    if (!payout || !coach) {
      return;
    }

    const { general } = coach;

    this.setState({
      payout,
      confirmModalIsOpen: true,
      confirmModalText: `Are you sure you want to try to pay coach [${payout.coachId}] - ${general.name} ${general.surname} again?`,
      alertIsOpen: false
    });
  };

  retry = () => {
    const {payout} = this.state;

    this.setState({
      retryLoading: true,
      errorAlertIsOpen: false,
      confirmModalLoading: true,
    });

    retryRequest(payout.id).then(res => {
      // open alert
      this.setState({
        payout: res.data,
      });

      if (['ERROR', 'FAILED'].includes(payout.status)) {
        this.setState({
          alertType: 'error',
          alertIsOpen: true,
          alertErrorText: `${payout.status}: ${payout.reason}`,
          confirmModalLoading: false,
          confirmModalIsOpen: false,
        });
      } else {
        this.setState({
          alertIsOpen: true,
          alertType: 'paid',
          confirmModalLoading: false,
          confirmModalIsOpen: false,
        });
      }

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({retryLoading: false, alertIsOpen: false});
      }, 2000);

    }).catch(error => this.catchErrors(error));
  }

  closeConfirmModal = () => {
    this.setState({
      confirmModalIsOpen: false
    })
  };

  render() {
    const {
      // table
      coach, payout, sessions, tableLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText,

      // modal
      confirmModalIsOpen, confirmModalLoading, confirmModalText,

      retryLoading,
    } = this.state;

    return (
      <div className="payouts-sessions-page">
        { alertIsOpen && <Alerts type={alertType} errorText={alertErrorText} /> }

        <ConfirmPayModal
          isOpen={confirmModalIsOpen}
          confirmSubmit={this.retry}
          modalLoading={confirmModalLoading}
          closeModal={this.closeConfirmModal}
        >
          <span>
            {confirmModalText}
          </span>
        </ConfirmPayModal>

        <div className="table-top">
          {
            coach ?
              <h4 className="ph-detail-page__title">
                <b>Payout to: {coach.general.name} {coach.general.surname}</b>
              </h4>
              : null
          }
          <NavLink to="/payout-history">
            Back to Payouts History
          </NavLink>
        </div>

        <div className="form-group row top-fields top-fields--with-border">
          {
            payout
              ? <>
                {/* created */}
                <div className="col-md-2 col-sm-6">
                  <b>Date and Time</b>
                  <span>{`${payout.created.substring(0, 10)}, ${payout.created.substring(11, 16)} UTC`}</span>
                </div>

                {/* paypal */}
                <div className="col-md-4 col-sm-6">
                  <b>PayPal Account</b>
                  <span>
                    {
                      payout.paypal ?
                        <a href={`mailto:${payout.paypal}`} title={payout.paypal} onClick={e => e.stopPropagation()}>
                          {payout.paypal}
                        </a> :
                        '-'
                    }
                  </span>
                </div>

                {/* status */}
                <div className={cln('col-md-1', 'col-sm-6', {
                  'status--success': ['SUCCESS'].includes(payout.status),
                  'status--error': ['ERROR', 'UNCLAIMED', 'FAILED'].includes(payout.status)
                })}>
                  <b>Status</b>
                  <span className="text-capitalize">{payout.status.toLowerCase()}</span>
                </div>

                {/* retry */}
                <div className="col-md-1 col-sm-6">
                  {
                    ['ERROR', 'FAILED'].includes(payout.status)
                      ? <Button outline color="primary" disabled={retryLoading} onClick={this.retryClick}>Retry</Button>
                      : null
                  }
                </div>

                {/* reason */}
                <div className="col-md-4 col-sm-6">
                  {
                    payout.reason
                      ? <>
                        Error<br />
                        {payout.reason}
                      </>
                      : null
                  }
                </div>
              </>
              : <div className="col-md-9 col-sm-6"/>
          }
        </div>

        <div className="form-group row top-fields">
          {/* sessions */}
          <div className="col-md-2 col-sm-4">
            Sessions: {!tableLoading ? sessions.length : 0}
          </div>

          {/* amount */}
          <div className="col-md-2 col-sm-4">
            Total Amount: ${!tableLoading && payout ? Math.round(payout.total * 100) / 100 : 0}
          </div>

          {/* less fees */}
          <div className="col-md-2 col-sm-4">
            Total Less Fees: ${!tableLoading && payout ? Math.round(payout.amount * 100) / 100 : 0}
          </div>
        </div>

        <Table
          showPagination={false}
          data={sessions}
          loading={tableLoading}
          columns={[...sessionsColumns]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return { style: { overflow: 'visible' }};
          }}
        />
      </div>
    )
  }
}

export default withHeaderTitle(Sessions);
