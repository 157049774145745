import axios from 'axios';
import { API_URL, subUrl } from '../../../api/apiUrl';

const checkCoachUrl = url => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  return axios.get(
    `${API_URL}/${subUrl}/coaches/existsByUrl/${url}`,
    {
      headers: { Authorization: token }
    }
  ).then(res => res.data.exists);
}

export default checkCoachUrl;
