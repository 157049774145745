// ---------------- LIBS ---------------- //
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Button } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// ---------------- LIBS ---------------- //

import formatNumber from "../../utils/formatNumber";
import {getQueryParam} from "../../utils/queryString";

// ---------------- COMPONENTS ---------------- //
import DeleteCampaign from './delete';
import Alerts from '../../components/Alerts';
import Spinner from '../../../components/Spinner';
import Table from "../../components/Table";
import logsColumns from "./logsColumns";
// ---------------- COMPONENTS ---------------- //



// ---------------- API ---------------- //
import { API_URL, subUrl } from '../../api/apiUrl';
import getCampaign    from './api/getCampaign';
import addCampaign    from "./api/addCampaign";
import editCampaign   from './api/editCampaign';
import deleteCampaign from './api/deleteCampaign';
import uploadDesktopBanner from './api/uploadDesktopBanner';
import uploadMobileBanner  from './api/uploadMobileBanner';

import getRoles        from "./api/getRoles";
import getPages        from "./api/getPages";
import getEventTypes   from './api/getEventTypes';
import getSkills       from './api/getSkills';
import getLocations    from './api/getLocations';
import getCompanies    from './api/getCompanies';
import getSeniorities  from './api/getSeniorities';
import getUsers        from './api/getUsers';
import getUserRoles    from './api/getUserRoles';
import getCampaignLogs from "./api/getCampaignLogs";
import getCampaignLogsCount from "./api/getCampaignLogsCount";

import statusOptions   from './api/statusOptions';
// ---------------- API ---------------- //

import './edit.scss';


class CampaignDetail extends React.Component {
  fileInputDesktopBanner = React.createRef();
  fileInputMobileBanner = React.createRef();



  // ---------------- STATE ---------------- //
  state = {
    alertIsOpen: false, alertType: '', alertErrorText: '', // alerts
    loading: false,                                        // api
    deleteModalIsOpen: false, deleteModalLoading: false,   // delete

    // fields
    id: null,
    created: null,
    clicks: null,
    impressions: null,

    title: '',
    url: '',
    owner: null,
    start: null,
    end: null,
    weight: 0,
    status: 'live',
    user_roles: [],
    locations: [],
    skills: [],
    roles: [],
    seniorities: [],
    companies: [],
    eventtypes: [],
    pages: [],

    statusObj: statusOptions.find(status => status.value === 'live') || null,

    // logs table
    campaignLogs: [], campaignLogsCount: null, tableLoading: false, count: null,

    // images
    desktopBanner: '', mobileBanner: '',
    desktopBannerLoading: false, mobileBannerLoading: false,
    desktopBannerSwitcher: false, mobileBannerSwitcher: false,

    // applied
    tabIndex: 0,
  }
  // ---------------- STATE ---------------- //



  // ---------------- ONCHANGE ---------------- //
  onChange            = e            => this.setState({ [e.target.name]: e.target.value });
  onChangeOwner       = owner        => this.setState({ owner });
  onChangeUserRoles   = user_roles   => this.setState({ user_roles });
  onChangeStatus      = statusObj    => this.setState({ statusObj, status: statusObj.value });
  onChangeLocations   = locations    => this.setState({ locations });
  onChangeSkills      = skills       => this.setState({ skills });
  onChangeRoles       = roles        => this.setState({ roles });
  onChangeSeniorities = seniorities  => this.setState({ seniorities });
  onChangeCompanies   = companies    => this.setState({ companies });
  onChangeEventTypes  = eventtypes   => this.setState({ eventtypes });
  onChangePages       = pages        => this.setState({ pages });
  // ---------------- ONCHANGE ---------------- //



  // ---------------- CLOSE ---------------- //
  closeDetail      = () => this.props.history.push('/campaigns');
  closeDeleteModal = () => {
    const { deleteModalLoading } = this.state
    !deleteModalLoading && this.setState({ deleteModalIsOpen: false });
  }
  closeErrorAlert  = () => this.setState({ errorAlertIsOpen: false });
  // ---------------- CLOSE ---------------- //

  // ---------------- ADD ---------------- //
  addSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true });
    const {
      title, url, owner, start, end, weight, status,
      locations, skills, roles, seniorities, companies, eventtypes, user_roles, pages,
      desktopBanner, mobileBanner,
    } = this.state;

    addCampaign(
      title, url, owner, start, end, weight, status,
      locations, skills, roles, seniorities, companies, eventtypes, user_roles, pages,
      desktopBanner, mobileBanner,
    )
      .then(res => {
        // open alert
        this.setState({ alertIsOpen: true, alertType: 'add'});

        // close alert after 2 sec and redirect to table
        setTimeout(() => {
          this.setState({loading: false, alertIsOpen: false});

          // push data to router
          const {history} = this.props;
          history.push({
            pathname: '/campaigns',
            state: {
              afterAddData: Object.assign({created: new Date().toISOString()}, res.data),
              detailState: this.state,
            }
          });
        }, 2000);
      })

      .catch(error => this.catchErrors(error));
  };
  // ---------------- ADD ---------------- //

  // ---------------- EDIT ---------------- //
  editSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });

    const { state } = this;
    editCampaign(state).then(res => {
      // open alert
      this.setState({ alertIsOpen: true, alertType: 'edit'});

      // close alert after 2 sec and redirect to table
      setTimeout(() => {
        this.setState({ loading: false, alertIsOpen: false});

        // push data to router
        const { history } = this.props;
        history.push({
          pathname: '/campaigns',
          state: {
            afterEditData: res.data,
            detailState: state,
          }
        });
      }, 2000);

    }).catch(error => this.catchErrors(error));
  }
  // ---------------- EDIT ---------------- //



  // ---------------- DELETE ---------------- //
  deleteClick = () => {
    this.setState({ deleteModalIsOpen: true, alertIsOpen: false });
  }

  deleteSubmit = () => {
    const { id } = this.state;

    this.setState({
      loading: true,
      deleteModalIsOpen: false, // it may be lower in logic (now it is not needs if false)
      deleteModalLoading: true,
      errorAlertIsOpen: false
    });

    deleteCampaign(id)
      .then(() => {
        this.setState({
          deleteModalIsOpen: false,
          deleteModalLoading: false,
          alertType: 'delete', alertIsOpen: true
        });

        setTimeout(() => {
          // push data to router
          const { history } = this.props;
          history.push({
            pathname: '/campaigns',
            state: { deletedId: id }
          });

          this.setState({ alertIsOpen: false });
        }, 2000);
      })
      .catch(error => this.catchErrors(error));
  }
  // ---------------- DELETE ---------------- //



  // ---------------- DESKTOP BANNER & MOBILE BANNER ---------------- //
  onUploadDesktopBanner = e => {
    e.preventDefault();
    this.setState({
      desktopBannerLoading: true,
      desktopBannerSwitcher: true
    });

    // add new form data
    const formData = new FormData();

    // get image from the browser memory
    const uploadDesktopBannerFile = this.fileInputDesktopBanner.current.files[0];

    // append this file to form data
    formData.append('file', uploadDesktopBannerFile);

    // uploadDesktopBannerRequest
    uploadDesktopBanner(formData)
      .then(res => {
        this.setState({
          desktopBanner: `${API_URL}/${subUrl}/containers/desktop-banner/download/${res.data.name}`,
          desktopBannerLoading: false
        })
      })

      // TODO
      .catch(error => console.log(error))
  }

  onUploadMobileBanner = e => {
    e.preventDefault();
    this.setState({
      mobileBannerLoading: true,
      mobileBannerSwitcher: true
    });

    // add new form data
    const formData = new FormData();

    // get image from the browser memory
    const uploadMobileBannerFile = this.fileInputMobileBanner.current.files[0];

    // append this file to form data
    formData.append('file', uploadMobileBannerFile);

    // uploadMobileBannerRequest
    uploadMobileBanner(formData)
      .then(res => {
        this.setState({
          mobileBanner: `${API_URL}/${subUrl}/containers/mobile-banner/download/${res.data.name}`,
          mobileBannerLoading: false
        })
      })

      // TODO
      .catch(error => console.log(error))
  }

  onDeleteDesktopBanner  = () => this.setState({ desktopBanner: '', desktopBannerSwitcher: false });
  onDeleteMobileBanner = () => this.setState({ mobileBanner: '', mobileBannerSwitcher: false });
  // ---------------- DESKTOP BANNER & MOBILE BANNER ---------------- //

  catchErrors = error => {
    const { name, statusCode, message } = error.response.data.error;
    if (statusCode === 401) {
      localStorage.removeItem('ph-admin-user-data');
      this.props.history.push('/login');

    } else {
      this.setState({
        errorAlertIsOpen: true,
        loading: false,
        alertType: 'error',
        alertIsOpen: true,
        alertErrorText: `${name}, ${message}`
      });
    }
  }

  copyClick = () => {
    this.props.history.push({
      pathname: `/campaigns/new?copyId=${this.state.id}`,
    });
  }


  // ---------------- LIFECYCLE ---------------- //
  componentDidMount() {
    this.loadCampaign(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const oldCampaignId = this.props.match.params.id ? this.props.match.params.id.split('?')[0] : null;
    const campaignId = nextProps.match.params.id ? nextProps.match.params.id.split('?')[0] : null;
    const copyIdInParams = nextProps.match.params.id ? (nextProps.match.params.id.split('?')[1] || null) : null;

    if (oldCampaignId === campaignId) {
      return;
    }

    if (copyIdInParams) {
      nextProps.match.params.id = campaignId;
      nextProps.location.search = `?${copyIdInParams}`;
    }

    this.loadCampaign(nextProps);
  }

  loadCampaign = ({ match, location }) => {
    const copyId = match.params.id === 'new' ? getQueryParam(location.search, 'copyId', null) : null;

    if (match.params.id === 'new' && !copyId) {
      return;
    }

    this.setState({ loading: true });

    // get request
    getCampaign(copyId || match.params.id).then(res => {
      const { data } = res;
      const {
        id, created, title, url, owner, start, end, weight, status,
        user_roles, locations, skills, roles, seniorities, companies, eventtypes, pages,
        desktop_banner, mobile_banner, impressions
      } = data;

      this.setState({
        loading: false,
        oldTitle: title,
        desktopBanner: desktop_banner,
        mobileBanner: mobile_banner,

        // save data to state from react-table
        ...(
          copyId
            ? { id: null, created: null, title, url, owner, start, end, weight, status, user_roles, locations, skills, roles, seniorities, companies, eventtypes, pages, impressions: null, }
            : { id, created, title, url, owner, start, end, weight, status, user_roles, locations, skills, roles, seniorities, companies, eventtypes, pages, impressions, }
        ),
      });

      // STATUS (get current statusObj {} from options mapping)
      if (status) {
        statusOptions.forEach(i => {
          if (status === i.value) {
            this.setState({statusObj: i});
          }
        });
      } else {
        this.setState({ statusObj: {} });
      }

      return !copyId ? id : null;
    }).then((campaignId) => {
      if (!campaignId) {
        return;
      }

      return getCampaignLogsCount(null, campaignId)
        .then(res => {
          const { data } = res;
          const { count } = data;

          this.setState({
            clicks: count
          });
        });
    })
    .catch(error => this.catchErrors(error));
  }
  // ---------------- LIFECYCLE --- ------------- //



  render() {
    const {
      // fields
      id, oldTitle,
      created, title, url, owner, start, end, weight,
      locations, skills, roles, seniorities, companies, eventtypes, user_roles, pages,
      clicks, impressions,
      statusObj,

      // images
      desktopBanner, mobileBanner, desktopBannerSwitcher, mobileBannerSwitcher, desktopBannerLoading, mobileBannerLoading,

      // alerts
      alertIsOpen, alertType, alertErrorText, errorAlertIsOpen,

      // api
      loading,

      // delete
      deleteModalIsOpen, deleteModalLoading,

      // applied
      tabIndex,

      // logs
      campaignLogs, campaignLogsCount, count, tableLoading,

    } = this.state;

    const createdString = created && `${created.substring(0, 10)}, ${created.substring(11, 16)} UTC`;

    let desktopBannerUrl = `${API_URL}/${subUrl}/containers/desktop-banner/download/${desktopBanner}`;
    let mobileBannerUrl = `${API_URL}/${subUrl}/containers/mobile-banner/download/${mobileBanner}`;

    // // fix problem with open item after additing image
    if (!desktopBannerSwitcher && desktopBanner && desktopBanner.includes('http')) {
      const desktopBannerSplit = desktopBanner.split('/').pop();
      desktopBannerUrl = `${API_URL}/${subUrl}/containers/desktop-banner/download/${desktopBannerSplit}`;
    }

    if (!mobileBannerSwitcher && mobileBanner && mobileBanner.includes('http')) {
      const mobileBannerSplit = mobileBanner.split('/').pop();
      mobileBannerUrl = `${API_URL}/${subUrl}/containers/mobile-banner/download/${mobileBannerSplit}`;
    }

    return (
      <section className="ph-detail-page container edit-campaign">
        <DeleteCampaign
          id={id} title={title}
          isOpen={deleteModalIsOpen}
          deleteSubmit={this.deleteSubmit}
          modalLoading={deleteModalLoading}
          closeModal={this.closeDeleteModal}
        />
        {
          alertIsOpen && (
            <Alerts id={id} name={title} type={alertType} errorText={alertErrorText} errorAlertIsOpen={errorAlertIsOpen} closeErrorAlert={this.closeErrorAlert} />
          )
        }

        <div className="table-top">
          {
            id
              ? <h4 className="ph-detail-page__title">Edit: <b>{oldTitle}</b></h4>
              : <h4 className="ph-detail-page__title">Add campaign</h4>
          }
          {
            id ? (
              <Button
                type="button"
                color="primary"
                onClick={this.copyClick}
                className="copy-button"
              >
                Duplicate Campaign
              </Button>
            ) : null
          }
        </div>
        <span className="ion-close-round ph-detail-page__close" onClick={this.closeDetail} />

        {
          loading && <div className="ph-detail-page__is-loading"><Spinner /></div>
        }

        <form action="" onSubmit={id ? this.editSubmit : this.addSubmit}>

          <Tabs selectedIndex={tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
            <TabList>
              <Tab>Campaign</Tab>
              <Tab>Images</Tab>
              {
                id ?
                  <Tab>Log</Tab>
                  : null
              }
            </TabList>
            <TabPanel>
              <div className="cardbox">
                <div className="cardbox-body">
                    {
                      id ?
                        <fieldset>
                          <div className="form-group row top-fields">
                            {/* created */}
                            <div className="col-md-4 col-sm-6">
                              <b>Created</b>
                              <span>{createdString || ''}</span>
                            </div>

                            {/* impressions */}
                            <div className="col-md-2 col-sm-6">
                              <b>Impressions</b>
                              <span>{impressions || 0}</span>
                            </div>

                            {/* clicks */}
                            <div className="col-md-2 col-sm-6">
                              <b>Clicks</b>
                              <span>{clicks || 0}</span>
                            </div>

                            {/* ctr */}
                            <div className="col-md-2 col-sm-6">
                              <b>CTR</b>
                              <span>{clicks && impressions ? (Math.round(clicks / impressions * 10000) / 100) + '%' : 0}</span>
                            </div>
                          </div>
                        </fieldset>
                        : null
                    }

                    <fieldset>
                      <div className="form-group row">
                        {/* title */}
                        <div className="col-md-4">
                          <label htmlFor="edit-title">Campaign Title</label>
                          <input
                            name="title"
                            type="text"
                            value={title}
                            id="edit-title"
                            style={{ height: '38px'}}
                            onChange={this.onChange}
                            className="form-control"
                          />
                        </div>

                        {/* url */}
                        <div className="col-md-4">
                          <label htmlFor="edit-url">Campaign URL (path regex)</label>
                          <input
                            name="url"
                            type="text"
                            value={url}
                            id="edit-url"
                            style={{ height: '38px'}}
                            onChange={this.onChange}
                            className="form-control"
                          />
                        </div>

                        {/* owner */}
                        <div className="col-md-4">
                          <label htmlFor="edit-owner">Campaign Owner</label>

                          <AsyncSelect
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getUsers(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => (
                              <div>
                                <span>{`${o.name} ${o.surname} `}</span>
                                <span style={{ color: '#1976D2' }}>{o.email}</span>
                              </div>
                            )}
                            onChange={this.onChangeOwner}
                            value={owner}
                          />
                        </div>

                        {/* start */}
                        <div className="col-md-3">
                          <label htmlFor="edit-start">Start Date</label>
                          <input
                            type="date"
                            name="start"
                            id="edit-start"
                            value={start ? start.substring(0, 10) : ''}
                            style={{height: '38px'}}
                            onChange={this.onChange}
                            className="form-control"
                          />
                        </div>

                        {/* end */}
                        <div className="col-md-3">
                          <label htmlFor="edit-end">End Date</label>
                          <input
                            type="date"
                            name="end"
                            id="edit-end"
                            value={end ? end.substring(0, 10) : ''}
                            style={{height: '38px'}}
                            onChange={this.onChange}
                            className="form-control"
                          />
                        </div>

                        {/* weight */}
                        <div className="col-md-3">
                          <label htmlFor="edit-weight">Campaign Weight</label>
                          <input
                            name="weight"
                            type="number"
                            value={weight}
                            id="edit-weight"
                            style={{ height: '38px'}}
                            onChange={this.onChange}
                            className="form-control"
                          />
                        </div>

                        {/* status */}
                        <div className="col-md-3">
                          <label htmlFor="edit-status">Campaign Status</label>

                          <Select
                            value={statusObj}
                            onChange={this.onChangeStatus}
                            options={statusOptions}
                          />
                        </div>

                        {/* user types */}
                        <div className="col-md-6">
                          <label htmlFor="edit-user-types">Talents/Recruiters</label>

                          <AsyncSelect
                            isMulti
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getUserRoles(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.name}
                            onChange={this.onChangeUserRoles}
                            value={user_roles}
                          />
                        </div>

                        {/* product roles */}
                        <div className="col-md-6">
                          <label htmlFor="edit-product-roles">Product Roles</label>

                          <AsyncSelect
                            isMulti
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getRoles(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.name}
                            onChange={this.onChangeRoles}
                            value={roles}
                          />
                        </div>

                        {/* site pages */}
                        <div className="col-md-12">
                          <label htmlFor="edit-pages">Site Pages</label>

                          <AsyncSelect
                            isMulti
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getPages(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.title}
                            onChange={this.onChangePages}
                            value={pages}
                          />
                        </div>

                        {/* seniority */}
                        <div className="col-md-6">
                          <label htmlFor="edit-seniorities">Seniority</label>

                          <AsyncSelect
                            isMulti
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getSeniorities(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.name}
                            onChange={this.onChangeSeniorities}
                            value={seniorities}
                          />
                        </div>

                        {/* event types */}
                        <div className="col-md-6">
                          <label htmlFor="edit-pages">Event Types</label>

                          <AsyncSelect
                            isMulti
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getEventTypes(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.name}
                            onChange={this.onChangeEventTypes}
                            value={eventtypes}
                          />
                        </div>

                        {/* skills */}
                        <div className="col-md-12">
                          <label htmlFor="edit-skills">Skills</label>
                          <AsyncSelect
                            isMulti={true}
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getSkills(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.name}
                            onChange={this.onChangeSkills}
                            value={skills}
                          />
                        </div>

                        {/* locations */}
                        <div className="col-md-6">
                          <label htmlFor="edit-locations">Locations</label>
                          <AsyncSelect
                            isMulti={true}
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getLocations(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => (
                              <div>
                                <span>{`${o.name && o.name + ', '} `}</span>
                                <span style={{ color: '#448aff' }}>{o.alias_region}</span>
                              </div>
                            )}
                            onChange={this.onChangeLocations}
                            value={locations}
                          />
                        </div>

                        {/* companies */}
                        <div className="col-md-6">
                          <label htmlFor="edit-locations">Companies</label>
                          <AsyncSelect
                            isMulti={true}
                            menuPlacement="auto"
                            cacheOptions={true}
                            defaultOptions={true}
                            loadOptions={inputValue => getCompanies(inputValue).then(res => res.data)}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.name}
                            onChange={this.onChangeCompanies}
                            value={companies}
                          />
                        </div>
                      </div>
                    </fieldset>


                    <footer className="ph-detail-page__buttons">
                      {
                        id
                          ? <Button outline color="danger" onClick={this.deleteClick}>Delete</Button>
                          : null
                      }
                      <Button outline color="secondary" onClick={this.closeDetail}>Cancel</Button>
                      <Button disabled={isEmpty(title) || isEmpty(owner) || isEmpty(statusObj) || isEmpty(url)} outline color="primary" type="submit">Save</Button>
                    </footer>

                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="cardbox">
                <div className="cardbox-body">
                  <fieldset>
                    {/* desktop banner */}
                    <div className="col-md-12 edit-logo">
                      <label htmlFor="edit-desktop-banner">Desktop Banner</label>
                      {
                        !desktopBannerSwitcher ? (
                          desktopBanner ? <img className="logo" src={desktopBannerUrl} alt="desktop banner" />
                            : <div className="no-logo">No desktop banner</div>
                        ) : (
                          desktopBannerLoading ? <Spinner /> : (
                            desktopBanner && <img className="logo" src={desktopBanner} alt="desktop banner" />
                          )
                        )
                      }
                      <input
                        id="edit-desktop-banner"
                        type="file"
                        className="input-file-custom"
                        ref={this.fileInputDesktopBanner}
                        onChange={this.onUploadDesktopBanner}
                      />

                      <div className="edit-logo__buttons">
                        <label htmlFor="edit-desktop-banner" className="input-file-label  btn btn-light">
                          <i className="ion-image" />&nbsp;
                          <span>Load desktop banner</span>
                        </label>
                        <Button disabled={!desktopBanner || !desktopBannerUrl} outline color="danger" onClick={this.onDeleteDesktopBanner}>Delete desktop banner</Button>
                      </div>
                    </div>

                    {/* mobile banner */}
                    <div className="col-md-12 edit-logo">
                      <label htmlFor="edit-mobile-banner">Mobile banner</label>
                      {
                        !mobileBannerSwitcher ? (
                          mobileBanner ? <img className="logo" src={mobileBannerUrl} alt="mobile banner" />
                            : <div className="no-logo">No mobile banner</div>
                        ) : (
                          mobileBannerLoading ? <Spinner /> : (
                            mobileBanner && <img className="logo" src={mobileBanner} alt="mobile banner" />
                          )
                        )
                      }
                      <input
                        id="edit-mobile-banner"
                        type="file"
                        className="input-file-custom"
                        ref={this.fileInputMobileBanner}
                        onChange={this.onUploadMobileBanner}
                      />
                      <div className="edit-logo__buttons">
                        <label htmlFor="edit-mobile-banner" className="input-file-label  btn btn-light">
                          <i className="ion-image" />&nbsp;
                          <span>Load mobile banner</span>
                        </label>
                        <Button disabled={!mobileBanner || !mobileBannerUrl} outline color="danger" onClick={this.onDeleteMobileBanner}>Delete mobile banner</Button>
                      </div>
                    </div>
                  </fieldset>

                  <footer className="ph-detail-page__buttons">
                    {
                      id
                        ? <Button outline color="danger" onClick={this.deleteClick}>Delete</Button>
                        : null
                    }
                    <Button outline color="secondary" onClick={this.closeDetail}>Cancel</Button>
                    <Button disabled={isEmpty(title) || isEmpty(owner) || isEmpty(statusObj) || isEmpty(url)} outline color="primary" type="submit">Save</Button>
                  </footer>
                </div>
              </div>
            </TabPanel>
            {
              id ?
                <TabPanel>
                  <div className="table-top">
                    <p className="md-lg">
                      Total records:&nbsp;<b>{count && formatNumber(count)}</b>
                    </p>
                  </div>
                  <Table
                    data={campaignLogs}
                    manual={true}
                    pages={campaignLogsCount}
                    loading={tableLoading}
                    columns={logsColumns}
                    getTheadFilterThProps={(state, rowInfo, column) => {
                      return { style: { overflow: 'visible' }};
                    }}
                    onFetchData={state => {
                      this.setState({ tableLoading: true });

                      // count request
                      getCampaignLogsCount(state, id)
                        .then(res => {
                          this.setState({
                            count: res.data.count,
                            campaignLogsCount: Math.ceil(res.data.count / state.pageSize)
                          })

                          // data request
                          getCampaignLogs(state, id)
                            // .then(res => this.setState({ campaigns: res.data, tableLoading: false }))
                            .then(res => {
                              this.setState({ campaignLogs: res.data, tableLoading: false});
                            })
                            .catch(error => this.catchErrors(error));
                        }).catch(error => this.catchErrors(error));
                    }}
                  />
                </TabPanel>
                : null
            }
          </Tabs>
        </form>
      </section>
    );
  }
}

export default CampaignDetail;
