import axios from 'axios';
import { format } from 'date-fns';

import { API_URL, subUrl } from '../../../api/apiUrl';

const getSessions = (state) => {
  const token = JSON.parse(localStorage.getItem('ph-admin-user-data')).id;

  const { pageSize, page, filtered, sorted } = state; // from own state of react-table

  // filter-template
  const filter = {
    where: {},
    limit: pageSize,
    skip: page * pageSize,
    order: 'id DESC',
  };

  // inject where to filter
  filtered.forEach(i => {
    // id //
    if (i.id === 'id') {
      filter.where[i.id] = parseInt(i.value);

    // type //
    } else if (i.id === 'type') {
      if (i.value) {
        filter.where.type = i.value.id;
      }

    // talent //
    } else if (i.id === 'talent') {
      filter.where.talent = { 'like': '%' + i.value + '%' }

    // coach //
    } else if (i.id === 'coach') {
      filter.where.coach = { 'like': '%' + i.value + '%' }

    // start //
    } else if (i.id === 'start') {
      const startDate = i.value && format(i.value, 'yyyy-MM-dd');
      if (startDate) {
        return filter.where.start = { 'gt': startDate }
      }
      filter.order = i.value ? 'start DESC' : 'id DESC';

    // session status //
    } else if (i.id === 'sessionStatus') {
      filter.where.sessionStatus = i.value;

    // payment status //
    } else if (i.id === 'status') {
      filter.where.status = i.value;

    // price //
    } else if (i.id === 'price') {
      if (i.value) {
        filter.where.price = parseInt(i.value);
      }

    // role //
    } else if (i.id === 'role') {
      if (i.value) {
        filter.where.role = i.value.id;
      }
    } else {
      filter.where[i.id] = {'like': '%' + i.value + '%'}
    }
  });

  // inject order to filter
  sorted.forEach(i => {
    const desc = i.desc ? 'DESC' : 'ASC'
    filter.order = `${i.id} ${desc}`;
  });

  // get-request for data
  return axios.get(`${API_URL}/${subUrl}/coach_sessions/searchExtra`, {
    params: { filter },
    headers: { Authorization: token } // backend doesn't check it
  })
};

export default getSessions;
