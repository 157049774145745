import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

/* loader component for Suspense */
import Core        from '../../components/Core/Core';
import Profile     from './UserProfile/';

import Companies       from './Companies/index.jsx';
import CompaniesDetail from './Companies/detail.jsx';

import Users        from './Users/index.jsx';
import UsersDetail  from './Users/detail.jsx';

import Jobs         from './Jobs/index.jsx';
import JobsDetail   from './Jobs/detail.jsx';

import Skills       from './Skills/index.jsx';
import SkillsDetail from './Skills/detail.jsx';

import Roles        from './Roles/index.jsx';
import RolesDetail  from './Roles/detail.jsx';

import Plans        from './Plans/index.jsx';
import PlansDetail  from './Plans/detail.jsx';

import Coaches        from './Coaches/index.jsx';
import CoachesDetail  from './Coaches/detail.jsx';

import CoachSessions        from './CoachSessions/index.jsx';
import CoachSessionsDetail  from './CoachSessions/detail.jsx';

import Payouts        from './Payouts/index.jsx';

import PayoutsHistory          from './PayoutsHistory/index.jsx';
import PayoutsHistorySessions  from './PayoutsHistory/sessions.jsx';

import Campaigns       from './Campaigns/index.jsx';
import CampaignsDetail from './Campaigns/detail.jsx';

import PagesForAdvertising       from './PagesForAdvertising/index.jsx';
import PagesForAdvertisingDetail from './PagesForAdvertising/detail.jsx';

import PromoCodes        from './PromoCodes/index.jsx';
import PromoCodesDetail  from './PromoCodes/detail.jsx';

import JobsPromoCodes        from './JobsPromoCodes/index.jsx';
import JobsPromoCodesDetail  from './JobsPromoCodes/detail.jsx';

import Payments from './JobHistory/index.jsx';

// common styles //
import '../../components/Bootstrap/Bootstrap';
import '../../components/Common/Common';
import '../../components/Colors/Colors';
import '../../components/FloatButton/FloatButton';
import '../../components/Utils/Utils';

import User from '../../views/User/User';


/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Routes = ({ location }) => {

  // get token
  const userData = JSON.parse(localStorage.getItem('ph-admin-user-data'));
  const token    = userData && userData.id;

  if (!token) {
    // clean localStorage
    localStorage.removeItem('ph-admin-user-data');

    // if not login
    return (
      <User.Layout>
        <Switch location={location}>
          <Route path="/login" component={waitFor(User.Login)}/>
          <Redirect to="/login" />
        </Switch>
      </User.Layout>
    );

  // if login (don't change Routes order)
  } else return (
    <Core>
      <Route path="/companies/:id" component={CompaniesDetail} />
      <Route path="/companies"     component={Companies} />

      <Route path="/users/:id"     component={UsersDetail} />
      <Route path="/users"         component={Users} />

      <Route path="/jobs/:id"      component={JobsDetail} />
      <Route path="/jobs"          component={Jobs} />

      <Route path="/skills/:id"    component={SkillsDetail} />
      <Route path="/skills"        component={Skills} />

      <Route path="/roles/:id"     component={RolesDetail} />
      <Route path="/roles"         component={Roles} />

      <Route path="/plans/:id"     component={PlansDetail} />
      <Route path="/plans"         component={Plans} />

      <Route path="/coaches/:id"   component={CoachesDetail} />
      <Route path="/coaches"       component={Coaches} />

      <Route path="/coaching-sessions/:id" component={CoachSessionsDetail} />
      <Route path="/coaching-sessions"     component={CoachSessions} />

      <Route path="/payouts/:coachId"   component={Payouts} />
      <Route path="/payouts" exact      component={Payouts} />

      <Route path="/payout-history/:id"   component={PayoutsHistorySessions} />
      <Route path="/payout-history" exact      component={PayoutsHistory} />

      <Route path="/campaigns/:id" component={CampaignsDetail} />
      <Route path="/campaigns"     component={Campaigns} />

      <Route path="/pages-for-advertising/:id" component={PagesForAdvertisingDetail} />
      <Route path="/pages-for-advertising"     component={PagesForAdvertising} />

      <Route path="/promo-codes/:id"   component={PromoCodesDetail} />
      <Route path="/promo-codes"       component={PromoCodes} />

      <Route path="/jobs-promo-codes/:id"   component={JobsPromoCodesDetail} />
      <Route path="/jobs-promo-codes"       component={JobsPromoCodes} />

      <Route path="/job-payments"       component={Payments} />

      <Route path="/profile"       component={Profile} />

      {/* <Route path="*"              component={() => <h2>404 - Page not found</h2>} /> */}

      {/* if wrong route */}
      {/* <Redirect to="/companies" /> */}
    </Core>
  );
}

export default withRouter(Routes);
